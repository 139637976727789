define('keygen/routes/processes/index', ['exports', 'keygen/routes/processes'], function (exports, _keygenRoutesProcesses) {
  exports['default'] = _keygenRoutesProcesses['default'].extend({
    queryParams: {
      page: { refreshModel: true },
      size: { refreshModel: true },
      product: { refreshModel: true },
      license: { refreshModel: true },
      machine: { refreshModel: true },
      user: { refreshModel: true },
      status: { refreshModel: true }
    },

    titleToken: function titleToken() {
      return 'Machines';
    },

    model: function model(params) {
      var number = params.page;
      var size = params.size;
      var product = params.product;
      var machine = params.machine;
      var license = params.license;
      var status = params.status;
      var user = params.user;

      return this.store.query('process', {
        page: { number: number, size: size },
        product: product,
        machine: machine,
        license: license,
        status: status,
        user: user
      });
    }
  });
});