define('keygen/routes/releases/index', ['exports', 'keygen/routes/releases'], function (exports, _keygenRoutesReleases) {
  exports['default'] = _keygenRoutesReleases['default'].extend({
    queryParams: {
      page: { refreshModel: true },
      size: { refreshModel: true },
      yanked: { refreshModel: true },
      filetype: { refreshModel: true },
      platform: { refreshModel: true },
      channel: { refreshModel: true },
      product: { refreshModel: true }
    },

    titleToken: function titleToken() {
      return 'Releases';
    },

    model: function model(params) {
      var number = params.page;
      var size = params.size;
      var yanked = params.yanked;
      var filetype = params.filetype;
      var platform = params.platform;
      var channel = params.channel;
      var product = params.product;

      return this.store.query('release', {
        page: { number: number, size: size },
        yanked: yanked,
        filetype: filetype,
        platform: platform,
        channel: channel,
        product: product
      });
    }
  });
});