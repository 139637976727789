define('keygen/controllers/users/index', ['exports', 'keygen/controllers/application'], function (exports, _keygenControllersApplication) {
  exports['default'] = _keygenControllersApplication['default'].extend({
    queryParams: ['page', 'size', 'product', 'license', 'group', 'roles', 'status', 'assigned'],
    // FIXME(ezekg) We're splitting by comma because Ember parses query
    // strings in a stupid way i.e. `/users?roles=['user','admin']`
    roles: 'user,support-agent,sales-agent,developer,admin,read-only',
    product: null,
    license: null,
    page: 1,
    size: 20
  });
});