define('keygen/models/billing', ['exports', 'keygen/models/base', 'ember', 'ember-data'], function (exports, _keygenModelsBase, _ember, _emberData) {
  var computed = _ember['default'].computed;
  exports['default'] = _keygenModelsBase['default'].extend({
    type: 'billings',

    account: _emberData['default'].belongsTo('account'),
    plan: _emberData['default'].belongsTo('plan'),
    subscriptionStatus: _emberData['default'].attr('string'),
    subscriptionPeriodStart: _emberData['default'].attr('date'),
    subscriptionPeriodEnd: _emberData['default'].attr('date'),
    cardExpiry: _emberData['default'].attr('date'),
    cardBrand: _emberData['default'].attr('string'),
    cardLast4: _emberData['default'].attr('string'),
    coupon: _emberData['default'].attr('string'),
    state: _emberData['default'].attr('string'),

    card: computed('cardExpiry', 'cardBrand', 'cardLast4', function () {
      if (!this.get('store').hasRecordForId('billing', this.get('id'))) {
        // Exit early if the resource hasn't been loaded yet. We're explicitly
        // checking for null to show certain notifications e.g. missing card, etc.
        // This will return undefined if the billing hasn't actually loaded yet.
        return undefined;
      }

      if (this.get('cardExpiry') && this.get('cardBrand') && this.get('cardLast4')) {
        return {
          expiry: this.get('cardExpiry'),
          brand: this.get('cardBrand'),
          last4: this.get('cardLast4')
        };
      } else {
        return null; // Missing payment method for account
      }
    })
  });
});