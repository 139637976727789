define('keygen/components/kg-metric-cards', ['exports', 'keygen/components/base', 'lodash', 'ember'], function (exports, _keygenComponentsBase, _lodash, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _keygenComponentsBase['default'].extend({
    groupedMetrics: computed('metrics.[]', function () {
      var metrics = this.get('metrics');

      // Group metrics by metric type
      return (0, _lodash.groupBy)(metrics.map(function (m) {
        return m.toJSON();
      }), function (m) {
        return m.metric;
      });
    })
  });
});