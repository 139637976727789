define('keygen/controllers/licenses/index', ['exports', 'keygen/controllers/application'], function (exports, _keygenControllersApplication) {
  exports['default'] = _keygenControllersApplication['default'].extend({
    queryParams: ['page', 'size', 'product', 'policy', 'user', 'group', 'machine'],
    product: null,
    policy: null,
    user: null,
    machine: null,
    page: 1,
    size: 20
  });
});