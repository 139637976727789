define('keygen/routes/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'keygen/routes/base', 'ember', 'moment'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _keygenRoutesBase, _ember, _moment) {
  var RSVP = _ember['default'].RSVP;
  var service = _ember['default'].inject.service;

  var METRICS = ['user.created', 'user.updated', 'user.deleted', 'user.password-reset', 'user.banned', 'user.unbanned', 'package.created', 'package.updated', 'package.deleted', 'product.created', 'product.updated', 'product.deleted', 'policy.created', 'policy.updated', 'policy.deleted', 'policy.pool.popped', 'policy.entitlements.attached', 'policy.entitlements.detached', 'license.created', 'license.updated', 'license.deleted', 'license.expiring-soon', 'license.expired', 'license.checked-out', 'license.checked-in', 'license.check-in-required-soon', 'license.check-in-overdue', 'license.usage.incremented', 'license.usage.decremented', 'license.usage.reset', 'license.renewed', 'license.revoked', 'license.suspended', 'license.reinstated', 'license.policy.updated', 'license.owner.updated', 'license.user.updated', 'license.entitlements.attached', 'license.entitlements.detached', 'license.users.attached', 'license.users.detached', 'machine.created', 'machine.updated', 'machine.deleted', 'machine.checked-out', 'machine.owner.updated', 'process.created', 'process.updated', 'process.deleted', 'component.created', 'component.updated', 'component.deleted', 'entitlement.created', 'entitlement.updated', 'entitlement.deleted', 'release.created', 'release.updated', 'release.deleted', 'release.package.updated', 'release.upgraded', 'release.published', 'release.yanked', 'artifact.created', 'artifact.updated', 'artifact.deleted', 'release.constraints.attached', 'release.constraints.detached'];

  exports['default'] = _keygenRoutesBase['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    currentAccount: service('current-account'),
    currentBearer: service('current-bearer'),

    model: function model() /* params */{
      var account = this.get('currentAccount.account');
      var bearer = this.get('currentBearer.bearer');
      var metrics = [];

      if (account.get('status') !== 'CANCELED' && bearer != null && (bearer.get('isAdmin') || bearer.get('isDev') || bearer.get('isReadOnly'))) {
        metrics = this.store.query('metric', {
          metrics: METRICS,
          date: {
            start: (0, _moment['default'])().utc().subtract(2, 'weeks').format(),
            end: (0, _moment['default'])().utc().format()
          },
          page: {
            number: 1,
            size: 100
          }
        }).then(function (metrics) {
          return metrics.toArray();
        });
      }

      return RSVP.hash({
        plan: account && account.get('plan'),
        account: account,
        metrics: metrics
      });
    }
  });
});