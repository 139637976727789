define('keygen/components/kg-metric-chart', ['exports', 'ember', 'keygen/components/base', 'd3-shape', 'd3-array', 'd3-scale', 'd3-selection'], function (exports, _ember, _keygenComponentsBase, _d3Shape, _d3Array, _d3Scale, _d3Selection) {
  var run = _ember['default'].run;
  exports['default'] = _keygenComponentsBase['default'].extend({
    tagName: 'svg',
    attributeBindings: ['width', 'height', 'viewBox', 'preserveAspectRatio'],
    width: 800,
    height: 400,
    viewBox: '0 0 800 400',
    preserveAspectRatio: 'none',

    didReceiveAttrs: function didReceiveAttrs() {
      run.scheduleOnce('render', this, this._draw);
    },

    _draw: function _draw() {
      var plot = (0, _d3Selection.select)(this.element);
      var data = this.get('data');
      var width = this.get('width');
      var height = this.get('height');
      if (!data) {
        return;
      }

      var x = (0, _d3Scale.scaleLinear)().domain((0, _d3Array.extent)(data.map(function (d) {
        return d.x;
      }))).range([0, width]);

      var y = (0, _d3Scale.scaleLinear)().domain((0, _d3Array.extent)(data.map(function (d) {
        return d.y;
      }).sort(_d3Array.ascending))).range([height - 10, 10]);

      var valueLine = (0, _d3Shape.line)().x(function (d) {
        return x(d.x);
      }).y(function (d) {
        return y(d.y);
      }).curve(_d3Shape.curveBasis);

      // Clear before redrawing
      plot.selectAll("svg > *").remove();

      plot.append("path").data([data]).attr("vector-effect", "non-scaling-stroke").attr("class", "line").attr("d", valueLine);
    }
  });
});