define('keygen/serializers/package', ['exports', 'keygen/serializers/base'], function (exports, _keygenSerializersBase) {
  exports['default'] = _keygenSerializersBase['default'].extend({
    attrs: {
      product: { serialize: ['create', 'new'] },
      account: { serialize: false },

      created: { serialize: false },
      updated: { serialize: false }
    }
  });
});