define('keygen/models/release', ['exports', 'keygen/mixins/changed-relationships', 'keygen/models/base', 'ember-data', 'ember-api-actions', 'ember'], function (exports, _keygenMixinsChangedRelationships, _keygenModelsBase, _emberData, _emberApiActions, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _keygenModelsBase['default'].extend(_keygenMixinsChangedRelationships['default'], {
    type: 'releases',

    account: _emberData['default'].belongsTo('account'),
    product: _emberData['default'].belongsTo('product'),
    'package': _emberData['default'].belongsTo('package'),
    entitlements: _emberData['default'].hasMany('entitlement'),
    constraints: _emberData['default'].hasMany('constraint'),
    artifacts: _emberData['default'].hasMany('artifact'),

    name: _emberData['default'].attr('string'),
    description: _emberData['default'].attr('string'),
    tag: _emberData['default'].attr('string'),
    version: _emberData['default'].attr('string'),
    semver: _emberData['default'].attr('json'),
    channel: _emberData['default'].attr('string'),
    status: _emberData['default'].attr('string'),
    metadata: _emberData['default'].attr('json'),

    created: _emberData['default'].attr('date'),
    updated: _emberData['default'].attr('date'),
    yanked: _emberData['default'].attr('date'),

    isDraft: computed.equal('status', 'DRAFT'),
    isPublished: computed.equal('status', 'PUBLISHED'),
    isYanked: computed.equal('status', 'YANKED'),

    // Relationships
    attachEntitlements: (0, _emberApiActions.memberAction)({ type: 'POST', path: 'constraints' }),
    detachEntitlements: (0, _emberApiActions.memberAction)({ type: 'DELETE', path: 'constraints' }),
    changePackage: (0, _emberApiActions.memberAction)({ type: 'PUT', path: 'package' }),

    // Actions
    publish: (0, _emberApiActions.memberAction)({ type: 'POST', path: 'actions/publish' }),
    yank: (0, _emberApiActions.memberAction)({ type: 'POST', path: 'actions/yank' })
  });
});