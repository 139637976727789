define('keygen/routes/subscription', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'keygen/routes/base', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _keygenRoutesBase, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _keygenRoutesBase['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    currentBearer: service('current-bearer'),

    beforeModel: function beforeModel() {
      if (!this.get('currentBearer.isAdmin')) {
        return this.transitionTo('index');
      }

      return this.transitionTo('billing');
    }
  });
});