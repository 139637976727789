define('keygen/components/kg-metric-feed', ['exports', 'keygen/components/base', 'ember', 'lodash'], function (exports, _keygenComponentsBase, _ember, _lodash) {
  var computed = _ember['default'].computed;
  exports['default'] = _keygenComponentsBase['default'].extend({
    limit: 20,
    size: 20,
    ignored: ['license.validation.succeeded', 'license.validation.failed', 'license.validated'],

    sortedMetrics: computed('metrics.[]', function () {
      var metrics = this.get('metrics');

      return (0, _lodash.orderBy)(metrics, function (metric) {
        return metric.get('created');
      }, ['desc']);
    }),

    paginatedMetrics: computed('sortedMetrics', 'limit', function () {
      var _this = this;

      var limit = this.get('limit');
      var metrics = this.get('sortedMetrics').filter(function (metric) {
        return !_this.ignored.includes(metric.get('metric'));
      });

      return metrics.slice(0, limit);
    }),

    canLoadMore: computed('sortedMetrics', 'limit', function () {
      var _this2 = this;

      var limit = this.get('limit');
      var metrics = this.get('sortedMetrics').filter(function (metric) {
        return !_this2.ignored.includes(metric.get('metric'));
      });

      return limit <= metrics.length;
    }),

    actions: {
      handleGoToNextPage: function handleGoToNextPage() {
        var limit = this.get('limit');
        limit += this.get('size');

        this.set('limit', limit);
      }
    }
  });
});