define('keygen/controllers/application', ['exports', 'confirmer', 'ember'], function (exports, _confirmer, _ember) {
  var Controller = _ember['default'].Controller;
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  exports['default'] = Controller.extend({
    currentAccount: service('current-account'),
    currentBearer: service('current-bearer'),
    session: service('session'),

    // The confirmer instance
    confirmer: null,

    // The confirmation value
    confirmation: null,

    // Whether confirmation input is required to match a model property
    confirmationProperty: null,

    // Whether confirmation input is required to match a value
    confirmationValue: 'DELETE',

    // Whether a confirmation is required
    isConfirmationRequired: computed.bool('confirmer'),

    // Whether the confirmation is valid
    isConfirmationValid: computed('confirmation', function () {
      var _getProperties = this.getProperties('confirmationProperty', 'confirmationValue', 'confirmation');

      var confirmationProperty = _getProperties.confirmationProperty;
      var confirmationValue = _getProperties.confirmationValue;
      var confirmation = _getProperties.confirmation;

      if (confirmationProperty != null) {
        return confirmation === this.get('model.' + confirmationProperty);
      }

      return confirmation === confirmationValue;
    }),

    // Whether the confirmation is invalid
    isConfirmationInvalid: computed.not('isConfirmationValid'),

    promptForConfirmation: function promptForConfirmation() {
      var _this = this;

      var _ref = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

      var confirmationProperty = _ref.property;
      var _ref$value = _ref.value;
      var confirmationValue = _ref$value === undefined ? 'DELETE' : _ref$value;

      return new _confirmer['default'](function (confirmer) {
        return _this.setProperties({ confirmation: null, confirmationProperty: confirmationProperty, confirmationValue: confirmationValue, confirmer: confirmer });
      }).onDone(function () {
        return _this.setProperties({ confirmation: null, confirmer: null, confirmationProperty: null, confirmationValue: 'DELETE' });
      });
    }
  });
});