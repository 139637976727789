define('keygen/routes/register', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'npm:raygun4js'], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin, _npmRaygun4js) {
  var RSVP = _ember['default'].RSVP;
  var service = _ember['default'].inject.service;
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {
    flashMessages: service('flashMessages'),
    session: service('session'),

    queryParams: {
      logout: { refreshModel: true },
      email: { refreshModel: true },
      plan: { refreshModel: true }
    },

    title: function title() {
      return 'Sign Up';
    },

    beforeModel: function beforeModel(transition) {
      var session = this.get('session');
      var shouldLogout = transition.queryParams.logout === 'true' || transition.queryParams.logout === 't' || transition.queryParams.logout === '1';

      if (session.get('isAuthenticated') && shouldLogout) {
        return session.invalidate();
      }

      this._super.apply(this, arguments);
    },

    model: function model(params) {
      var _this = this;

      return this.store.query('plan', { limit: 100 }).then(function (plans) {
        var account = _this.store.createRecord('account', {
          admins: [_this.store.createRecord('user', { email: params.email, role: 'admin' })],
          billing: _this.store.createRecord('billing'),
          plan: plans.find(function (plan) {
            return plan.id === params.plan;
          }),
          'protected': true
        });

        return RSVP.hash({
          account: account,
          plans: plans
        });
      });
    },

    afterModel: function afterModel(models, transition) {
      if (!transition.queryParams.plan) {
        return;
      }

      var selectedPlanId = transition.queryParams.plan;
      var account = models.account;
      var plans = models.plans;

      // Set referral if present
      try {
        var _ref = window.Rewardful || {};

        var referral = _ref.referral;

        if (referral) {
          account.set('request.meta', { referral: referral });
        }
      } catch (e) {
        (0, _npmRaygun4js['default'])('send', e);
      }

      this.store.findRecord('plan', selectedPlanId).then(function (plan) {
        account.set('plan', plan);

        if (!plans.includes(plan)) {
          plans.pushObject(plan._internalModel); // FIXME: Private API
        }
      });
    },

    actions: {
      register: function register(account) {
        var _this2 = this;

        account.save().then(function () {
          var admin = account.get('admins.firstObject');

          return _this2.get('session').authenticate('authenticator:token', account.get('slug'), admin.get('email'), admin.get('password'));
        }).then(function () {
          var flash = get(_this2, 'flashMessages');

          flash.clearMessages();

          return _this2.transitionTo('index', { queryParams: { onboarding: 'welcome' } });
        })['catch'](function (err) {
          var flash = get(_this2, 'flashMessages');

          flash.clearMessages();
          flash.add({
            message: 'Your account could not be created. Please double check your information below.',
            type: 'error',
            timeout: Infinity
          });

          // Log all registration errors and consider it a bug
          (0, _npmRaygun4js['default'])('send', err);
        });
      }
    }
  });
});