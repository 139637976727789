define('keygen/components/kg-register-form', ['exports', 'keygen/components/kg-password-form', 'ember'], function (exports, _keygenComponentsKgPasswordForm, _ember) {
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  exports['default'] = _keygenComponentsKgPasswordForm['default'].extend({
    tagName: '',

    store: service('store'),

    autofill: {},

    account: computed.alias('model.account'),
    admins: computed.alias('account.admins'),
    plan: computed.alias('account.plan'),

    plans: computed.alias('model.plans'),

    isSubmitDisabled: computed('account.isSaving', 'account.isLoading', 'admins.@each.{email,password}', 'plan', function () {
      var account = this.get('account');
      var admins = this.get('admins').toArray();
      var plan = this.get('plan');

      return account.get('isSaving') || account.get('isLoading') || plan == null || admins.some(function (admin) {
        return !admin.get('email') || !admin.get('password');
      });
    }),

    hasCustomAccountSlug: computed('model.account.slug', function () {
      return this.get('model.account.slug') != null;
    }),

    isEmailAutoFilled: computed('autofill.email', function () {
      return !!this.get('autofill.email');
    }),

    isPlanAutoFilled: computed('autofill.plan', function () {
      return !!this.get('autofill.plan');
    }),

    actions: {
      planSelectChange: function planSelectChange(event) {
        var id = event.target.value;

        var account = this.get('model.account');

        if (!id) {
          account.set('plan', null);
          return;
        }

        var record = this.get('store').peekRecord('plan', id);
        if (record) {
          account.set('plan', record);
        }
      }
    }
  });
});