define('keygen/components/kg-resource-table-row', ['exports', 'ember', 'keygen/components/base', 'ember-inflector'], function (exports, _ember, _keygenComponentsBase, _emberInflector) {
  var service = _ember['default'].inject.service;
  exports['default'] = _keygenComponentsBase['default'].extend({
    tagName: '',

    router: service('-routing'),

    clickable: true,

    actions: {
      transitionTo: function transitionTo(model) {
        var clickable = this.get('clickable');
        if (!clickable) {
          return;
        }

        var router = this.get('router');
        var modelName = undefined;
        try {
          modelName = model.modelName || model.constructor.modelName || model.type.modelName;
        } catch (e) {
          try {
            modelName = model.content.constructor.modelName;
          } catch (e) {
            console.error(e);

            return;
          }
        }

        router.transitionTo((0, _emberInflector.pluralize)(modelName) + '.show', [model.id]);
      }
    }
  });
});