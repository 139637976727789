define('keygen/routes/event-logs/index', ['exports', 'keygen/routes/event-logs'], function (exports, _keygenRoutesEventLogs) {
  exports['default'] = _keygenRoutesEventLogs['default'].extend({
    queryParams: {
      'whodunnit[type]': { refreshModel: true },
      'whodunnit[id]': { refreshModel: true },
      'resource[type]': { refreshModel: true },
      'resource[id]': { refreshModel: true },
      request: { refreshModel: true },
      event: { refreshModel: true },
      page: { refreshModel: true },
      size: { refreshModel: true }
    },

    titleToken: function titleToken() {
      return 'Event Logs';
    },

    model: function model(params) {
      var event = params.event;
      var request = params.request;

      var date = undefined;
      if (params.start && params.end) {
        date = {
          start: params.start,
          end: params.end
        };
      }

      // FIXME(ezekg) Ember doesn't support hash query params
      var whodunnit = undefined;
      if (params['whodunnit[type]'] && params['whodunnit[id]']) {
        whodunnit = {
          type: params['whodunnit[type]'],
          id: params['whodunnit[id]']
        };
      }

      var resource = undefined;
      if (params['resource[type]'] && params['resource[id]']) {
        resource = {
          type: params['resource[type]'],
          id: params['resource[id]']
        };
      }

      return this.store.query('event-log', {
        request: request,
        event: event,
        whodunnit: whodunnit,
        resource: resource,
        date: date,
        page: {
          number: params.page,
          size: params.size
        }
      });
    }
  });
});