define('keygen/services/current-bearer', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  var RSVP = _ember['default'].RSVP;
  exports['default'] = _ember['default'].Service.extend({
    session: service('session'),
    store: service('store'),

    bearer: null,

    token: computed.alias('session.data.authenticated.tok'),

    isAdmin: computed.equal('bearer.role', 'admin'),
    isDeveloper: computed.equal('bearer.role', 'developer'),
    isSalesAgent: computed.equal('bearer.role', 'sales-agent'),
    isSupportAgent: computed.equal('bearer.role', 'support-agent'),
    isUser: computed.equal('bearer.role', 'user'),

    load: function load() {
      var _this = this;

      var id = this.get('session.data.authenticated.uid');

      if (!isEmpty(id)) {
        return this.get('store').findRecord('user', id).then(function (bearer) {
          _this.set('bearer', bearer);

          return bearer;
        });
      } else {
        return RSVP.resolve();
      }
    }
  });
});