define('keygen/routes/releases/new', ['exports', 'keygen/routes/releases', 'ember'], function (exports, _keygenRoutesReleases, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _keygenRoutesReleases['default'].extend({
    currentAccount: service('current-account'),

    titleToken: function titleToken() {
      return 'New release';
    },

    model: function model() {
      return this.store.createRecord('release');
    }
  });
});