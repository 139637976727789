define('keygen/controllers/index', ['exports', 'keygen/controllers/application', 'npm:copy-to-clipboard', 'ember', 'keygen/config/environment', 'npm:detect-browser'], function (exports, _keygenControllersApplication, _npmCopyToClipboard, _ember, _keygenConfigEnvironment, _npmDetectBrowser) {
  var computed = _ember['default'].computed;
  exports['default'] = _keygenControllersApplication['default'].extend({
    queryParams: ['onboarding'],

    onboarding: null,

    account: computed.alias('model.account'),
    product: null,
    token: null,
    policy: null,
    license: null,
    machine: null,

    pubkey: computed('currentAccount.account.meta.keys.ed25519', function () {
      return atob(this.get('currentAccount.account.meta.keys.ed25519'));
    }),

    isDesktopBrowser: _npmDetectBrowser['default'].os !== 'Android OS' && _npmDetectBrowser['default'].os !== 'iOS',

    isOnboardingInProgress: computed.or('isWelcomeStep', 'isAccountStep', 'isProductStep', 'isTokenStep', 'isPolicyStep', 'isLicenseStep', 'isMachineStep', 'isValidateStep'),

    isWelcomeStep: computed.equal('onboarding', 'welcome'),
    isAccountStep: computed.equal('onboarding', 'account'),

    isProductStep: computed('onboarding', 'product', function () {
      return this.get('onboarding') === 'product' && this.get('product') != null;
    }),

    isTokenStep: computed('onboarding', 'token', function () {
      return this.get('onboarding') === 'token' && this.get('token') != null;
    }),

    isPolicyStep: computed('onboarding', 'policy', function () {
      return this.get('onboarding') === 'policy' && this.get('policy') != null;
    }),

    isLicenseStep: computed('onboarding', 'license', function () {
      return this.get('onboarding') === 'license' && this.get('license') != null;
    }),

    isMachineStep: computed('onboarding', 'machine', function () {
      return this.get('onboarding') === 'machine' && this.get('machine') != null;
    }),

    isValidateStep: computed('onboarding', 'validate', function () {
      return this.get('onboarding') === 'validate' && this.get('license') != null;
    }),

    isProductValid: computed('product.name', 'productHasLicensing', 'productHasDistribution', function () {
      return !!this.get('product.name') && (this.get('productHasLicensing') || this.get('productHasDistribution'));
    }),

    isProductInvalid: computed.not('isProductValid'),

    isPolicyValid: computed('policy.name', function () {
      return !!this.get('policy.name');
    }),

    isPolicyInvalid: computed.not('isPolicyValid'),

    isLicenseValid: computed('license.name', function () {
      return !!this.get('license.name');
    }),

    isLicenseInvalid: computed.not('isLicenseValid'),

    isMachineValid: computed('machine.{name,fingerprint}', function () {
      return !!this.get('machine.name') && !!this.get('machine.fingerprint');
    }),

    isMachineInvalid: computed.not('isMachineValid'),

    policyDurationOptions: [{
      groupName: 'Timed Trial',
      options: [{ name: '7-day Trial', value: 604800 }, { name: '14-day Trial', value: 1209600 }, { name: '30-day Trial', value: 2592000 }]
    }, {
      groupName: 'Timed License',
      options: [{ name: '1 Month', value: 2592000 }, { name: '1 Year', value: 31536000 }]
    }, {
      groupName: 'Perpetual License',
      options: [{ name: 'Forever', value: null }]
    }],

    selectedPolicyDuration: computed('policyDurationOptions', 'policy.duration', function () {
      var groups = this.get('policyDurationOptions');
      var selected = this.get('policy.duration') || null;

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = groups[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var group = _step.value;
          var options = group.options;
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {

            for (var _iterator2 = options[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var option = _step2.value;

              if (option.value === selected) {
                return option;
              }
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2['return']) {
                _iterator2['return']();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator['return']) {
            _iterator['return']();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return null;
    }),

    policySchemeOptions: [{
      groupName: 'Signatures',
      options: [{ name: 'Ed25519 (best security, smallest key length)', value: 'ED25519_SIGN' }, { name: 'RSA PKCS1 PSS (good security, longer keys)', value: 'RSA_2048_PKCS1_PSS_SIGN_V2' }, { name: 'RSA PKCS1 (more language support)', value: 'RSA_2048_PKCS1_SIGN_V2' }]
    }, {
      groupName: 'Encryption',
      options: [{ name: 'RSA PKCS1', value: 'RSA_2048_PKCS1_ENCRYPT' }]
    }, {
      groupName: 'None',
      options: [{ name: 'No cryptography', value: null }]
    }],

    selectedPolicyScheme: computed('policySchemeOptions', 'policy.scheme', function () {
      var groups = this.get('policySchemeOptions');
      var selected = this.get('policy.scheme') || null;

      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = groups[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var group = _step3.value;
          var options = group.options;
          var _iteratorNormalCompletion4 = true;
          var _didIteratorError4 = false;
          var _iteratorError4 = undefined;

          try {

            for (var _iterator4 = options[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
              var option = _step4.value;

              if (option.value === selected) {
                return option;
              }
            }
          } catch (err) {
            _didIteratorError4 = true;
            _iteratorError4 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion4 && _iterator4['return']) {
                _iterator4['return']();
              }
            } finally {
              if (_didIteratorError4) {
                throw _iteratorError4;
              }
            }
          }
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3['return']) {
            _iterator3['return']();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }

      return null;
    }),

    productHasWebsite: false,
    productHasLicensing: true,
    productHasDistribution: false,

    policyHasDuration: false,
    policyHasMachineLimit: false,
    policyHasScheme: false,

    machineHasFingerprint: false,

    validateCodeSnippet: computed('account', 'product', 'policy', 'license', 'machine', function () {
      var accountId = this.get('account.slug');
      var productId = this.get('product.id');
      var policyId = this.get('policy.id');
      var licenseKey = this.get('license.key');
      var machineFingerprint = this.get('machine.fingerprint');

      if (machineFingerprint != null) {
        return 'curl -X POST ' + _keygenConfigEnvironment['default'].keygen.host + '/v1/accounts/' + accountId + '/licenses/actions/validate-key \\\n  -H \'Content-Type: application/vnd.api+json\' \\\n  -H \'Accept: application/vnd.api+json\' \\\n  -d \'{\n        "meta": {\n          "key": "' + licenseKey + '",\n          "scope": {\n            "product": "' + productId + '",\n            "policy": "' + policyId + '",\n            "fingerprint": "' + machineFingerprint + '"\n          }\n        }\n      }\'';
      }

      return 'curl -X POST ' + _keygenConfigEnvironment['default'].keygen.host + '/v1/accounts/' + accountId + '/licenses/actions/validate-key \\\n  -H \'Content-Type: application/vnd.api+json\' \\\n  -H \'Accept: application/vnd.api+json\' \\\n  -d \'{\n        "meta": {\n          "key": "' + licenseKey + '",\n          "scope": {\n            "product": "' + productId + '",\n            "policy": "' + policyId + '"\n          }\n        }\n      }\'';
    }),

    hasAccountSlugRecentlyBeenCopied: false,
    hasAccountIdRecentlyBeenCopied: false,
    hasProductIdRecentlyBeenCopied: false,
    hasProductTokenRecentlyBeenCopied: false,
    hasPublicKeyRecentlyBeenCopied: false,
    hasMachineFingerprintRecentlyBeenCopied: false,
    hasValidateCodeSnippetRecentlyBeenCopied: false,

    actions: {
      copyAccountSlugToClipboard: function copyAccountSlugToClipboard() {
        var _this = this;

        var id = this.get('account.slug');

        setTimeout(function () {
          return _this.set('hasAccountSlugRecentlyBeenCopied', false);
        }, 2500);

        this.set('hasAccountSlugRecentlyBeenCopied', (0, _npmCopyToClipboard['default'])(id));
      },

      copyAccountIdToClipboard: function copyAccountIdToClipboard() {
        var _this2 = this;

        var id = this.get('account.id');

        setTimeout(function () {
          return _this2.set('hasAccountIdRecentlyBeenCopied', false);
        }, 2500);

        this.set('hasAccountIdRecentlyBeenCopied', (0, _npmCopyToClipboard['default'])(id));
      },

      copyProductIdToClipboard: function copyProductIdToClipboard() {
        var _this3 = this;

        var id = this.get('product.id');

        setTimeout(function () {
          return _this3.set('hasProductIdRecentlyBeenCopied', false);
        }, 2500);

        this.set('hasProductIdRecentlyBeenCopied', (0, _npmCopyToClipboard['default'])(id));
      },

      copyProductTokenToClipboard: function copyProductTokenToClipboard() {
        var _this4 = this;

        var token = this.get('token.token');

        setTimeout(function () {
          return _this4.set('hasProductTokenRecentlyBeenCopied', false);
        }, 2500);

        this.set('hasProductTokenRecentlyBeenCopied', (0, _npmCopyToClipboard['default'])(token));
      },

      copyPublicKeyToClipboard: function copyPublicKeyToClipboard() {
        var _this5 = this;

        var key = this.get('pubkey');

        setTimeout(function () {
          return _this5.set('hasPublicKeyRecentlyBeenCopied', false);
        }, 2500);

        this.set('hasPublicKeyRecentlyBeenCopied', (0, _npmCopyToClipboard['default'])(key));
      },

      copyMachineFingerprintToClipboard: function copyMachineFingerprintToClipboard() {
        var _this6 = this;

        var fingerprint = this.get('machine.fingerprint');

        setTimeout(function () {
          return _this6.set('hasMachineFingerprintRecentlyBeenCopied', false);
        }, 2500);

        this.set('hasMachineFingerprintRecentlyBeenCopied', (0, _npmCopyToClipboard['default'])(fingerprint));
      },

      copyValidateCodeSnippetToClipboard: function copyValidateCodeSnippetToClipboard() {
        var _this7 = this;

        var snippet = this.get('validateCodeSnippet');

        setTimeout(function () {
          return _this7.set('hasValidateCodeSnippetRecentlyBeenCopied', false);
        }, 2500);

        this.set('hasValidateCodeSnippetRecentlyBeenCopied', (0, _npmCopyToClipboard['default'])(snippet));
      },

      enableProductWebsite: function enableProductWebsite() {
        this.set('productHasWebsite', true);
      },

      toggleProductLicensing: function toggleProductLicensing() {
        this.toggleProperty('productHasLicensing');
      },

      toggleProductDistribution: function toggleProductDistribution() {
        this.toggleProperty('productHasDistribution');
      },

      enablePolicyDuration: function enablePolicyDuration() {
        this.set('policyHasDuration', true);
      },

      enablePolicyScheme: function enablePolicyScheme() {
        this.set('policyHasScheme', true);
        this.set('policy.scheme', 'ED25519_SIGN');
      },

      enablePolicyMachineLimit: function enablePolicyMachineLimit() {
        this.set('policyHasMachineLimit', true);
        this.set('policy.overageStrategy', 'NO_OVERAGE');
        this.set('policy.floating', true);
        this.set('policy.strict', true);
        this.set('policy.requireFingerprintScope', true);
        this.set('policy.maxMachines', 3);
      },

      choosePolicyDuration: function choosePolicyDuration(_ref) {
        var name = _ref.name;
        var value = _ref.value;

        if (name.includes('trial')) {
          this.set('policy.machineUniquenessStrategy', 'UNIQUE_PER_PRODUCT');
        }

        this.set('policy.duration', value);
      },

      choosePolicyScheme: function choosePolicyScheme(_ref2) {
        var value = _ref2.value;

        this.set('policy.scheme', value);
      },

      generateMachineFingerprint: function generateMachineFingerprint() {
        this.set('machineHasFingerprint', true);

        var fingerprint = Math.random().toFixed(16).substring(2).split(/(.{2})/).filter(function (n) {
          return n;
        }).join(':');

        this.set('machine.fingerprint', fingerprint);
      },

      gotoAccountStep: function gotoAccountStep() {
        this.transitionToRoute({ queryParams: { onboarding: 'account' } });
      },

      gotoProductStep: function gotoProductStep() {
        this.set('product', this.store.createRecord('product', { 'protected': true }));

        this.transitionToRoute({ queryParams: { onboarding: 'product' } });
      },

      gotoTokenStep: function gotoTokenStep() {
        var _this8 = this;

        if (!this.get('productHasLicensing')) {
          this.set('product.distributionStrategy', 'OPEN');
        }

        this.get('product').save().then(function (product) {
          _this8.set('token', _this8.store.createRecord('token', { kind: 'product-token', bearer: product }));

          _this8.get('token').save().then(function (token) {
            _this8.transitionToRoute({ queryParams: { onboarding: 'token' } });
          });
        });
      },

      gotoPolicyStepWithoutSave: function gotoPolicyStepWithoutSave() {
        var product = this.get('product');

        this.set('policy', this.store.createRecord('policy', { floating: true, 'protected': true, product: product }));

        this.transitionToRoute({ queryParams: { onboarding: 'policy' } });
      },

      gotoPolicyStep: function gotoPolicyStep() {
        var _this9 = this;

        this.get('product').save().then(function (product) {
          _this9.set('policy', _this9.store.createRecord('policy', { floating: true, 'protected': true, product: product }));

          _this9.transitionToRoute({ queryParams: { onboarding: 'policy' } });
        });
      },

      gotoLicenseStep: function gotoLicenseStep() {
        var _this10 = this;

        this.get('policy').save().then(function (policy) {
          _this10.set('license', _this10.store.createRecord('license', { policy: policy }));

          _this10.transitionToRoute({ queryParams: { onboarding: 'license' } });
        });
      },

      gotoMachineStep: function gotoMachineStep() {
        var _this11 = this;

        this.get('license').save().then(function (license) {
          _this11.set('machine', _this11.store.createRecord('machine', { license: license }));

          _this11.transitionToRoute({ queryParams: { onboarding: 'machine' } });
        });
      },

      gotoValidateStep: function gotoValidateStep() {
        var _this12 = this;

        var save = this.get('machine.isNew') ? this.get('machine').save() : this.get('license').save();

        save.then(function () {
          return _this12.transitionToRoute({ queryParams: { onboarding: 'validate' } });
        });
      },

      gotoLicensePage: function gotoLicensePage() {
        this.transitionToRoute('licenses.show', this.get('license.id'));
      },

      closeOnboardingModal: function closeOnboardingModal() {
        this.transitionToRoute({ queryParams: { onboarding: null } });
      }
    }
  });
});