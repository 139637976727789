define('keygen/components/kg-analytics-cards', ['exports', 'keygen/components/base', 'ember'], function (exports, _keygenComponentsBase, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _keygenComponentsBase['default'].extend({
    session: service('session'),
    store: service('store'),

    init: function init() {
      this._super.apply(this, arguments);

      this._fetchData();
    },

    isLoading: computed.empty('analytics'),

    hasError: computed.notEmpty('error'),

    limits: computed('plan', function () {
      return {
        activeLicensedUsers: this.get('plan.maxLicenses') || this.get('plan.maxUsers')
      };
    }),

    hasExceededActiveLicensedUserLimit: computed('analytics.activeLicensedUsers', 'limits.activeLicensedUsers', function () {
      var count = this.get('analytics.activeLicensedUsers');
      var limit = this.get('limits.activeLicensedUsers');
      if (limit == null) {
        return false;
      }

      return count > limit;
    }),

    _fetchData: function _fetchData() {
      var _this = this;

      var baseUrl = getOwner(this).lookup('adapter:application').buildURL();
      var accountId = this.get('session.data.authenticated.aid');
      var token = this.get('session.data.authenticated.tok');

      // FIXME(ezekg) Can't figure out how to use this action on the model
      fetch(baseUrl + '/accounts/' + accountId + '/analytics/actions/count', {
        headers: {
          "Content-Type": "application/vnd.api+json",
          "Accept": "application/vnd.api+json",
          "Authorization": 'Bearer ' + token
        }
      }).then(function (res) {
        return res.json();
      }).then(function (body) {
        if (_this.isDestroyed) {
          return;
        }

        var analytics = body.meta;
        var errors = body.errors;

        if (errors) {
          var _errors = _slicedToArray(errors, 1);

          var error = _errors[0];

          return _this.setProperties({
            error: 'Looks like something went wrong while fetching some of your account\'s analytics. The data below may be incorrect.',
            analytics: null
          });
        }

        _this.setProperties({
          error: null,
          analytics: analytics
        });
      })['catch'](function (e) {
        if (_this.isDestroyed) {
          return;
        }

        _this.setProperties({
          error: e.message,
          analytics: null
        });
      });
    }
  });
});