define('keygen/models/machine', ['exports', 'ember-api-actions', 'keygen/models/base', 'ember-data', 'ember'], function (exports, _emberApiActions, _keygenModelsBase, _emberData, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _keygenModelsBase['default'].extend({
    type: 'machines',

    account: _emberData['default'].belongsTo('account'),
    product: _emberData['default'].belongsTo('product'),
    group: _emberData['default'].belongsTo('group'),
    license: _emberData['default'].belongsTo('license'),
    owner: _emberData['default'].belongsTo('user', { inverse: 'machines' }),
    processes: _emberData['default'].hasMany('process'),
    components: _emberData['default'].hasMany('component'),

    fingerprint: _emberData['default'].attr('string'),
    cores: _emberData['default'].attr('number'),
    ip: _emberData['default'].attr('string'),
    hostname: _emberData['default'].attr('string'),
    platform: _emberData['default'].attr('string'),
    name: _emberData['default'].attr('string'),
    requireHeartbeat: _emberData['default'].attr('boolean'),
    heartbeatStatus: _emberData['default'].attr('string'),
    heartbeatDuration: _emberData['default'].attr('number'),
    lastHeartbeat: _emberData['default'].attr('date'),
    nextHeartbeat: _emberData['default'].attr('date'),
    lastCheckOut: _emberData['default'].attr('date'),
    metadata: _emberData['default'].attr('json'),
    checkout: (0, _emberApiActions.memberAction)({
      path: 'actions/check-out',
      type: 'POST'
    }),

    universalName: computed('name', 'fingerprint', function () {
      return this.get('name') || this.get('fingerprint');
    }),

    // Relationships
    changeGroup: (0, _emberApiActions.memberAction)({ type: 'PUT', path: 'group' }),
    changeOwner: (0, _emberApiActions.memberAction)({ type: 'PUT', path: 'owner' })
  });
});