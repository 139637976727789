define('keygen/components/kg-search-field', ['exports', 'ember'], function (exports, _ember) {
  var TextField = _ember['default'].TextField;
  exports['default'] = TextField.extend({
    placeholder: '',
    format: 'string',

    click: function click(event) {
      var action = this.get('onClick');
      if (typeof action === 'function') {
        action(event);
      }
    },

    keyUp: function keyUp(event) {
      var action = this.get('onChange');
      var format = this.get('format');
      var key = event.key;
      var value = event.target.value;

      if (key == null) {
        key = String.fromCharCode(event.keyCode);
      }

      // Move cursor to end when enter key is pressed
      if (key === 'Enter') {
        var input = this.$()[0];

        input.setSelectionRange(value.length, value.length);
      }

      switch (format) {
        case 'string':
          {
            value = value.toString();
            break;
          }
        case 'number':
          {
            value = Number(value);
            break;
          }
      }

      this.set('value', value);

      if (typeof action === 'function') {
        action(event, value);
      }
    }
  });
});