define('keygen/transforms/date', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  exports['default'] = _emberData['default'].Transform.extend({

    deserialize: function deserialize(serialized) {
      return serialized ? _moment['default'].utc(serialized) : serialized;
    },

    serialize: function serialize(deserialized) {
      return deserialized ? _moment['default'].utc(deserialized).startOf('day').toISOString() : deserialized;
    }
  });
});