define('keygen/controllers/error', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend({
    currentAccount: service('current-account'),
    currentBearer: service('current-bearer'),
    session: service('session'),

    errors: computed('model', function () {
      var _get = this.get('model');

      var errors = _get.errors;

      return JSON.stringify(errors, null, 2);
    })
  });
});