define('keygen/routes/second-factors/edit', ['exports', 'keygen/routes/second-factors', 'ember'], function (exports, _keygenRoutesSecondFactors, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _keygenRoutesSecondFactors['default'].extend({
    currentAccount: service('current-account'),
    currentBearer: service('current-bearer'),
    flashMessages: service(),

    titleToken: function titleToken() {
      return 'Edit 2FA';
    },

    model: function model(params) {
      return this.store.findRecord('second-factor', params.id, { reload: true });
    },

    actions: {
      removeSecondFactor: function removeSecondFactor(secondFactor, authParams) {
        var _this = this;

        secondFactor.set('request.meta', authParams);

        secondFactor.destroyRecord()['catch'](function (err) {
          return _this.handleModelErrors(secondFactor, err);
        }).then(function () {
          return _this.transitionTo('second-factors.index');
        });
      },
      enableSecondFactor: function enableSecondFactor(secondFactor, authParams) {
        var _this2 = this;

        secondFactor.set('request.meta', authParams);
        secondFactor.set('enabled', true);

        return secondFactor.save()['catch'](function (err) {
          return _this2.handleModelErrors(secondFactor, err);
        }).then(function () {
          var flash = _this2.get('flashMessages');

          flash.clearMessages();
          flash.add({
            message: 'Second factor has been enabled',
            type: 'success',
            timeout: 2500,
            priority: 500
          });

          _this2.refresh();
        });
      },
      disableSecondFactor: function disableSecondFactor(secondFactor, authParams) {
        var _this3 = this;

        secondFactor.set('request.meta', authParams);
        secondFactor.set('enabled', false);

        return secondFactor.save()['catch'](function (err) {
          return _this3.handleModelErrors(secondFactor, err);
        }).then(function () {
          var flash = _this3.get('flashMessages');

          flash.clearMessages();
          flash.add({
            message: 'Second factor has been disabled',
            type: 'success',
            timeout: 2500,
            priority: 500
          });

          _this3.refresh();
        });
      }
    }
  });
});