define('keygen/serializers/policy', ['exports', 'keygen/serializers/base'], function (exports, _keygenSerializersBase) {
  exports['default'] = _keygenSerializersBase['default'].extend({
    attrs: {
      created: { serialize: false },
      updated: { serialize: false },
      scheme: { serialize: ['create', 'new'] },
      metadata: { serialize: ['create', 'new', 'update', 'edit'] },
      account: { serialize: false },
      product: { serialize: ['create', 'new'] },
      licenses: { serialize: false },
      pool: { serialize: false },
      entitlements: { serialize: false }
    }
  });
});