define('keygen/mixins/serialize-for-action', ['exports', 'ember', 'lodash'], function (exports, _ember, _lodash) {
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _ember['default'].Mixin.create({
    /**
     * Update serializion check to also check current action
     *
     * Possible actions could include though not limited to:
     *   - create
     *   - new
     *   - update
     *   - edit
     *   - delete
     *   - destroy
     *
     * @example
     *   ```javascript
     *   attrs: {
     *     relationship: { serialize: { embedded: 'records', actions: ['create', 'new'] } },
     *     attribute: { serialize: ['update', 'edit'] }
     *   }
     *   ````
     */
    _canSerialize: function _canSerialize(key) {
      var attrs = this.get('attrs');
      var currentAction = undefined;
      try {
        currentAction = getOwner(this).lookup('controller:application').currentPath;
      } catch (e) {}

      if (!attrs || !attrs[key]) {
        return true;
      }

      if (((0, _lodash.isArray)(attrs[key].serialize) || (0, _lodash.isObject)(attrs[key].serialize) && attrs[key].serialize.actions) && currentAction != null) {
        var embedded = undefined,
            allowedActions = undefined;
        if ((0, _lodash.isObject)(attrs[key].serialize) && !(0, _lodash.isArray)(attrs[key].serialize)) {
          allowedActions = attrs[key].serialize.actions;
          embedded = attrs[key].serialize.embedded;
        } else {
          allowedActions = attrs[key].serialize;
        }

        var normalizedAction = null;

        if (currentAction === 'register') {
          normalizedAction = currentAction;
        }

        try {
          // Attempt to get the action name e.g. users.edit => edit
          normalizedAction = currentAction.match(/\.([a-z]+)$/)[1];
        } catch (e) {}

        // When action is null then we're probably doing something like
        // creating a record outside of a typical /new or /edit route
        if (normalizedAction == null) {
          return true;
        }

        return allowedActions.includes(normalizedAction);
      }

      return attrs[key].serialize !== false;
    }
  });
});