define('keygen/models/component', ['exports', 'keygen/models/base', 'ember-data'], function (exports, _keygenModelsBase, _emberData) {
  exports['default'] = _keygenModelsBase['default'].extend({
    type: 'components',

    account: _emberData['default'].belongsTo('account'),
    product: _emberData['default'].belongsTo('product'),
    license: _emberData['default'].belongsTo('license'),
    machine: _emberData['default'].belongsTo('machine'),

    fingerprint: _emberData['default'].attr('string'),
    name: _emberData['default'].attr('string'),
    metadata: _emberData['default'].attr('json')
  });
});