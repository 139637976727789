define('keygen/components/kg-resource-grouped-attributes', ['exports', 'ember', 'keygen/components/base', 'lodash', 'npm:copy-to-clipboard', 'moment'], function (exports, _ember, _keygenComponentsBase, _lodash, _npmCopyToClipboard, _moment) {
  var computed = _ember['default'].computed;
  var typeOf = _ember['default'].typeOf;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = _keygenComponentsBase['default'].extend({
    recentlyCopied: false,
    filtered: ['metadata', 'password', 'updated', 'created'],

    groupedAttributes: computed('resource', 'groups', function () {
      var filtered = this.get('filtered');
      var resource = this.get('resource');
      var groups = this.get('groups');
      var res = groups.map(function (group) {
        var attributes = get(group, 'attributes');
        var transformed = (0, _lodash.reduce)(attributes, function (attrs, attr, key) {
          if (!attr || !key || (0, _lodash.includes)(filtered, key)) {
            return attrs;
          }

          // FIXME(ezekg) I have no idea how, but sometimes key is null, so casting to string.
          var value = resource.get('' + key);
          var type = typeOf(value);
          var detail = undefined;

          if (attr.type === 'json' && type === 'string') {
            try {
              value = JSON.parse(value);
            } catch (e) {}
          }

          if (attr.type === 'duration' && value && _moment['default'].unix(value).isValid()) {
            detail = _moment['default'].utc().add(value, 'seconds').fromNow( /* drop in/and verbiage */true);
          }

          if (attr.type === 'date' && value && _moment['default'].unix(value).isValid()) {
            detail = _moment['default'].utc(value).fromNow();
          }

          attrs.push(Object.assign({}, attr, { key: key, value: value, detail: detail }));

          return attrs;
        }, []);

        return Object.assign({}, group, { attributes: transformed });
      });

      return res;
    }),

    actions: {
      handleGroupVisibilityToggle: function handleGroupVisibilityToggle(group) {
        var groups = this.get('groups');

        groups.forEach(function (g) {
          if (get(g, 'label') === get(group, 'label')) {
            set(g, 'visible', !get(g, 'visible'));
          }
        });

        this.notifyPropertyChange('groups');
      },
      copyToClipboard: function copyToClipboard(text, _event) {
        var _this = this;

        setTimeout(function () {
          return _this.set('recentlyCopied', false);
        }, 2500);
        this.set('recentlyCopied', (0, _npmCopyToClipboard['default'])(text));
      }
    }
  });
});