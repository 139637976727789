define('keygen/routes/keys', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'keygen/routes/base'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _keygenRoutesBase) {
  exports['default'] = _keygenRoutesBase['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    actions: {
      create: function create(key) {
        var _this = this;

        key.save()['catch'](function (err) {
          return _this.handleModelErrors(key, err);
        }).then(function (l) {
          var flash = _this.get('flashMessages');
          if (flash != null) {
            flash.clearMessages();
          }

          _this.transitionTo('keys.show', l.id);
        });
      },
      update: function update(key) {
        var _this2 = this;

        key.save()['catch'](function (err) {
          return _this2.handleModelErrors(key, err);
        }).then(function (l) {
          var flash = _this2.get('flashMessages');
          if (flash != null) {
            flash.clearMessages();
          }

          _this2.transitionTo('keys.show', l.id);
        });
      },
      'delete': function _delete(key) {
        var _this3 = this;

        if (confirm("Are you sure? This cannot be undone.")) {
          key.destroyRecord()['catch'](function (err) {
            return _this3.handleModelErrors(key, err);
          }).then(function () {
            return _this3.transitionTo('keys.index');
          });
        }
      }
    }
  });
});