define('keygen/routes/packages/index', ['exports', 'keygen/routes/packages'], function (exports, _keygenRoutesPackages) {
  exports['default'] = _keygenRoutesPackages['default'].extend({
    queryParams: {
      page: { refreshModel: true },
      size: { refreshModel: true }
    },

    titleToken: function titleToken() {
      return 'Packages';
    },

    model: function model(params) {
      return this.store.query('package', {
        page: {
          number: params.page,
          size: params.size
        }
      });
    }
  });
});