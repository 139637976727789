define('keygen/initializers/fix-change-tracker-lazy-relationships', ['exports', 'ember-data/store', 'ember-data-change-tracker/tracker'], function (exports, _emberDataStore, _emberDataChangeTrackerTracker) {
  exports.initialize = initialize;

  // FIXME(ezekg) Hack to make sure ember-data-change-tracker correctly tracks
  //              lazy loaded relationships. Without this, it thinks the act
  //              of loading the relationship is a "change."

  function initialize() /* application */{
    _emberDataStore['default'].reopen({
      findBelongsTo: function findBelongsTo(internalModel, link, relationship /*, options */) {
        var _this = this;

        return this._super.apply(this, arguments).then(function (data) {
          // FIXME(ezekg) Need to run this after Ember finishes storing the data,
          //              otherwise it's still considered a "change."
          setTimeout(function () {
            return _this._resetChangeTrackingOfRelationship(internalModel.getRecord(), relationship.key);
          }, 50);

          return data;
        });
      },

      findHasMany: function findHasMany(internalModel, link, relationship /*, options */) {
        var _this2 = this;

        return this._super.apply(this, arguments).then(function (data) {
          setTimeout(function () {
            return _this2._resetChangeTrackingOfRelationship(internalModel.getRecord(), relationship.key);
          }, 50);

          return data;
        });
      },

      _resetChangeTrackingOfRelationship: function _resetChangeTrackingOfRelationship(model, relationshipName) {
        _emberDataChangeTrackerTracker['default'].saveKey(model, relationshipName);
      }
    });
  }

  exports['default'] = {
    initialize: initialize
  };
});