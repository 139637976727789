define('keygen/routes/request-logs/index', ['exports', 'keygen/routes/request-logs'], function (exports, _keygenRoutesRequestLogs) {
  exports['default'] = _keygenRoutesRequestLogs['default'].extend({
    queryParams: {
      'requestor[type]': { refreshModel: true },
      'requestor[id]': { refreshModel: true },
      'resource[type]': { refreshModel: true },
      'resource[id]': { refreshModel: true },
      request: { refreshModel: true },
      method: { refreshModel: true },
      status: { refreshModel: true },
      url: { refreshModel: true },
      ip: { refreshModel: true },
      event: { refreshModel: true },
      page: { refreshModel: true },
      size: { refreshModel: true }
    },

    titleToken: function titleToken() {
      return 'Request Logs';
    },

    model: function model(params) {
      var request = params.request;
      var method = params.method;
      var status = params.status;
      var url = params.url;
      var ip = params.ip;
      var event = params.event;

      var date = undefined;
      if (params.start && params.end) {
        date = {
          start: params.start,
          end: params.end
        };
      }

      // FIXME(ezekg) I guess Ember doesn't support hash query params?
      var requestor = undefined;
      if (params['requestor[type]'] && params['requestor[id]']) {
        requestor = {
          type: params['requestor[type]'],
          id: params['requestor[id]']
        };
      }

      var resource = undefined;
      if (params['resource[type]'] && params['resource[id]']) {
        resource = {
          type: params['resource[type]'],
          id: params['resource[id]']
        };
      }

      return this.store.query('request-log', {
        request: request,
        method: method,
        status: status,
        url: url,
        ip: ip,
        event: event,
        requestor: requestor,
        resource: resource,
        date: date,
        page: {
          number: params.page,
          size: params.size
        }
      });
    }
  });
});