define('keygen/serializers/user', ['exports', 'keygen/serializers/base', 'ember'], function (exports, _keygenSerializersBase, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _keygenSerializersBase['default'].extend({
    currentBearer: service('current-bearer'),

    attrs: {
      fullName: { serialize: false },
      password: { serialize: ['register', 'create', 'new', 'edit'] },
      metadata: { serialize: ['create', 'new', 'update', 'edit'] },
      status: { serialize: false },
      permissions: { serialize: ['create', 'new', 'update', 'edit'] },
      role: { serialize: ['create', 'new', 'update', 'edit'] },
      created: { serialize: false },
      updated: { serialize: false },
      account: { serialize: false },
      group: { serialize: ['create', 'new'] },
      products: { serialize: false },
      licenses: { serialize: false },
      machines: { serialize: false },
      tokens: { serialize: false }
    },

    serialize: function serialize(snapshot, options) {
      var bearer = this.get('currentBearer');
      var json = this._super.apply(this, arguments);

      if (json.data != null && json.data.attributes != null) {
        // These attributes get serialized for new records, and we don't want
        // that, since the API doesn't allow these params for non-admins.
        if (bearer != null && !bearer.get('isAdmin')) {
          delete json.data.attributes.role;
        }

        // Permissions should not be serialized by default.
        if (json.data.attributes.permissions != null && json.data.attributes.permissions.length === 0) {
          delete json.data.attributes.permissions;
        }
      }

      return json;
    }
  });
});