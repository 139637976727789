define('keygen/models/product', ['exports', 'keygen/models/bearer', 'ember-data', 'ember'], function (exports, _keygenModelsBearer, _emberData, _ember) {
  var computed = _ember['default'].computed;
  var PRODUCT_PERMISSIONS = ['*', 'account.read', 'arch.read', 'artifact.create', 'artifact.delete', 'artifact.read', 'artifact.update', 'channel.read', 'constraint.read', 'entitlement.read', 'group.create', 'group.delete', 'group.licenses.read', 'group.machines.read', 'group.owners.attach', 'group.owners.detach', 'group.owners.read', 'group.read', 'group.update', 'group.users.read', 'key.create', 'key.delete', 'key.read', 'key.update', 'license.check-in', 'license.check-out', 'license.create', 'license.delete', 'license.entitlements.attach', 'license.entitlements.detach', 'license.group.update', 'license.policy.update', 'license.read', 'license.reinstate', 'license.renew', 'license.revoke', 'license.suspend', 'license.tokens.generate', 'license.update', 'license.usage.decrement', 'license.usage.increment', 'license.usage.reset', 'license.user.update', 'license.owner.update', 'license.users.attach', 'license.users.detach', 'license.validate', 'machine.check-out', 'machine.create', 'machine.delete', 'machine.group.update', 'machine.heartbeat.ping', 'machine.heartbeat.reset', 'machine.proofs.generate', 'machine.read', 'machine.update', 'machine.owner.update', 'platform.read', 'policy.create', 'policy.delete', 'policy.entitlements.attach', 'policy.entitlements.detach', 'policy.pool.pop', 'policy.read', 'policy.update', 'process.create', 'process.delete', 'process.heartbeat.ping', 'process.read', 'process.update', 'product.read', 'product.update', 'release.constraints.attach', 'release.constraints.detach', 'release.create', 'release.delete', 'release.download', 'release.publish', 'release.read', 'release.update', 'release.upgrade', 'release.upload', 'release.yank', 'token.generate', 'token.read', 'token.regenerate', 'token.revoke', 'user.ban', 'user.create', 'user.group.update', 'user.read', 'user.tokens.generate', 'user.unban', 'user.update', 'webhook-endpoint.create', 'webhook-endpoint.delete', 'webhook-endpoint.read', 'webhook-endpoint.update', 'webhook-event.read'];

  exports.PRODUCT_PERMISSIONS = PRODUCT_PERMISSIONS;
  exports['default'] = _keygenModelsBearer['default'].extend({
    type: 'products',

    account: _emberData['default'].belongsTo('account'),
    policies: _emberData['default'].hasMany('policy'),
    licenses: _emberData['default'].hasMany('license'),
    machines: _emberData['default'].hasMany('machine'),
    users: _emberData['default'].hasMany('user'),
    name: _emberData['default'].attr('string'),
    url: _emberData['default'].attr('string'),
    distributionStrategy: _emberData['default'].attr('string'),
    platforms: _emberData['default'].attr('array'),
    permissions: _emberData['default'].attr('array'),
    metadata: _emberData['default'].attr('json'),
    role: 'product',

    universalName: computed('name', function () {
      return this.get('name');
    }),

    availablePermissions: computed('permissions', function () {
      var perms = this.get('permissions');
      if (perms != null && perms.includes('*')) {
        return ['*'];
      }

      return PRODUCT_PERMISSIONS;
    })
  });
});