define('keygen/routes/demo', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsUnauthenticatedRouteMixin, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {
    currentAccount: service('current-account'),
    currentBearer: service('current-bearer'),
    flashMessages: service('flashMessages'),
    session: service('session'),

    title: function title() {
      return 'Demo';
    },

    beforeModel: function beforeModel() {
      var _this = this;

      var session = this.get('session');

      session.authenticate('authenticator:token', 'demo', 'demo@keygen.sh', 'fb3b9f0abe0d812d2ef110f72a600461', null, moment().utc().add(1, 'day').format()).then(function () {
        return _this.get('currentAccount').load();
      }).then(function () {
        return _this.get('currentBearer').load();
      }).then(function () {
        return _this.transitionTo('index');
      })['catch'](function (err) {
        var flash = _this.get('flashMessages');

        flash.clearMessages();
        flash.add({
          message: 'Failed to start demo: ' + err.message,
          type: 'error',
          timeout: Infinity
        });
      });
    }
  });
});