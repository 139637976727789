define('keygen/serializers/second-factor', ['exports', 'keygen/serializers/base'], function (exports, _keygenSerializersBase) {
  exports['default'] = _keygenSerializersBase['default'].extend({
    attrs: {
      account: { serialize: false },
      user: { serialize: false },
      enabled: { serialize: ['edit'] },
      uri: { serialize: false },
      created: { serialize: false },
      updated: { serialize: false }
    }
  });
});