define('keygen/controllers/tokens/show', ['exports', 'keygen/controllers/application', 'npm:copy-to-clipboard', 'ember'], function (exports, _keygenControllersApplication, _npmCopyToClipboard, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _keygenControllersApplication['default'].extend({
    isCurrentSessionToken: computed('session.data.authenticated.tid', 'model', 'model.id', function () {
      return this.get('session.data.authenticated.tid') === this.get('model.id');
    }),

    hasNewlyGeneratedToken: computed('model', 'model.token', function () {
      var model = this.get('model');

      return model.get('token') != null;
    }),

    hasRecentlyBeenCopied: false,

    actions: {
      copyTokenToClipboard: function copyTokenToClipboard() {
        var _this = this;

        var token = this.get('model.token');

        setTimeout(function () {
          return _this.set('hasRecentlyBeenCopied', false);
        }, 2500);

        this.set('hasRecentlyBeenCopied', (0, _npmCopyToClipboard['default'])(token));
      },
      closeNewlyGeneratedTokenModal: function closeNewlyGeneratedTokenModal() {
        var model = this.get('model');

        model.reload();
      }
    }
  });
});