define('keygen/components/kg-resource-attributes', ['exports', 'ember', 'keygen/components/base', 'lodash', 'npm:copy-to-clipboard', 'moment'], function (exports, _ember, _keygenComponentsBase, _lodash, _npmCopyToClipboard, _moment) {
  var typeOf = _ember['default'].typeOf;
  exports['default'] = _keygenComponentsBase['default'].extend({
    recentlyCopied: false,
    filtered: ['metadata', 'password', 'updated', 'created'],

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var resource = this.get('resource');
      var attrs = this.get('attrs');

      delete attrs.attributes;
      delete attrs.resource;

      if ((0, _lodash.isEmpty)(attrs)) {
        return;
      }

      if (this.isDestroyed || this.isDestroying) {
        return; // Object has been destroyed (probably transitioned to a new route)
      }

      this.set('attributes', (0, _lodash.reduce)(attrs, function (attributes, attr, key) {
        if (typeof attr === 'undefined') {
          return attributes;
        }

        if (attr.value) {
          attributes.push(Object.assign({}, attr));

          return attributes;
        }

        if (!(0, _lodash.includes)(_this.get('filtered'), key)) {
          var value = resource.get(key);
          var type = typeOf(value);
          var detail = undefined;

          // Add observer to recompute attributes when attribute is modified
          resource.addObserver(key, _this, 'didReceiveAttrs');

          if (attr.type === "json" && type === "string") {
            try {
              value = JSON.parse(value);
            } catch (e) {}
          }

          if (attr.type === "duration" && value && _moment['default'].unix(value).isValid()) {
            detail = _moment['default'].utc().add(value, 'seconds').fromNow( /* drop in/and verbiage */true);
          }

          if (attr.type === "date" && value && _moment['default'].unix(value).isValid()) {
            detail = _moment['default'].utc(value).fromNow();
          }

          // Special case for unavailable webhook status
          if (key === 'status' && value === 'unavailable' && resource.get('constructor.modelName') === 'webhook-event') {
            detail = 'status has expired';
          }

          // Special case for license validation
          if (key === 'valid' && resource.get('constructor.modelName') === 'license') {
            (function () {
              var index = attributes.length;

              resource.validate().then(function (response) {
                if (_this.isDestroyed || _this.isDestroying) {
                  return; // Object has been destroyed (probably transitioned to a new route)
                }
                _this.set('attributes.' + index + '.value', response.meta.valid);
                _this.set('attributes.' + index + '.detail', response.meta.detail);
              })['catch'](function (err) {
                if (_this.isDestroyed || _this.isDestroying) {
                  return; // Object has been destroyed (probably transitioned to a new route)
                }
                _this.set('attributes.' + index + '.value', 'N/A');
                _this.set('attributes.' + index + '.detail', 'validation request failed');
              });
            })();
          }

          attributes.push(Object.assign({}, attr, { value: value, detail: detail }));
        }
        return attributes;
      }, []));
    },

    actions: {
      copyToClipboard: function copyToClipboard(text, event) {
        var _this2 = this;

        setTimeout(function () {
          return _this2.set('recentlyCopied', false);
        }, 2500);
        this.set('recentlyCopied', (0, _npmCopyToClipboard['default'])(text));
      }
    }
  });
});