define('keygen/models/webhook-endpoint', ['exports', 'keygen/models/base', 'ember-data', 'ember'], function (exports, _keygenModelsBase, _emberData, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _keygenModelsBase['default'].extend({
    type: 'webhook-endpoints',

    account: _emberData['default'].belongsTo('account'),
    url: _emberData['default'].attr('string'),
    subscriptions: _emberData['default'].attr('array', { defaultValue: function defaultValue() {
        return ['*'];
      } }),
    apiVersion: _emberData['default'].attr('string'),

    eventTypes: computed('subscriptions', function () {
      var subs = this.getWithDefault('subscriptions', []);
      var events = ['account.updated', 'account.subscription.paused', 'account.subscription.resumed', 'account.subscription.canceled', 'account.subscription.renewed', 'account.plan.updated', 'account.billing.updated', 'user.created', 'user.updated', 'user.deleted', 'user.password-reset', 'user.banned', 'user.unbanned', 'package.created', 'package.updated', 'package.deleted', 'product.created', 'product.updated', 'product.deleted', 'policy.created', 'policy.updated', 'policy.deleted', 'policy.entitlements.attached', 'policy.entitlements.detached', 'license.created', 'license.updated', 'license.deleted', 'license.expiring-soon', 'license.expired', 'license.checked-in', 'license.check-in-required-soon', 'license.check-in-overdue', 'license.validation.succeeded', 'license.validation.failed', 'license.usage.incremented', 'license.usage.decremented', 'license.usage.reset', 'license.renewed', 'license.revoked', 'license.suspended', 'license.reinstated', 'license.policy.updated', 'license.owner.updated', 'license.user.updated', 'license.entitlements.attached', 'license.entitlements.detached', 'license.users.attached', 'license.users.detached', 'machine.created', 'machine.updated', 'machine.deleted', 'machine.heartbeat.ping', 'machine.heartbeat.dead', 'machine.heartbeat.resurrected', 'machine.heartbeat.reset', 'machine.owner.updated', 'process.created', 'process.updated', 'process.deleted', 'process.heartbeat.ping', 'process.heartbeat.dead', 'process.heartbeat.resurrected', 'component.created', 'component.updated', 'component.deleted', 'entitlement.created', 'entitlement.updated', 'entitlement.deleted', 'token.generated', 'token.regenerated', 'token.revoked', 'release.created', 'release.updated', 'release.deleted', 'release.upgraded', 'release.package.updated', 'release.published', 'release.yanked', 'release.constraints.attached', 'release.constraints.detached', 'artifact.created', 'artifact.updated', 'artifact.deleted', 'artifact.uploaded', 'artifact.downloaded', '*'];

      if (subs.some(function (s) {
        return s === '*';
      })) {
        return ['*'];
      }

      return events;
    })
  });
});