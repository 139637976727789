define('keygen/components/kg-plans', ['exports', 'keygen/components/base', 'ember'], function (exports, _keygenComponentsBase, _ember) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  exports['default'] = _keygenComponentsBase['default'].extend({
    publicPlans: computed('plans.[]', function () {
      var plans = this.get('plans');

      return plans.filter(function (p) {
        return !get(p, 'private');
      });
    }),

    groupedPlans: computed('publicPlans', function () {
      var plans = this.get('publicPlans');

      return [{
        groupName: 'Monthly',
        options: plans.filter(function (p) {
          return p.get('interval') === 'mo';
        })
      }, {
        groupName: 'Yearly',
        options: plans.filter(function (p) {
          return p.get('interval') === 'yr';
        })
      }];
    }),

    actions: {
      updatePlan: function updatePlan(account, plan) {
        account.set('plan', plan);
      }
    }
  });
});