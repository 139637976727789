define('keygen/routes/base', ['exports', 'keygen/mixins/reset-scroll', 'ember', 'npm:raygun4js'], function (exports, _keygenMixinsResetScroll, _ember, _npmRaygun4js) {
  var service = _ember['default'].inject.service;
  var observer = _ember['default'].observer;
  var computed = _ember['default'].computed;

  var toFlashMessage = function toFlashMessage(err) {
    var title = err.title;
    var source = err.source;
    var detail = err.detail;

    var src = source != null ? (source.pointer || source.parameter).replace(/\/data(\/\w+\/)?/, '') : null;
    var msg = src != null ? src + ' ' + detail : detail;

    return title + ': ' + msg;
  };

  exports['default'] = _ember['default'].Route.extend(_keygenMixinsResetScroll['default'], {
    flashMessages: service('flashMessages'),
    session: service('session'),

    navigation: _ember['default'].Object.create({ open: false }),

    // TODO(ezekg) Retain search terms between transitions
    search: _ember['default'].Object.create({ open: false }),

    isNavigationOpen: computed.alias('navigation.open'),
    isSearchOpen: computed.alias('search.open'),

    handleModelErrors: function handleModelErrors(model, err) {
      var flash = this.get('flashMessages');
      var state = model != null && model.currentState != null ? model.currentState.stateName : null;

      try {
        var _err$errors = err.errors;
        var errors = _err$errors === undefined ? [] : _err$errors;

        flash.clearMessages();
        errors.map(function (e) {
          return flash.add({ message: toFlashMessage(e), type: 'error', timeout: Infinity });
        });

        if (model != null && (state === 'root.deleted.invalid' || state === 'root.loaded.updated.invalid')) {
          model.rollback();
        }
      } catch (e) {
        (0, _npmRaygun4js['default'])('send', { error: e, customData: { model: model, state: state } });
      }

      throw err;
    },

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('navigation', this.get('navigation'));
      controller.set('search', this.get('search'));
    },

    resetController: function resetController(controller, isExiting) {
      if (!isExiting) {
        return;
      }

      var queryParams = controller.get('queryParams');

      queryParams.forEach(function (param) {
        return controller.set(param, undefined);
      });
    },

    actions: {
      willTransition: function willTransition(transition) {
        this._super.apply(this, arguments);

        var model = this.controller.get('model');
        var pendingChanges = model && model.get && !model.get('isDeleted') && !model.get('isNew') && model.get('hasDirtyAttributes') && /edit$/.test(this.get('routeName'));

        // Rollback unsaved changes when transitioning
        if (pendingChanges) {
          if (confirm("Are you sure you want to leave this page? All pending changes to this resource will be lost.")) {
            model.rollbackAttributes();
          } else {
            transition.abort();
          }
        }

        return true;
      },

      didTransition: function didTransition() {
        this._super.apply(this, arguments);

        this.send('handleNavigationClose');
        this.send('handleSearchClose');

        return true;
      },

      handleNavigationOpenToggle: function handleNavigationOpenToggle() {
        this.toggleProperty('navigation.open');
      },

      handleSearchOpenToggle: function handleSearchOpenToggle() {
        this.toggleProperty('search.open');
      },

      handleNavigationOpen: function handleNavigationOpen() {
        this.set('navigation.open', true);
      },

      handleSearchOpen: function handleSearchOpen() {
        this.set('search.open', true);
      },

      handleNavigationClose: function handleNavigationClose() {
        this.set('navigation.open', false);
      },

      handleSearchClose: function handleSearchClose() {
        this.set('search.open', false);
      },

      logout: function logout() {
        var _this = this;

        var session = this.get('session');

        if (session.get('isAuthenticated')) {
          session.invalidate().then(function () {
            return _this.transitionTo('login');
          });
        }
      }
    }
  });
});