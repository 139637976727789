define('keygen/components/kg-second-factor/new', ['exports', 'keygen/components/base', 'ember'], function (exports, _keygenComponentsBase, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _keygenComponentsBase['default'].extend({
    tagName: '',

    authParams: {
      password: null
    },

    isSecondFactorPresent: computed.notEmpty('secondFactor')
  });
});