define('keygen/controllers/releases/index', ['exports', 'keygen/controllers/application'], function (exports, _keygenControllersApplication) {
  exports['default'] = _keygenControllersApplication['default'].extend({
    queryParams: ['page', 'size', 'yanked', 'filetype', 'platform', 'channel', 'product'],
    yanked: null,
    filetype: null,
    platform: null,
    channel: null,
    product: null,
    page: 1,
    size: 20
  });
});