define('keygen/routes/settings/password', ['exports', 'keygen/routes/settings', 'ember'], function (exports, _keygenRoutesSettings, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var service = _ember['default'].inject.service;
  var get = _ember['default'].get;
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _keygenRoutesSettings['default'].extend({
    currentAccount: service('current-account'),
    currentBearer: service('current-bearer'),

    titleToken: function titleToken() {
      return 'Change admin password';
    },

    model: function model(params) {
      return _ember['default'].Object.create({
        oldPassword: null,
        newPassword: null
      });
    },

    actions: {
      update: function update(model) {
        var _this = this;

        var baseUrl = getOwner(this).lookup('adapter:application').buildURL();
        var flash = get(this, 'flashMessages');
        var token = get(this, 'session.data.authenticated.tok');

        var _get = get(this, 'currentAccount');

        var account = _get.account;

        var _get2 = get(this, 'currentBearer');

        var bearer = _get2.bearer;
        var oldPassword = model.oldPassword;
        var newPassword = model.newPassword;

        if (oldPassword == null || newPassword == null) {
          flash.clearMessages();
          flash.add({
            message: 'Please fill out all form fields.',
            type: 'error',
            timeout: Infinity
          });

          return null;
        }

        fetch(baseUrl + '/accounts/' + account.id + '/users/' + bearer.id + '/actions/update-password', {
          method: "POST",
          headers: {
            "Content-Type": "application/vnd.api+json",
            "Accept": "application/vnd.api+json",
            "Authorization": 'Bearer ' + token
          },
          body: JSON.stringify({
            meta: { oldPassword: oldPassword, newPassword: newPassword }
          })
        }).then(function (response) {
          if (response.status !== 200) {
            return response.json();
          }

          flash.clearMessages();
          flash.add({
            message: 'Password was successfully updated.',
            type: 'success',
            timeout: Infinity
          });

          _this.transitionTo('settings.show');
        }).then(function (json) {
          if (!json) {
            return;
          }

          var _json$errors = _slicedToArray(json.errors, 1);

          var detail = _json$errors[0].detail;

          flash.clearMessages();
          flash.add({
            message: 'Error while updating password: ' + detail,
            type: 'error',
            timeout: Infinity
          });
        })['catch'](function (e) {
          flash.clearMessages();
          flash.add({
            message: 'Failed to update your password.',
            type: 'error',
            timeout: Infinity
          });
        });
      }
    }
  });
});