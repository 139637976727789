define("keygen/components/kg-logo", ["exports", "keygen/components/base", "npm:gaussian", "npm:random-number", "npm:baffle"], function (exports, _keygenComponentsBase, _npmGaussian, _npmRandomNumber, _npmBaffle) {
  exports["default"] = _keygenComponentsBase["default"].extend({
    style: "light",
    color: "#00fe9b",
    size: 64,

    didInsertElement: function didInsertElement() {
      this.logo = document.querySelector(".logo");
      this.favicon = document.querySelector("link[rel*='icon']");
      this.canvas = document.querySelector(".logo__logomark");
      this.ctx = this.canvas.getContext("2d");

      // Update width/height to support retina screens
      var _canvas = this.canvas;
      var width = _canvas.width;
      var height = _canvas.height;

      this.canvas.width *= window.devicePixelRatio;
      this.canvas.height *= window.devicePixelRatio;

      this.canvas.style.width = width + "px";
      this.canvas.style.height = height + "px";
    },

    didRender: function didRender() {
      this._shuffle();
      try {
        this._clear();
        this._draw();
      } catch (e) {}
    },

    _clear: function _clear() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    },

    _draw: function _draw() {
      var points = this._generateRandomPoints((this.canvas.width - 10) / 2, (this.canvas.height - 10) / 2, (this.canvas.width - 10) / 2, 0.5, 0.001, 5).map(function (point) {
        return point + 5 // Make sure we're always within the canvas
        ;
      });

      // Draw logomark
      this.ctx.beginPath();
      this.ctx.moveTo(points[0], points[1]);

      for (var i = 2; i < points.length - 1; i += 2) {
        this.ctx.lineTo(points[i], points[i + 1]);
      }

      this.ctx.closePath();

      this.ctx.strokeStyle = this.get('color');

      switch (this.get('size')) {
        case 64:
          {
            this.ctx.lineWidth = this.canvas.width / 14 + 1;
            break;
          }
        default:
          {
            this.ctx.lineWidth = this.canvas.width / 12 + 1;
          }
      }

      this.ctx.stroke();

      // Show logomark
      this.logo.className += " logo--with-logomark";

      // Duplicate canvas so we can create a greyscale favicon
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");

      canvas.width = this.canvas.width;
      canvas.height = this.canvas.height;

      // Draw favicon
      ctx.beginPath();
      ctx.moveTo(points[0], points[1]);

      for (var i = 2; i < points.length - 1; i += 2) {
        ctx.lineTo(points[i], points[i + 1]);
      }

      ctx.closePath();

      if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
        ctx.strokeStyle = "#00fe9b";
      } else {
        ctx.strokeStyle = "#000";
      }

      ctx.lineWidth = 5 * window.devicePixelRatio;

      switch (this.get('size')) {
        case 64:
          {
            ctx.lineWidth = 5 * window.devicePixelRatio;
            break;
          }
        default:
          {
            ctx.lineWidth = 3 * window.devicePixelRatio;
          }
      }

      ctx.stroke();

      // Update favicon
      this.favicon.href = canvas.toDataURL("image/png");
    },

    _shuffle: function _shuffle() {
      (0, _npmBaffle["default"])(".logo__logotype").start().reveal(1000, 500);
    },

    /**
     * @link http://stackoverflow.com/a/25276331/3247081
     *
     * @param {Int} ctrX, ctrY - coordinates of the "centre" of the polygon
     * @param {Int} aveRadius - in px, the average radius of this polygon, this roughly controls how large the polygon is, really only useful for order of magnitude.
     * @param {Int} irregularity - [0,1] indicating how much variance there is in the angular spacing of vertices. [0,1] will map to [0, 2pi/numberOfVerts]
     * @param {Int} spikeyness - [0,1] indicating how much variance there is in each vertex from the circle of radius aveRadius. [0,1] will map to [0, aveRadius]
     * @param {Int} numVerts - self-explanatory
     *
     * @return {Arr} Array of vertices, in CCW order.
     */
    _generateRandomPoints: function _generateRandomPoints(ctrX, ctrY, aveRadius, irregularity, spikeyness, numVerts) {
      irregularity = this._clip(irregularity, 0, 1) * 2 * Math.PI / numVerts;
      spikeyness = this._clip(spikeyness, 0, 1) * aveRadius;

      // Generate n angle steps
      var angleSteps = [];
      var lower = 2 * Math.PI / numVerts - irregularity;
      var upper = 2 * Math.PI / numVerts + irregularity;
      var sum = 0;

      for (var i = 0; i < numVerts; i++) {
        var tmp = (0, _npmRandomNumber["default"])({ min: lower, max: upper });

        angleSteps.push(tmp);

        sum += tmp;
      }

      // Normalize the steps so that point 0 and point n+1 are the same
      var k = sum / (2 * Math.PI);

      for (var i = 0; i < numVerts; i++) {
        angleSteps[i] = angleSteps[i] / k;
      }

      // Generate the points
      var points = [];
      var angle = (0, _npmRandomNumber["default"])({ min: 0, max: 2 * Math.PI });

      for (var i = 0; i < numVerts; i++) {
        var gauss = (0, _npmGaussian["default"])(aveRadius, spikeyness).ppf(Math.random());
        var rad = this._clip(gauss, 0, 2 * aveRadius);
        var x = ctrX + rad * Math.cos(angle);
        var y = ctrY + rad * Math.sin(angle);

        points.push(+x, +y);

        angle += angleSteps[i];
      }

      return points;
    },

    _clip: function _clip(x, min, max) {
      if (min > max) {
        return x;
      } else if (x < min) {
        return min;
      } else if (x > max) {
        return max;
      } else {
        return x;
      }
    }
  });
});