define('keygen/routes/invite/accept', ['exports', 'keygen/routes/invite'], function (exports, _keygenRoutesInvite) {
  exports['default'] = _keygenRoutesInvite['default'].extend({
    titleToken: function titleToken() {
      return 'Accept Invite';
    },
    model: function model(_ref) {
      var token = _ref.token;

      this.transitionTo('recover.edit', { token: token });
    }
  });
});