define('keygen/routes/policies/index', ['exports', 'keygen/routes/policies'], function (exports, _keygenRoutesPolicies) {
  exports['default'] = _keygenRoutesPolicies['default'].extend({
    queryParams: {
      page: { refreshModel: true },
      size: { refreshModel: true },
      product: { refreshModel: true }
    },

    titleToken: function titleToken() {
      return 'Policies';
    },

    model: function model(params) {
      var number = params.page;
      var size = params.size;
      var product = params.product;

      return this.store.query('policy', {
        page: { number: number, size: size },
        product: product
      });
    }
  });
});