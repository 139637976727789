define('keygen/services/resource-limits', ['exports', 'ember-data', 'ember', 'lodash'], function (exports, _emberData, _ember, _lodash) {

  var FAILED_WEBHOOK_THRESHOLD = 5;
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Service.extend({
    currentAccount: service('current-account'),
    currentBearer: service('current-bearer'),
    store: service('store'),

    account: computed.alias('currentAccount.account'),
    bearer: computed.alias('currentBearer.bearer'),
    isDemo: computed.alias('currentAccount.isDemo'),

    dailyRequestLimit: computed.alias('account.plan.maxReqs'),

    isFailedWebhookThresholdMet: computed('account.webhookEvents.firstObject', 'account.webhookEndpoints.firstObject', 'isDemo', function () {
      // Skip for demo
      if (this.get('isDemo')) {
        return false;
      }

      // Only for admins
      if (!this.get('bearer.isAdmin') && !this.get('bearer.isDev')) {
        return false;
      }

      // Canceled accounts aren't allowed to read webhook endpoints
      if (this.get('account.status') === 'CANCELED') {
        return false;
      }

      try {
        var endpoints = this.get('account.webhookEndpoints').toArray();
        if (!endpoints.length) {
          return false;
        }

        var events = (0, _lodash.sortBy)(this.get('account.webhookEvents').toArray(), function (e) {
          return e.get('created');
        });
        var count = events.filter(function (e) {
          return e.get('status') === 'FAILING' || e.get('status') === 'FAILED';
        }).length;

        return count >= FAILED_WEBHOOK_THRESHOLD;
      } catch (e) {
        return false;
      }
    }),

    hasExceededAdminResourceLimit: computed('account.plan.maxAdmins', 'account.plan.price', 'account.admins.[]', 'store.admins', 'isDemo', function () {
      // Skip for demo
      if (this.get('isDemo')) {
        return false;
      }

      if (this.get('account.status') === 'CANCELED') {
        return false;
      }

      var maxAdmins = this.get('account.plan.maxAdmins');
      if (!maxAdmins) {
        return false;
      }

      // Skip for free tier (let them do what they want until upgrading)
      var price = this.get('account.plan.price');
      if (!price) {
        return false;
      }

      return _emberData['default'].PromiseObject.create({
        promise: this.get('store').query('user', { roles: ['admin', 'developer', 'sales-agent', 'support-agent'], limit: 25 }).then(function (admins) {
          var adminCount = admins.get('content.length');
          if (!adminCount) {
            return false;
          }

          return adminCount > maxAdmins;
        })['catch'](function () {
          return false;
        })
      });
    })
  });
});