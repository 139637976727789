define('keygen/components/kg-billing', ['exports', 'keygen/config/environment', 'keygen/components/base', 'ember'], function (exports, _keygenConfigEnvironment, _keygenComponentsBase, _ember) {
  var service = _ember['default'].inject.service;
  var RSVP = _ember['default'].RSVP;
  var get = _ember['default'].get;
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _keygenComponentsBase['default'].extend({
    flashMessages: service('flashMessages'),
    session: service('session'),
    router: service('-routing'),

    actions: {
      reactivateAccount: function reactivateAccount(account) {
        var baseUrl = getOwner(this).lookup('adapter:application').buildURL();
        var flash = get(this, 'flashMessages');
        var token = get(this, 'session.data.authenticated.tok');

        fetch(baseUrl + '/accounts/' + account.id + '/plan', {
          redirect: 'manual',
          method: 'PUT',
          headers: {
            'Accept': 'application/vnd.api+json',
            'Authorization': 'Bearer ' + token
          },
          body: JSON.stringify({
            data: { type: 'plan', id: 'fba5986e-05d0-46e8-98cb-922a45887d4d' }
          })
        }).then(function (response) {
          if (response.status !== 200) {
            return RSVP.reject('received status code ' + response.status);
          }

          flash.clearMessages();
          flash.add({
            message: 'Account has been reactivated! Choose a new plan from your Billing Portal.',
            type: 'success',
            timeout: 1000,
            priority: 500
          });

          // Since subscription state is processed async, we're going to go ahead
          // update the state manually
          account.get('billing').then(function (billing) {
            billing.setProperties({ state: 'pending' });
          });

          // Reload account state
          account.reload();
        })['catch'](function (err) {
          console.error('[kg-billing.reactivateAccount] Unexpected error during reactivation', err);

          flash.clearMessages();
          flash.add({
            message: 'Failed to reactivate account: ' + err,
            type: 'error',
            timeout: Infinity
          });
        });
      },

      manageBilling: function manageBilling(account) {
        var baseUrl = getOwner(this).lookup('adapter:application').buildURL();
        var flash = get(this, 'flashMessages');
        var token = get(this, 'session.data.authenticated.tok');

        flash.clearMessages();
        flash.add({
          message: 'Redirecting you to billing management portal…',
          type: 'success',
          timeout: 1000,
          priority: 500
        });

        fetch(baseUrl + '/accounts/' + account.id + '/actions/manage-subscription', {
          redirect: 'manual',
          method: 'POST',
          headers: {
            'Accept': 'application/vnd.api+json',
            'Authorization': 'Bearer ' + token
          }
        }).then(function (response) {
          if (response.status !== 200) {
            return RSVP.reject('received status code ' + response.status);
          }

          return response.json();
        }).then(function (body) {
          window.location.href = body.meta.url;
        })['catch'](function (err) {
          console.error('[kg-billing.manageBilling] Unexpected error during billing management initialization', err);

          flash.clearMessages();
          flash.add({
            message: 'Failed to open the billing management portal…',
            type: 'error',
            timeout: Infinity
          });
        });
      },

      cancelSubscription: function cancelSubscription(account) {
        var baseUrl = getOwner(this).lookup('adapter:application').buildURL();
        var flash = get(this, 'flashMessages');
        var token = get(this, 'session.data.authenticated.tok');
        var controller = getOwner(this).lookup('controller:billing.show');

        controller.promptForConfirmation({ value: 'CANCEL' }).onConfirmed(function (confirm) {
          if (confirm !== 'CANCEL') {
            return false;
          }

          fetch(baseUrl + '/accounts/' + account.id + '/actions/cancel-subscription', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/vnd.api+json',
              'Accept': 'application/vnd.api+json',
              'Authorization': 'Bearer ' + token
            }
          }).then(function (response) {
            return response.json();
          }).then(function (json) {
            var state = json.meta.status;

            flash.clearMessages();
            flash.add({
              message: 'Your subscription has been canceled.',
              type: 'error',
              timeout: Infinity
            });

            // Since subscription state is processed async, we're going to go ahead
            // update the state manually.
            account.get('billing').then(function (b) {
              return b.setProperties({ state: state });
            });
          })['catch'](function (e) {
            flash.clearMessages();
            flash.add({
              message: 'Failed to cancel your subscription.',
              type: 'error',
              timeout: Infinity
            });
          });
        });
      },

      updateCard: function updateCard(account) {
        var _this = this;

        var handler = StripeCheckout.configure({
          key: _keygenConfigEnvironment['default'].stripe.publishableKey,

          token: function token(_ref) {
            var stripeToken = _ref.id;
            var card = _ref.card;

            var baseUrl = getOwner(_this).lookup('adapter:application').buildURL();
            var token = get(_this, 'session.data.authenticated.tok');
            var flash = get(_this, 'flashMessages');

            fetch(baseUrl + '/accounts/' + account.id + '/billing', {
              method: "PATCH",
              headers: {
                "Content-Type": "application/vnd.api+json",
                "Accept": "application/vnd.api+json",
                "Authorization": 'Bearer ' + token
              },
              body: JSON.stringify({
                data: { type: "billing", attributes: { token: stripeToken } }
              })
            }).then(function (response) {
              if (response.status !== 202) {
                return RSVP.reject();
              }

              flash.clearMessages();
              flash.add({
                message: 'Your payment method has been updated!',
                type: 'success',
                timeout: Infinity,
                priority: 500
              });

              // Since billing info is processed async, we're going to set it
              // manually so that the new card is immediately shown.
              account.get('billing').then(function (billing) {
                billing.setProperties({
                  cardExpiry: new Date(card.exp_year, card.exp_month - 1 /* WTF JAVASCRIPT… ZERO INDEX MONTH??? */),
                  cardBrand: card.brand,
                  cardLast4: card.last4
                });
              });
            })['catch'](function (e) {
              flash.clearMessages();
              flash.add({
                message: 'Failed to update your payment method',
                type: 'error',
                timeout: Infinity
              });
            });
          }
        });

        handler.open({
          name: 'Keygen',
          image: '/icon.jpg',
          panelLabel: 'Update Card',
          zipCode: true
        });
      }
    }
  });
});