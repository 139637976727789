define('keygen/serializers/component', ['exports', 'keygen/serializers/base'], function (exports, _keygenSerializersBase) {
  exports['default'] = _keygenSerializersBase['default'].extend({
    attrs: {
      created: { serialize: false },
      updated: { serialize: false },
      fingerprint: { serialize: ['create', 'new'] },
      name: { serialize: ['create', 'new', 'update', 'edit'] },
      metadata: { serialize: ['create', 'new', 'update', 'edit'] },
      account: { serialize: false },
      product: { serialize: false },
      machine: { serialize: ['create', 'new'] },
      license: { serialize: false }
    }
  });
});