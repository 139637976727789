define('keygen/models/request-log', ['exports', 'ember-api-actions', 'keygen/models/base', 'ember-data'], function (exports, _emberApiActions, _keygenModelsBase, _emberData) {
  exports['default'] = _keygenModelsBase['default'].extend({
    type: 'request-logs',

    account: _emberData['default'].belongsTo('account'),
    requestor: _emberData['default'].belongsTo('requestor', { polymorphic: true }),

    url: _emberData['default'].attr('string'),
    method: _emberData['default'].attr('string'),
    status: _emberData['default'].attr('string'),
    userAgent: _emberData['default'].attr('string'),
    ip: _emberData['default'].attr('string'),
    requestHeaders: _emberData['default'].attr('string'),
    requestBody: _emberData['default'].attr('string'),
    responseHeaders: _emberData['default'].attr('string'),
    responseSignature: _emberData['default'].attr('string'),
    responseBody: _emberData['default'].attr('string'),

    count: (0, _emberApiActions.collectionAction)({
      path: 'actions/count',
      type: 'GET'
    })
  });
});