define('keygen/routes/groups/index', ['exports', 'keygen/routes/groups'], function (exports, _keygenRoutesGroups) {
  exports['default'] = _keygenRoutesGroups['default'].extend({
    queryParams: {
      page: { refreshModel: true },
      size: { refreshModel: true }
    },

    titleToken: function titleToken() {
      return 'Groups';
    },

    model: function model(params) {
      return this.store.query('group', {
        page: {
          number: params.page,
          size: params.size
        }
      });
    }
  });
});