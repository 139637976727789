define('keygen/routes/subscription/edit', ['exports', 'keygen/routes/subscription'], function (exports, _keygenRoutesSubscription) {
  exports['default'] = _keygenRoutesSubscription['default'].extend({
    titleToken: function titleToken() {
      return 'Subscription';
    },

    model: function model() /* params */{
      return this.transitionTo('billing');
    }
  });
});