define('keygen/components/kg-coupon-form', ['exports', 'keygen/config/environment', 'keygen/components/base', 'ember'], function (exports, _keygenConfigEnvironment, _keygenComponentsBase, _ember) {
  var service = _ember['default'].inject.service;
  var RSVP = _ember['default'].RSVP;
  var get = _ember['default'].get;
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _keygenComponentsBase['default'].extend({
    flashMessages: service('flashMessages'),
    session: service('session'),

    actions: {
      applyCoupon: function applyCoupon(account) {
        var baseUrl = getOwner(this).lookup('adapter:application').buildURL();
        var flash = get(this, 'flashMessages');
        var token = get(this, 'session.data.authenticated.tok');
        var coupon = get(account, 'billing.coupon');

        fetch(baseUrl + '/accounts/' + account.id + '/billing', {
          method: "PATCH",
          headers: {
            "Content-Type": "application/vnd.api+json",
            "Accept": "application/vnd.api+json",
            "Authorization": 'Bearer ' + token
          },
          body: JSON.stringify({
            data: { type: "billing", attributes: { coupon: coupon } }
          })
        }).then(function (response) {
          if (response.status !== 202) {
            return RSVP.reject();
          }

          flash.clearMessages();
          flash.add({
            message: 'The coupon \'' + coupon + '\' has been applied to your account!',
            type: 'success',
            timeout: Infinity,
            priority: 500
          });
        })['catch'](function (e) {
          flash.clearMessages();
          flash.add({
            message: 'Failed to apply coupon code to your account',
            type: 'error',
            timeout: Infinity
          });
        });
      }
    }
  });
});