define('keygen/transforms/json', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var isEmpty = _ember['default'].isEmpty;
  var typeOf = _ember['default'].typeOf;
  exports['default'] = _emberData['default'].Transform.extend({

    deserialize: function deserialize(obj) {
      if (isEmpty(obj)) {
        return {};
      }

      if (typeOf(obj) === "object" || typeOf(obj) === "array") {
        return obj;
      }

      return JSON.parse(obj);
    },

    serialize: function serialize(obj) {
      if (isEmpty(obj)) {
        return {};
      }

      if (typeOf(obj) === "object" || typeOf(obj) === "array") {
        return obj;
      }

      return JSON.parse(obj);
    }
  });
});