define('keygen/instance-initializers/browser/ember-href-to', ['exports', 'ember', 'ember-href-to/href-to'], function (exports, _ember, _emberHrefToHrefTo) {

  var hrefToClickHandler = undefined;
  function closestLink(el) {
    if (el.closest) {
      return el.closest('a');
    } else {
      el = el.parentElement;
      while (el && el.tagName !== 'A') {
        el = el.parentElement;
      }
      return el;
    }
  }
  exports['default'] = {
    name: 'ember-href-to',
    initialize: function initialize(applicationInstance) {
      if (hrefToClickHandler !== undefined) {
        document.body.removeEventListener('click', hrefToClickHandler);
      }
      hrefToClickHandler = function _hrefToClickHandler(e) {
        var link = e.target.tagName === 'A' ? e.target : closestLink(e.target);
        if (link) {
          var hrefTo = new _emberHrefToHrefTo['default'](applicationInstance, e, link);
          hrefTo.maybeHandle();
        }
      };
      document.body.addEventListener('click', hrefToClickHandler);
    }
  };
});