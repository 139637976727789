define('keygen/routes/machines', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'keygen/routes/base', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _keygenRoutesBase, _ember) {
  var RSVP = _ember['default'].RSVP;
  exports['default'] = _keygenRoutesBase['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    actions: {
      create: function create(machine) {
        var _this = this;

        machine.save()['catch'](function (err) {
          return _this.handleModelErrors(machine, err);
        }).then(function (m) {
          var flash = _this.get('flashMessages');
          if (flash != null) {
            flash.clearMessages();
          }

          _this.transitionTo('machines.show', m.id);
        });
      },
      update: function update(machine) {
        var _this2 = this;

        var reqs = [];

        if (machine.get('hasDirtyRelations')) {
          var changes = machine.modelChanges();

          for (var key in changes) {
            if (!changes[key]) {
              continue;
            }

            switch (key) {
              case 'group':
                {
                  var data = machine.get('group.id') ? { type: 'groups', id: machine.get('group.id') } : null;

                  reqs.push(machine.changeGroup({
                    data: data
                  })['catch'](function (e) {
                    if (e.errors.length) {
                      machine.get('errors').add('group', e.errors[0].detail);
                    }

                    throw e;
                  }));

                  break;
                }
              case 'owner':
                {
                  var data = machine.get('owner.id') ? { type: 'users', id: machine.get('owner.id') } : null;

                  reqs.push(machine.changeOwner({
                    data: data
                  })['catch'](function (e) {
                    if (e.errors.length) {
                      machine.get('errors').add('owner', e.errors[0].detail);
                    }

                    throw e;
                  }));

                  break;
                }
            }
          }
        }

        if (machine.get('hasDirtyAttributes')) {
          reqs.push(machine.save()['catch'](function (err) {
            return _this2.handleModelErrors(machine, err);
          }));
        }

        RSVP.all(reqs).then(function () {
          _this2.transitionTo('machines.show', machine.id);

          var flash = _this2.get('flashMessages');
          if (flash != null) {
            flash.clearMessages();
          }

          machine.get('errors').clear();
        });
      },
      'delete': function _delete(machine) {
        var _this3 = this;

        this.controller.promptForConfirmation().onConfirmed(function (confirm) {
          if (confirm !== 'DELETE') {
            return false;
          }

          machine.destroyRecord()['catch'](function (err) {
            return _this3.handleModelErrors(machine, err);
          }).then(function () {
            return _this3.transitionTo('machines.index');
          });
        });
      }
    }
  });
});