define('keygen/services/current-account', ['exports', 'ember'], function (exports, _ember) {

  var DEMO_ACCOUNT_ID = '1fddcec8-8dd3-4d8d-9b16-215cac0f9b52';
  var RSVP = _ember['default'].RSVP;
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var isEmpty = _ember['default'].isEmpty;
  exports['default'] = _ember['default'].Service.extend({
    session: service('session'),
    store: service('store'),

    account: null,

    isDemo: computed('account', 'account.id', function () {
      return this.get('account.id') === DEMO_ACCOUNT_ID;
    }),

    isEnt: computed('account', 'account.plan', 'account.plan.name', function () {
      var plan = this.get('account.plan');
      if (plan == null) {
        return false;
      }

      var name = plan.get('name');
      if (name) {
        return name.startsWith('Ent ');
      }

      return false;
    }),

    isLegacy: computed('account', function () {
      var created = this.get('account.created');
      if (created == null) {
        return false;
      }

      return created < new Date(1627066597734); // July 23, 2021
    }),

    load: function load() {
      var _this = this;

      var id = this.get('session.data.authenticated.aid');

      if (!isEmpty(id)) {
        return this.get('store').findRecord('account', id).then(function (account) {
          _this.set('account', account);

          return account;
        });
      } else {
        return RSVP.resolve();
      }
    }
  });
});