define('keygen/components/kg-current-bearer', ['exports', 'keygen/components/base', 'npm:copy-to-clipboard'], function (exports, _keygenComponentsBase, _npmCopyToClipboard) {
  exports['default'] = _keygenComponentsBase['default'].extend({
    actions: {
      copyToClipboard: function copyToClipboard(text, event) {
        var _this = this;

        setTimeout(function () {
          return _this.set('recentlyCopied', false);
        }, 2500);
        this.set('recentlyCopied', (0, _npmCopyToClipboard['default'])(text));
      }
    }
  });
});