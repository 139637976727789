define('keygen/components/kg-resource-list-item', ['exports', 'ember', 'keygen/components/base', 'lodash'], function (exports, _ember, _keygenComponentsBase, _lodash) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

  var set = _ember['default'].set;
  var typeOf = _ember['default'].typeOf;
  exports['default'] = _keygenComponentsBase['default'].extend({
    observers: [],
    filtered: ['metadata', 'password'],

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var observers = this.get('observers');
      var resource = this.get('resource');

      var _get = this.get('attrs');

      var attributes = _get.attributes;

      if ((0, _lodash.isEmpty)(attributes)) {
        return;
      }

      this.set('attributes', (0, _lodash.reduce)(attributes, function (attrs, key) {
        if (!(0, _lodash.isEmpty)(key) && !(0, _lodash.includes)(_this.get('filtered'), key)) {
          var value = resource.get(key);
          var type = typeOf(value);

          if (value == null) {
            resource.addObserver(key, _this, '_updateAsyncAttributes');
            observers.pushObject([resource, key, _this, '_updateAsyncAttributes']);

            value = "…";
          }

          if (value && type === 'date') {
            value = key + ' ' + moment.utc(value).fromNow();
          }

          // Truncate IDs when in list view
          if (value && key === 'id') {
            value = value.slice(0, 8);
          }

          attrs.push(Object.assign({}, { key: key, value: value, type: type }));
        }
        return attrs;
      }, []));
    },

    willDestroyElement: function willDestroyElement() {
      var observers = this.get('observers');

      (0, _lodash.each)(observers, function (_ref) {
        var _ref2 = _toArray(_ref);

        var resource = _ref2[0];

        var observer = _ref2.slice(1);

        resource.removeObserver.apply(resource, _toConsumableArray(observer));
      });
    },

    _updateAsyncAttributes: function _updateAsyncAttributes(sender, key, value, rev) {
      var attributes = this.get('attributes');

      this.set('attributes', attributes.map(function (attr) {
        if (attr.key === key && attr.type === "undefined") {
          var _value = sender.get(key);

          set(attr, 'value', _value);
          set(attr, 'type', typeOf(_value));
        }
        return attr;
      }));
    }
  });
});