define('keygen/components/kg-request-log-card', ['exports', 'keygen/components/base', 'ember', 'moment', 'lodash'], function (exports, _keygenComponentsBase, _ember, _moment, _lodash) {
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _keygenComponentsBase['default'].extend({
    resourceLimits: service('resource-limits'),
    session: service('session'),
    store: service('store'),

    init: function init() {
      this._super.apply(this, arguments);

      this._fetchData();
    },

    dailyRequestLimit: computed.alias('resourceLimits.dailyRequestLimit'),

    isLoading: computed.empty('data'),

    isError: computed.notEmpty('error'),

    isWarning: computed.notEmpty('warning'),

    warning: computed('usageForToday', 'dailyRequestLimit', function () {
      var _getProperties = this.getProperties('usageForToday', 'dailyRequestLimit');

      var usageForToday = _getProperties.usageForToday;
      var dailyRequestLimit = _getProperties.dailyRequestLimit;

      if (usageForToday == null || dailyRequestLimit == null) {
        return null;
      }

      if (usageForToday > dailyRequestLimit) {
        return 'You\'ve exceeded your API request limit for today';
      }
    }),

    todayUTC: computed(function () {
      return _moment['default'].utc().format('YYYY-MM-DD');
    }),

    usageForTodayPercentage: computed('usageForToday', 'dailyRequestLimit', function () {
      var _getProperties2 = this.getProperties('usageForToday', 'dailyRequestLimit');

      var usageForToday = _getProperties2.usageForToday;
      var dailyRequestLimit = _getProperties2.dailyRequestLimit;

      if (usageForToday == null || dailyRequestLimit == null) {
        return null;
      }

      return Math.max(0, Math.min(100, usageForToday / dailyRequestLimit * 100));
    }),

    usageForToday: computed('todayUTC', 'usage', function () {
      var today = this.get('todayUTC');
      var usage = this.get('usage');
      if (usage == null) {
        return null;
      }

      return usage[today];
    }),

    usageForPeriod: computed('usage', function () {
      var usage = this.get('usage');
      if (usage == null) {
        return null;
      }

      return (0, _lodash.sum)((0, _lodash.values)(usage)) || 0;
    }),

    _fetchData: function _fetchData() {
      var _this = this;

      var baseUrl = getOwner(this).lookup('adapter:application').buildURL();
      var accountId = this.get('session.data.authenticated.aid');
      var token = this.get('session.data.authenticated.tok');

      // FIXME(ezekg) Can't figure out how to use this action on the request log model
      fetch(baseUrl + '/accounts/' + accountId + '/request-logs/actions/count', {
        headers: {
          "Content-Type": "application/vnd.api+json",
          "Accept": "application/vnd.api+json",
          "Authorization": 'Bearer ' + token
        }
      }).then(function (res) {
        return res.json();
      }).then(function (body) {
        if (_this.isDestroyed) {
          return;
        }

        var usage = body.meta;

        var data = (0, _lodash.map)(usage, function (count, date) {
          return {
            x: (0, _moment['default'])(date),
            y: count || 0
          };
        });

        _this.setProperties({
          error: null,
          usage: usage,
          data: data
        });
      })['catch'](function (e) {
        if (_this.isDestroyed) {
          return;
        }

        _this.setProperties({
          error: e.message,
          usage: null,
          data: null
        });
      });
    }
  });
});