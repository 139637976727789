define('keygen/helpers/route-exists', ['exports', 'ember', 'ember-inflector'], function (exports, _ember, _emberInflector) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

  var assert = _ember['default'].assert;
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Helper.extend({
    router: _ember['default'].inject.service('-routing'),

    compute: function compute(params) {
      var _get = get(this, 'router');

      var router = _get.router;

      assert('[route-exists-helper] Unable to lookup router', router);

      var _params = _toArray(params);

      var resource = _params[0];

      var routes = _params.slice(1);

      // Add observer to recompute link if resource is not loaded
      resource.addObserver('data', this, 'recompute');

      var model = undefined;
      try {
        model = resource.modelName || resource.constructor.modelName || resource.type.modelName;
      } catch (e) {
        try {
          model = resource.content.constructor.modelName;
        } catch (e) {
          return false;
        }
      }

      return router.hasRoute([(0, _emberInflector.pluralize)(model)].concat(_toConsumableArray(routes)).join('.'));
    }
  });
});