define('keygen/routes/login', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'keygen/authenticators/token'], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin, _keygenAuthenticatorsToken) {
  var service = _ember['default'].inject.service;
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {
    currentAccount: service('current-account'),
    flashMessages: service('flashMessages'),
    session: service('session'),

    queryParams: {
      account: '',
      email: ''
    },

    title: function title() {
      return 'Log In';
    },

    model: function model(params) {
      return _ember['default'].Object.create({
        account: params.account,
        email: params.email,
        password: null,
        otp: null,

        isSecondFactorRequired: false
      });
    },

    actions: {
      login: function login(model) {
        var _this = this;

        var account = model.account;
        var email = model.email;
        var password = model.password;
        var otp = model.otp;

        // Accept domain names for a nice UX (some people accidentially enter their domain)
        if (account != null) {
          account = account.replace('.', '-');
        }

        this.get('session').authenticate('authenticator:token', account, email, password, otp).then(function () {
          return _this.get('currentAccount').load();
        }).then(function (account) {
          var flash = get(_this, 'flashMessages');
          var status = _this.get('status');

          flash.clearMessages();

          if (status === 'PAUSED' || status === 'CANCELED') {
            return _this.transitionTo('subscription');
          } else {
            return _this.transitionTo('index');
          }
        })['catch'](function (err) {
          var isSecondFactorRequired = err instanceof _keygenAuthenticatorsToken.SecondFactorRequiredError;
          var isSecondFactorInvalid = err instanceof _keygenAuthenticatorsToken.SecondFactorInvalidError;
          var flash = get(_this, 'flashMessages');

          flash.clearMessages();
          flash.add({
            message: 'Failed to login: ' + err.message,
            type: isSecondFactorRequired ? 'warn' : 'error',
            timeout: Infinity
          });

          switch (true) {
            case isSecondFactorRequired:
            case isSecondFactorInvalid:
              model.setProperties({ isSecondFactorRequired: true, otp: null });
              break;
            default:
              model.setProperties({ isSecondFactorRequired: false, otp: null });
          }
        });
      }
    }
  });
});