define('keygen/serializers/product', ['exports', 'keygen/serializers/base'], function (exports, _keygenSerializersBase) {
  exports['default'] = _keygenSerializersBase['default'].extend({
    attrs: {
      created: { serialize: false },
      updated: { serialize: false },
      permissions: { serialize: ['create', 'new', 'update', 'edit'] },
      account: { serialize: false },
      policies: { serialize: false },
      licenses: { serialize: false },
      machines: { serialize: false },
      users: { serialize: false },
      tokens: { serialize: false }
    },

    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);

      if (json.data != null && json.data.attributes != null) {
        // Permissions should not be serialized by default.
        if (json.data.attributes.permissions != null && json.data.attributes.permissions.length === 0) {
          delete json.data.attributes.permissions;
        }
      }

      return json;
    }
  });
});