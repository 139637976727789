define('keygen/helpers/current-route', ['exports', 'ember', 'lodash'], function (exports, _ember, _lodash) {
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Helper.extend({
    router: _ember['default'].inject.service('-routing'),

    onRouteChange: _ember['default'].observer('router.currentRouteName', function () {
      this.recompute();
    }),

    compute: function compute() {
      var _get = get(this, 'router.router');

      var currentRouteName = _get.currentRouteName;

      var parts = currentRouteName.split('.');

      return (0, _lodash.last)(parts);
    }
  });
});