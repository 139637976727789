define('keygen/serializers/account', ['exports', 'keygen/serializers/base', 'keygen/mixins/embedded-jsonapi-records'], function (exports, _keygenSerializersBase, _keygenMixinsEmbeddedJsonapiRecords) {
  exports['default'] = _keygenSerializersBase['default'].extend(_keygenMixinsEmbeddedJsonapiRecords['default'], {
    attrs: {
      created: { serialize: false },
      updated: { serialize: false },
      status: { serialize: false },
      apiVersion: { serialize: ['update', 'edit'] },
      meta: { serialize: false },
      admins: { serialize: { embedded: 'records', actions: ['register'] } },
      plan: { serialize: { embedded: 'records', actions: ['register'] } },
      billing: { serialize: false },
      webhookEndpoints: { serialize: false },
      webhookEvents: { serialize: false },
      products: { serialize: false },
      platforms: { serialize: false },
      arches: { serialize: false },
      channels: { serialize: false },
      releases: { serialize: false },
      artifacts: { serialize: false },
      policies: { serialize: false },
      users: { serialize: false },
      keys: { serialize: false },
      licenses: { serialize: false },
      machines: { serialize: false },
      processes: { serialize: false },
      tokens: { serialize: false }
    }
  });
});