define('keygen/models/artifact', ['exports', 'keygen/models/base', 'ember', 'ember-data'], function (exports, _keygenModelsBase, _ember, _emberData) {
  var computed = _ember['default'].computed;
  exports['default'] = _keygenModelsBase['default'].extend({
    type: 'artifacts',

    account: _emberData['default'].belongsTo('account'),
    release: _emberData['default'].belongsTo('release'),
    product: _emberData['default'].belongsTo('product'),

    filename: _emberData['default'].attr('string'),
    filetype: _emberData['default'].attr('string'),
    filesize: _emberData['default'].attr('number'),
    platform: _emberData['default'].attr('string'),
    arch: _emberData['default'].attr('string'),
    signature: _emberData['default'].attr('string'),
    checksum: _emberData['default'].attr('string'),
    status: _emberData['default'].attr('string'),
    metadata: _emberData['default'].attr('json'),

    isWaiting: computed.equal('status', 'WAITING'),
    isUploaded: computed.equal('status', 'UPLOADED'),
    isFailed: computed.equal('status', 'FAILED'),
    isYanked: computed.equal('status', 'YANKED'),

    isDownloadable: computed('release.isYanked', 'isUploaded', function () {
      return this.get('isUploaded') && !this.get('release.isYanked');
    }),

    meta: _emberData['default'].attr('object')
  });
});