define('keygen/components/kg-resource-form', ['exports', 'ember', 'keygen/components/base', 'ember-inflector', 'npm:uuid', 'lodash'], function (exports, _ember, _keygenComponentsBase, _emberInflector, _npmUuid, _lodash) {

  var SEMVER_RE = /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/;
  var RSVP = _ember['default'].RSVP;
  var service = _ember['default'].inject.service;
  var debounce = _ember['default'].run.debounce;
  var computed = _ember['default'].computed;
  var getOwner = _ember['default'].getOwner;
  var get = _ember['default'].get;
  var set = _ember['default'].set;

  var renameObjKey = function renameObjKey(_ref) {
    var oldObj = _ref.oldObj;
    var oldKey = _ref.oldKey;
    var newKey = _ref.newKey;
    return Object.keys(oldObj).reduce(function (acc, key) {
      if (key === oldKey) {
        acc[newKey] = oldObj[oldKey];
      } else {
        acc[key] = oldObj[key];
      }
      return acc;
    }, {});
  };

  var isNumeric = function isNumeric(x) {
    return !isNaN(x) && ('' + x)[('' + x).length - 1] !== '.';
  };

  var genHexString = function genHexString(len) {
    var alphabet = '3479ACEFHJKLMNPRTUVWXY';
    var output = '';

    for (var i = 0; i < len; ++i) {
      output += alphabet.charAt(Math.floor(Math.random() * alphabet.length));
    }

    return output;
  };

  exports['default'] = _keygenComponentsBase['default'].extend({
    session: service(),
    store: service(),

    relationships: {},
    attributes: {},

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var relationships = this.get('relationships');
      var store = this.get('store');

      if ((0, _lodash.isEmpty)(relationships)) {
        return;
      }

      // Initialize relationship data (search will handle the rest)
      (0, _lodash.each)(relationships, function (relationship, key) {
        var model = (0, _emberInflector.singularize)(relationship.type);
        var query = store.query(model, { limit: 100 });

        query.then(function (res) {
          return _this.set('relationships.' + key + '.options', res);
        });
      });
    },

    formAttributes: computed('resource', 'attributes', function () {
      var _getProperties = this.getProperties('resource', 'attributes');

      var resource = _getProperties.resource;
      var attributes = _getProperties.attributes;

      if ((0, _lodash.isEmpty)(attributes)) {
        return null;
      }

      var attrs = (0, _lodash.mapValues)(attributes, function (attr, key) {
        if (attr == null) {
          return null;
        }

        if (attr.type === 'select' && attr.multiselect) {
          var options = resource.get(key);

          if (!(0, _lodash.isEmpty)(options)) {
            set(attr, 'options', (0, _lodash.unionBy)(options, get(attr, 'options')));
          } else {
            set(attr, 'options', get(attr, 'options') || []);
          }
        }

        return attr;
      });

      return (0, _lodash.pickBy)(attrs, function (a) {
        return a != null;
      });
    }),

    isSubmitDisabled: computed('resource.isSaving', 'resource.isLoading', 'resource.hasDirtyAttributes', 'resource.hasDirtyRelations', function () {
      return this.get('resource.isSaving') || this.get('resource.isLoading') || !this.get('resource.hasDirtyAttributes') && !this.get('resource.hasDirtyRelations');
    }),

    _performSearch: function _performSearch(type, query, resolve, reject) {
      var baseUrl = getOwner(this).lookup('adapter:application').buildURL();
      var accountId = this.get('session.data.authenticated.aid');
      var token = this.get('session.data.authenticated.tok');
      var store = this.get('store');

      fetch(baseUrl + '/accounts/' + accountId + '/search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          'Accept': 'application/vnd.api+json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
          meta: {
            type: type,
            query: query
          }
        })
      }).then(function (res) {
        return res.json();
      }).then(function (_ref2) {
        var data = _ref2.data;
        var errors = _ref2.errors;

        if (errors) {
          console.error('[kg-resource-form.searchRelationship] Error during relationship search', errors[0].detail);

          return resolve([]);
        }

        try {
          store.pushPayload(type, { data: data });
        } catch (e) {
          console.error(e);
        }

        return resolve(data.map(function (_ref3) {
          var id = _ref3.id;
          return store.peekRecord(type, id);
        }));
      })['catch'](function (err) {
        console.error('[kg-resource-form.searchRelationship] Unexpected error during relationship search', err);

        return reject(err);
      });
    },

    actions: {
      attributeSelectChange: function attributeSelectChange(key, items) {
        var resource = this.get('resource');

        resource.set(key, items.map(function (item) {
          if ((0, _lodash.isObject)(item)) {
            return item.value;
          }

          return item;
        }));
      },
      attributeSelectOnEnter: function attributeSelectOnEnter(key, select, event) {
        if (event.keyCode === 13 /* enter key */) {
            event.preventDefault();

            if (select.isOpen && !select.highlighted && !_ember['default'].isBlank(select.searchText)) {
              var options = this.get('attributes.' + key + '.options');

              if (!options.includes(select.searchText)) {
                options.pushObject(select.searchText);

                select.actions.choose(select.searchText);
              }
            }
          }
      },
      attributeGroupSelectChange: function attributeGroupSelectChange(key, _ref4) {
        var value = _ref4.value;

        this.set('resource.' + key, value);
      },
      relationshipSelectChange: function relationshipSelectChange(rel, type, model) {
        var resource = this.get('resource');
        if (!model) {
          resource.set(rel, null);
          return;
        }

        var id = model.get('id');
        if (!id) {
          resource.set(rel, null);
          return;
        }

        var record = this.get('store').peekRecord((0, _emberInflector.singularize)(type || rel), id);
        if (record) {
          resource.set(rel, record);
        }
      },
      relationshipMultiSelectChange: function relationshipMultiSelectChange(rel, type, models) {
        var _this2 = this;

        var resource = this.get('resource');

        resource.set(rel, models.map(function (model) {
          var record = _this2.get('store').peekRecord((0, _emberInflector.singularize)(type || rel), model.get('id'));
          if (!record) {
            return null;
          }

          return record;
        }));
      },
      searchRelationship: function searchRelationship(rel, type, value) {
        var _this3 = this;

        var term = ('' + value).trim();
        var query = {};

        try {
          this.set('relationships.' + rel + '.term', term);
        } catch (e) {}

        if (term.length < 3) {
          return [];
        }

        if (_npmUuid['default'].validate(term)) {
          query = { id: term };
        } else {
          switch ((0, _emberInflector.singularize)(type)) {
            case 'release':
              if (term.match(SEMVER_RE)) {
                query = { version: term };
              } else {
                query = { tag: term };
              }

              break;
            case 'user':
              query = { email: term };

              break;
            default:
              query = { name: term };
          }
        }

        return new RSVP.Promise(function (resolve, reject) {
          debounce(_this3, _this3._performSearch, (0, _emberInflector.singularize)(type), query, resolve, reject, 600);
        });
      },
      attributeMetadataKeyChange: function attributeMetadataKeyChange(oldKey, event) {
        var resource = this.get('resource');
        var newKey = typeof event === 'object' ? event.target.value : event;

        // Rename the old key in-place to prevent form layout thrashing
        resource.set('metadata', renameObjKey({
          oldObj: resource.get('metadata'),
          oldKey: oldKey,
          newKey: newKey
        }));
      },
      attributeMetadataValueChange: function attributeMetadataValueChange(key, value) {
        var resource = this.get('resource');
        var original = value;

        /*if ((value.startsWith(`'`) && value.endsWith(`'`))) {
          value = value.replace(/^'|'$/g, '')
        } else if (value.startsWith(`"`) && value.endsWith(`"`)) {
          value = value.replace(/^"|"$/g, '')
        } else*/if (value && isNumeric(value) && value.indexOf(' ') === -1 && value.indexOf('+') === -1 && !(value.startsWith('0') && value.length > 1)) {
          // FIXME(ezekg) Add a better way to detect a float
          if (value.indexOf('.') !== -1) {
            // Consider this a string for now when the last character is a dot
            if (value[value.length - 1] !== '.') {
              value = parseFloat(value);
            }
          } else {
            value = parseInt(value);
            if (value.toString() !== original) {
              value = original; // int is too large
            }
          }
        } else if (value === 'true') {
            value = true;
          } else if (value === 'false') {
            value = false;
          }

        resource.set('metadata.' + key, value);
        resource.notifyPropertyChange('metadata');
      },
      attributeMetadataAdd: function attributeMetadataAdd() {
        var resource = this.get('resource');
        var metadata = resource.get('metadata');
        if (!metadata) {
          metadata = resource.set('metadata', {});
        }

        // Add a new key/value pair
        metadata[''] = '';

        resource.notifyPropertyChange('metadata');
      },
      attributeMetadataRemove: function attributeMetadataRemove(key) {
        var resource = this.get('resource');
        var metadata = resource.get('metadata');

        delete metadata[key];

        resource.notifyPropertyChange('metadata');
      },
      onKeygen: function onKeygen(key) {
        var resource = this.get('resource');
        var hex = genHexString(32).match(/.{1,4}/g).join('-');

        resource.set(key, hex);
      }
    }
  });
});