define('keygen/routes/recover', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var service = _ember['default'].inject.service;
  var get = _ember['default'].get;
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {
    flashMessages: service('flashMessages'),
    session: service('session'),

    title: function title() {
      return 'Recover';
    },

    actions: {
      recover: function recover(model) {
        var baseUrl = getOwner(this).lookup('adapter:application').buildURL();
        var flash = get(this, 'flashMessages');
        var type = model.type;
        var account = model.account;
        var email = model.email;

        switch (type) {
          case 'account':
            {
              if (email == null) {
                flash.clearMessages();
                flash.add({
                  message: 'Please fill out the account recovery form.',
                  type: 'error',
                  timeout: Infinity
                });

                return null;
              }

              fetch(baseUrl + '/recover', {
                method: "POST",
                headers: {
                  "Content-Type": "application/vnd.api+json",
                  "Accept": "application/vnd.api+json"
                },
                body: JSON.stringify({
                  meta: { type: type, email: email }
                })
              }).then(function (response) {
                flash.clearMessages();
                flash.add({
                  message: 'Account recovery was successfully sent to your email.',
                  type: 'success',
                  timeout: Infinity
                });
              })['catch'](function (e) {
                flash.clearMessages();
                flash.add({
                  message: 'Failed to request account recovery.',
                  type: 'error',
                  timeout: Infinity
                });
              })['finally'](function () {
                model.setProperties({
                  account: null,
                  email: null
                });
              });

              break;
            }
          case 'password':
          default:
            {
              if (account == null || email == null) {
                flash.clearMessages();
                flash.add({
                  message: 'Please fill out the password reset form.',
                  type: 'error',
                  timeout: Infinity
                });

                return null;
              }

              fetch(baseUrl + '/accounts/' + account + '/passwords', {
                method: "POST",
                headers: {
                  "Content-Type": "application/vnd.api+json",
                  "Accept": "application/vnd.api+json"
                },
                body: JSON.stringify({
                  meta: { email: email }
                })
              }).then(function (response) {
                flash.clearMessages();
                flash.add({
                  message: 'Password reset was successfully sent to your email.',
                  type: 'success',
                  timeout: Infinity
                });
              })['catch'](function (e) {
                flash.clearMessages();
                flash.add({
                  message: 'Failed to request password reset.',
                  type: 'error',
                  timeout: Infinity
                });
              })['finally'](function () {
                model.setProperties({
                  account: null,
                  email: null
                });
              });
            }
        }
      },

      fulfill: function fulfill(model) {
        var _this = this;

        var baseUrl = getOwner(this).lookup('adapter:application').buildURL();
        var flash = get(this, 'flashMessages');
        var passwordResetToken = model.token;
        var newPassword = model.password;

        if (passwordResetToken == null || newPassword == null) {
          flash.clearMessages();
          flash.add({
            message: 'Failed to complete password reset.',
            type: 'error',
            timeout: Infinity
          });

          return null;
        }

        var _passwordResetToken$split = passwordResetToken.split('.');

        var _passwordResetToken$split2 = _slicedToArray(_passwordResetToken$split, 3);

        var account = _passwordResetToken$split2[0];
        var user = _passwordResetToken$split2[1];
        var token = _passwordResetToken$split2[2];

        if (account == null || user == null) {
          flash.clearMessages();
          flash.add({
            message: 'Your password reset token is invalid.',
            type: 'error',
            timeout: Infinity
          });

          return null;
        }

        fetch(baseUrl + '/accounts/' + account + '/users/' + user + '/actions/reset-password', {
          method: "POST",
          headers: {
            "Content-Type": "application/vnd.api+json",
            "Accept": "application/vnd.api+json"
          },
          body: JSON.stringify({
            meta: { passwordResetToken: token, newPassword: newPassword }
          })
        }).then(function (response) {
          flash.clearMessages();

          if (response.status >= 200 && response.status < 300) {
            flash.add({
              message: 'Your password was successfully updated.',
              type: 'success',
              timeout: Infinity
            });

            return _this.transitionTo('recover.success');
          } else if (response.status === 403) {
            flash.add({
              message: 'Failed to complete password reset: user does not have permission to set a password',
              type: 'error',
              timeout: Infinity
            });
          } else {
            flash.add({
              message: 'Failed to complete password reset: token has already been used or it has expired',
              type: 'error',
              timeout: Infinity
            });
          }
        })['catch'](function (e) {
          flash.clearMessages();
          flash.add({
            message: 'Failed to complete password reset.',
            type: 'error',
            timeout: Infinity
          });
        })['finally'](function () {
          model.setProperties({
            password: null
          });
        });
      }
    }
  });
});