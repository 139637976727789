define('keygen/controllers/artifacts/show', ['exports', 'keygen/controllers/application', 'npm:download-in-browser', 'ember'], function (exports, _keygenControllersApplication, _npmDownloadInBrowser, _ember) {
  var service = _ember['default'].inject.service;
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _keygenControllersApplication['default'].extend({
    flashMessages: service('flashMessages'),
    session: service(),

    actions: {
      downloadArtifact: function downloadArtifact(artifact) {
        var baseUrl = getOwner(this).lookup('adapter:application').buildURL();
        var accountId = this.get('session.data.authenticated.aid');
        var artifactId = artifact.get('id');
        var token = this.get('session.data.authenticated.tok');
        var filename = artifact.get('filename');
        var flash = this.get('flashMessages');

        (0, _npmDownloadInBrowser['default'])(baseUrl + '/accounts/' + accountId + '/artifacts/' + artifactId + '?token=' + token, filename).then(function (_res) {
          flash.add({
            message: 'Download starting for ' + filename + '…',
            type: 'success',
            timeout: 5000,
            priority: 500
          });
        })['catch'](function (err) {
          flash.add({
            message: err.statusText + ': download failed to start for ' + filename,
            type: 'error',
            timeout: Infinity
          });
        });
      }
    }
  });
});