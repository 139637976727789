define('keygen/models/plan', ['exports', 'keygen/models/base', 'ember', 'ember-data', 'moment', 'ember-inflector'], function (exports, _keygenModelsBase, _ember, _emberData, _moment, _emberInflector) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  exports['default'] = _keygenModelsBase['default'].extend({
    type: 'plans',

    name: _emberData['default'].attr('string'),
    price: _emberData['default'].attr('number'),
    interval: _emberData['default'].attr('string'),
    maxReqs: _emberData['default'].attr('number'),
    maxAdmins: _emberData['default'].attr('number'),
    maxUsers: _emberData['default'].attr('number'),
    maxPolicies: _emberData['default'].attr('number'),
    maxLicenses: _emberData['default'].attr('number'),
    maxProducts: _emberData['default'].attr('number'),
    trialDuration: _emberData['default'].attr('number'),
    'private': _emberData['default'].attr('boolean', { defaultValue: false }),

    // Trial duration from now dasherized and humanized e.g. 172800 => 2-day
    trialLength: computed('trialDuration', function () {
      var duration = get(this, 'trialDuration');
      if (!duration) {
        return;
      }

      return (0, _emberInflector.singularize)(_moment['default'].utc().add(duration, 'seconds').fromNow( /* drop in/and verbiage */true).split(' ').join('-'));
    })
  });
});