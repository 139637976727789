define('keygen/serializers/license', ['exports', 'keygen/serializers/base', 'ember'], function (exports, _keygenSerializersBase, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _keygenSerializersBase['default'].extend({
    currentBearer: service('current-bearer'),

    attrs: {
      created: { serialize: false },
      updated: { serialize: false },
      encrypted: { serialize: false },
      suspended: { serialize: ['update', 'edit'] },
      key: { serialize: ['create', 'new'] },
      maxMachines: { serialize: false },
      maxProcesses: { serialize: false },
      maxUsers: { serialize: false },
      maxCores: { serialize: false },
      status: { serialize: false },
      cores: { serialize: false },
      maxUses: { serialize: false },
      uses: { serialize: false },
      scheme: { serialize: false },
      permissions: { serialize: ['create', 'new', 'update', 'edit'] },
      metadata: { serialize: ['create', 'new', 'update', 'edit'] },
      meta: { serialize: false },
      account: { serialize: false },
      product: { serialize: false },
      policy: { serialize: ['create', 'new'] },
      group: { serialize: ['create', 'new'] },
      machines: { serialize: false },
      users: { serialize: false },
      owner: { serialize: ['create', 'new'] },
      user: { serialize: false },
      tokens: { serialize: false },
      entitlements: { serialize: false }
    },

    serialize: function serialize(snapshot, options) {
      var bearer = this.get('currentBearer');
      var json = this._super.apply(this, arguments);

      if (json.data != null && json.data.attributes != null) {
        // These attributes get serialized for new records, and we don't want
        // that, since the API doesn't allow these params for non-admins.
        if (bearer != null && bearer.get('isSupportAgent')) {
          delete json.data.attributes['protected'];
          delete json.data.attributes.suspended;
          delete json.data.attributes.expiry;
        }

        // Permissions should not be serialized by default.
        if (json.data.attributes.permissions != null && json.data.attributes.permissions.length === 0) {
          delete json.data.attributes.permissions;
        }
      }

      return json;
    }
  });
});