define('keygen/routes/webhook-events', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'keygen/routes/base'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _keygenRoutesBase) {
  exports['default'] = _keygenRoutesBase['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    actions: {
      'delete': function _delete(event) {
        var _this = this;

        if (confirm("Are you sure? This cannot be undone.")) {
          event.destroyRecord()['catch'](function (err) {
            return _this.handleModelErrors(event, err);
          }).then(function () {
            return _this.transitionTo('webhook-events.index');
          });
        }
      }
    }
  });
});