define('keygen/router', ['exports', 'ember', 'keygen/config/environment'], function (exports, _ember, _keygenConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _keygenConfigEnvironment['default'].locationType,
    rootURL: _keygenConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('register');
    this.route('login');
    this.route('demo');

    this.route('recover', function () {
      this.route('password', { path: '/password' });
      this.route('account', { path: '/account' });
      this.route('success', { path: '/success' });
      this.route('edit', { path: '/:token' });
    });

    this.route('invite', function () {
      this.route('accept', { path: '/:token' });
    });

    this.route('products', function () {
      this.route('show', { path: '/:id' });
      this.route('edit', { path: '/:id/edit' });
      this.route('new');
    });

    this.route('policies', function () {
      this.route('show', { path: '/:id' });
      this.route('edit', { path: '/:id/edit' });
      this.route('new');
    });

    this.route('entitlements', function () {
      this.route('show', { path: '/:id' });
      this.route('edit', { path: '/:id/edit' });
      this.route('new');
    });

    this.route('groups', function () {
      this.route('show', { path: '/:id' });
      this.route('edit', { path: '/:id/edit' });
      this.route('new');
    });

    this.route('engines', function () {
      this.route('show', { path: '/:id' });
    });

    this.route('packages', function () {
      this.route('show', { path: '/:id' });
      this.route('edit', { path: '/:id/edit' });
      this.route('new');
    });

    this.route('releases', function () {
      this.route('show', { path: '/:id' });
      this.route('edit', { path: '/:id/edit' });
      this.route('new');
    });

    this.route('artifacts', function () {
      this.route('show', { path: '/:id' });
      this.route('edit', { path: '/:id/edit' });
      this.route('new');
    });

    this.route('platforms', function () {
      this.route('show', { path: '/:id' });
    });

    this.route('channels', function () {
      this.route('show', { path: '/:id' });
    });

    this.route('arches', function () {
      this.route('show', { path: '/:id' });
    });

    this.route('keys', function () {
      this.route('show', { path: '/:id' });
      this.route('edit', { path: '/:id/edit' });
      this.route('new');
    });

    this.route('users', function () {
      this.route('show', { path: '/:id' });
      this.route('edit', { path: '/:id/edit' });
      this.route('new');
    });

    this.route('licenses', function () {
      this.route('show', { path: '/:id' });
      this.route('edit', { path: '/:id/edit' });
      this.route('new');
    });

    this.route('machines', function () {
      this.route('show', { path: '/:id' });
      this.route('edit', { path: '/:id/edit' });
      this.route('new');
    });

    // FIXME(ezekg) components is a reserved directory
    this.route('components', function () {
      this.route('show', { path: '/:id' });
      this.route('edit', { path: '/:id/edit' });
      this.route('new');
    });

    this.route('processes', function () {
      this.route('show', { path: '/:id' });
      this.route('edit', { path: '/:id/edit' });
      this.route('new');
    });

    this.route('tokens', function () {
      this.route('show', { path: '/:id' });

      this.route('activation.new', { path: '/activation/new' });
      this.route('product.new', { path: '/product/new' });
    });

    this.route('webhook-endpoints', function () {
      this.route('show', { path: '/:id' });
      this.route('edit', { path: '/:id/edit' });
      this.route('new');
    });

    this.route('webhook-events', function () {
      this.route('show', { path: '/:id' });
    });

    this.route('request-logs', function () {
      this.route('show', { path: '/:id' });
    });

    this.route('event-logs', function () {
      this.route('show', { path: '/:id' });
    });

    this.route('settings', function () {
      this.route('password', { path: '/password' });
      this.route('edit', { path: '/edit' });
      this.route('show', { path: '/' });
    });

    this.route('second-factors', function () {
      this.route('edit', { path: '/:id/edit' });
      this.route('new');
    });

    this.route('billing', function () {
      this.route('show', { path: '/' });
    });

    this.route('subscription', function () {
      this.route('edit', { path: '/' });
    });

    this.route('integrations', function () {
      this.route('zapier', { path: '/' });
    });

    this.route('404', { path: '*404' });
  });

  exports['default'] = Router;
});