define('keygen/models/token', ['exports', 'keygen/models/user', 'keygen/models/license', 'keygen/models/product', 'keygen/mixins/has-top-level-meta', 'keygen/models/base', 'ember-data', 'ember-api-actions', 'ember'], function (exports, _keygenModelsUser, _keygenModelsLicense, _keygenModelsProduct, _keygenMixinsHasTopLevelMeta, _keygenModelsBase, _emberData, _emberApiActions, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _keygenModelsBase['default'].extend(_keygenMixinsHasTopLevelMeta['default'], {
    type: 'tokens',

    account: _emberData['default'].belongsTo('account'),
    bearer: _emberData['default'].belongsTo('bearer', { polymorphic: true }),
    token: _emberData['default'].attr('string'),
    kind: _emberData['default'].attr('string'),
    expiry: _emberData['default'].attr('date'),
    permissions: _emberData['default'].attr('array'),
    maxActivations: _emberData['default'].attr('number'),
    activations: _emberData['default'].attr('number'),
    maxDeactivations: _emberData['default'].attr('number'),
    deactivations: _emberData['default'].attr('number'),
    name: _emberData['default'].attr('string'),

    availablePermissions: computed('permissions', 'bearer', function () {
      var perms = this.get('permissions');
      if (perms != null && perms.includes('*')) {
        return ['*'];
      }

      var subtype = this.get('kind');
      switch (subtype) {
        case 'admin-token':
          return _keygenModelsUser.ADMIN_PERMISSIONS;
        case 'user-token':
          return _keygenModelsUser.USER_PERMISSIONS;
        case 'product-token':
          return _keygenModelsProduct.PRODUCT_PERMISSIONS;
        case 'activation-token':
        case 'license-token':
          return _keygenModelsLicense.LICENSE_PERMISSIONS;
      }
    }),

    // Helpers
    isAdminToken: computed.equal('kind', 'admin-token'),
    isUserToken: computed.equal('kind', 'user-token'),
    isActivationToken: computed.equal('kind', 'activation-token'),
    isLicenseToken: computed.equal('kind', 'license-token'),
    isProductToken: computed.equal('kind', 'product-token'),

    // Actions
    regenerate: (0, _emberApiActions.memberAction)({ type: 'PUT', path: '/' })
  });
});