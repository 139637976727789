define('keygen/models/process', ['exports', 'keygen/models/base', 'ember-data'], function (exports, _keygenModelsBase, _emberData) {
  exports['default'] = _keygenModelsBase['default'].extend({
    type: 'processes',

    account: _emberData['default'].belongsTo('account'),
    product: _emberData['default'].belongsTo('product'),
    license: _emberData['default'].belongsTo('license'),
    machine: _emberData['default'].belongsTo('machine'),

    pid: _emberData['default'].attr('string'),
    status: _emberData['default'].attr('string'),
    lastHeartbeat: _emberData['default'].attr('date'),
    nextHeartbeat: _emberData['default'].attr('date'),
    interval: _emberData['default'].attr('number'),
    metadata: _emberData['default'].attr('json')
  });
});