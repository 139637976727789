define('keygen/mixins/account-namespace', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Mixin.create({
    session: service('session'),

    // FIXME(ezekg) This is so freaking hacky… but Ember is… eh.
    namespace: (function () {
      var id = this.get('session.data.authenticated.aid');

      return 'v1/accounts/' + id;
    }).property().volatile()
  });
});