define('keygen/routes/recover/password', ['exports', 'keygen/routes/recover', 'ember'], function (exports, _keygenRoutesRecover, _ember) {
  exports['default'] = _keygenRoutesRecover['default'].extend({
    queryParams: {
      account: '',
      email: ''
    },

    model: function model(params) {
      return _ember['default'].Object.create({
        type: 'password',
        account: params.account,
        email: params.email
      });
    }
  });
});