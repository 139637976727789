define('keygen/routes/users/new', ['exports', 'keygen/routes/users'], function (exports, _keygenRoutesUsers) {
  exports['default'] = _keygenRoutesUsers['default'].extend({
    titleToken: function titleToken() {
      return 'New user';
    },
    model: function model() {
      return this.store.createRecord('user', {
        role: 'user'
      });
    }
  });
});