define('keygen/components/kg-resource-actions', ['exports', 'ember', 'keygen/components/base', 'lodash'], function (exports, _ember, _keygenComponentsBase, _lodash) {
  var service = _ember['default'].inject.service;
  var typeOf = _ember['default'].typeOf;
  var get = _ember['default'].get;
  exports['default'] = _keygenComponentsBase['default'].extend({
    flashMessages: service('flashMessages'),
    router: service('-routing'),
    store: service('store'),
    session: service('session'),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var resource = this.get('resource');
      var attrs = this.get('attrs');

      delete attrs.resource;

      if ((0, _lodash.isEmpty)(attrs)) {
        return;
      }

      this.set('acts', (0, _lodash.reduce)(attrs, function (actions, act, key) {
        if (act == null) {
          return actions;
        }

        if (typeOf(act.action) === 'function') {
          var action = act.action;

          actions.push(Object.assign({}, act, { action: action }));
        } else if (typeOf(resource.get(key)) === 'function' && !(0, _lodash.includes)(_this.get('filtered'), key)) {
          var action = function action() {
            var currentTokenId = get(_this, 'session.data.authenticated.tid');
            if (resource.id === currentTokenId) {
              if (confirm("This token is being used for the current session. Regenerating it will log you out. Are you sure?")) {
                window.location.reload();
              } else {
                return;
              }
            } else {
              if (act.confirm != null && !confirm(act.confirm)) {
                return; // Return early if confirmation fails
              }
            }

            resource[key](act.data).then(function (body) {
              switch (resource.constructor.modelName) {
                case 'license':
                  {
                    var flash = get(_this, 'flashMessages');
                    var prettyActionNames = {
                      checkIn: 'checked in',
                      suspend: 'suspended',
                      reinstate: 'reinstated',
                      revoke: 'revoked',
                      renew: 'renewed',
                      incrementUsage: 'incremented',
                      decrementUsage: 'decremented',
                      resetUsage: 'reset'
                    };

                    flash.clearMessages();
                    flash.add({
                      message: 'License has successfully been ' + prettyActionNames[key],
                      type: 'success',
                      timeout: 5000
                    });

                    if (key === 'revoke') {
                      _this.get('router').transitionTo('licenses.index');
                    } else {
                      resource.reload();
                    }

                    break;
                  }
                case 'user':
                  {
                    var flash = get(_this, 'flashMessages');
                    var prettyActionNames = {
                      ban: 'banned',
                      unban: 'unbanned'
                    };

                    flash.clearMessages();

                    if (key === 'resetPassword') {
                      flash.add({
                        message: 'Password reset email was successfully sent to the user',
                        type: 'success',
                        timeout: 5000
                      });
                    } else {
                      flash.add({
                        message: 'User has successfully been ' + prettyActionNames[key],
                        type: 'success',
                        timeout: 5000
                      });
                    }

                    resource.reload();

                    break;
                  }
                case 'release':
                  {
                    var flash = get(_this, 'flashMessages');
                    var prettyActionNames = {
                      publish: 'published',
                      yank: 'yanked'
                    };

                    flash.clearMessages();
                    flash.add({
                      message: 'Release has successfully been ' + prettyActionNames[key],
                      type: 'success',
                      timeout: 5000
                    });

                    resource.reload();

                    break;
                  }
              }

              if (!body) {
                return;
              }
              var _body$data = body.data;
              var type = _body$data.type;
              var id = _body$data.id;

              // Special case for webhook events retry action
              if (type === 'webhook-events' && key === 'retry') {
                var flash = get(_this, 'flashMessages');

                flash.clearMessages();
                flash.add({
                  message: 'Webhook event has successfully been queued to retry',
                  type: 'success',
                  timeout: 5000
                });

                _this.get('router').transitionTo(type + '.show', [id]);
              }

              if (type === 'tokens' && key === 'regenerate') {
                var flash = get(_this, 'flashMessages');
                var store = get(_this, 'store');
                var data = body.data;

                flash.clearMessages();
                flash.add({
                  message: 'Token has successfully been regenerated',
                  type: 'success',
                  timeout: 5000
                });

                // Push updated token to store
                store.push(store.normalize('token', data));
              }
            });
          };

          actions.push(Object.assign({}, act, { action: action }));
        }

        return actions;
      }, []));
    }
  });
});