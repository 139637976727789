define('keygen/serializers/machine', ['exports', 'keygen/serializers/base'], function (exports, _keygenSerializersBase) {
  exports['default'] = _keygenSerializersBase['default'].extend({
    attrs: {
      created: { serialize: false },
      updated: { serialize: false },
      maxProcesses: { serialize: false },
      requireHeartbeat: { serialize: false },
      heartbeatStatus: { serialize: false },
      heartbeatDuration: { serialize: false },
      lastHeartbeat: { serialize: false },
      nextHeartbeat: { serialize: false },
      metadata: { serialize: ['create', 'new', 'update', 'edit'] },
      account: { serialize: false },
      product: { serialize: false },
      group: { serialize: ['create', 'new'] },
      license: { serialize: ['create', 'new'] },
      owner: { serialize: ['create', 'new'] },
      user: { serialize: false }
    }
  });
});