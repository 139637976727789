define("keygen/components/kg-spinner", ["exports", "keygen/components/base", "npm:gaussian", "npm:random-number", "npm:slik", "lodash"], function (exports, _keygenComponentsBase, _npmGaussian, _npmRandomNumber, _npmSlik, _lodash) {
  exports["default"] = _keygenComponentsBase["default"].extend({

    init: function init() {
      this._super.apply(this, arguments);

      this.duration = 500;
      this.easing = _npmSlik["default"].Easing.EaseInOutSine;
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.canvas = document.querySelector(".spinner__canvas");
      this.ctx = this.canvas.getContext("2d");

      // Set up initial state
      this.prev = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      (0, _lodash.fill)(this.prev, (this.canvas.height - 10) / 2);

      var isPointTooDifferent = function isPointTooDifferent(p, i) {
        if (_this.prev.every(function (p) {
          return p === 0;
        })) {
          return false;
        }
        return Math.abs(p - _this.prev[i]) > _this.canvas.width / 2;
      };

      this.interval = setInterval(function () {
        var attempts = 0;
        var next = undefined;

        // Attempt to generate a shape that is 'like' the prev shape
        while (next == null || next.any(isPointTooDifferent)) {
          if (attempts++ > 250) {
            return; // Too many attempts to get a 'good' shape to transition to
          }

          next = _this._generateRandomPoints((_this.canvas.width - 10) / 2, (_this.canvas.height - 10) / 2, (_this.canvas.width - 10) / 2, 0.5, 0.001, 5).map(function (point) {
            return point + 5;
          });
        }

        _this._animate(_this.prev, next);
        _this.prev = next;
      }, this.duration * 2);
    },

    willDestroyElement: function willDestroyElement() {
      clearInterval(this.interval);
    },

    _clear: function _clear() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    },

    _animate: function _animate(prev) {
      var next = arguments.length <= 1 || arguments[1] === undefined ? prev : arguments[1];
      return (function () {
        var _this2 = this;

        var animation = new _npmSlik["default"].Animation({
          duration: this.duration,
          ease: this.easing,
          from: prev,
          to: next
        });

        animation.on("update", function (points) {
          try {
            _this2._clear();
            _this2._draw(points.toArray());
          } catch (e) {}
        });

        animation.play();
      }).apply(this, arguments);
    },

    _draw: function _draw(points) {
      this.ctx.beginPath();
      this.ctx.moveTo(points[0], points[1]);

      for (var i = 2; i < points.length - 1; i += 2) {
        this.ctx.lineTo(points[i], points[i + 1]);
      }

      this.ctx.closePath();

      this.ctx.strokeStyle = "#f7f8fb";
      this.ctx.lineWidth = this.canvas.width / 16 + 1;

      this.ctx.stroke();
    },

    /**
     * @link http://stackoverflow.com/a/25276331/3247081
     *
     * @param {Int} ctrX, ctrY - coordinates of the "centre" of the polygon
     * @param {Int} aveRadius - in px, the average radius of this polygon, this roughly controls how large the polygon is, really only useful for order of magnitude.
     * @param {Int} irregularity - [0,1] indicating how much variance there is in the angular spacing of vertices. [0,1] will map to [0, 2pi/numberOfVerts]
     * @param {Int} spikeyness - [0,1] indicating how much variance there is in each vertex from the circle of radius aveRadius. [0,1] will map to [0, aveRadius]
     * @param {Int} numVerts - self-explanatory
     *
     * @return {Arr} Array of vertices, in CCW order.
     */
    _generateRandomPoints: function _generateRandomPoints(ctrX, ctrY, aveRadius, irregularity, spikeyness, numVerts) {
      irregularity = this._clip(irregularity, 0, 1) * 2 * Math.PI / numVerts;
      spikeyness = this._clip(spikeyness, 0, 1) * aveRadius;

      // Generate n angle steps
      var angleSteps = [];
      var lower = 2 * Math.PI / numVerts - irregularity;
      var upper = 2 * Math.PI / numVerts + irregularity;
      var sum = 0;

      for (var i = 0; i < numVerts; i++) {
        var tmp = (0, _npmRandomNumber["default"])({ min: lower, max: upper });

        angleSteps.push(tmp);

        sum += tmp;
      }

      // Normalize the steps so that point 0 and point n+1 are the same
      var k = sum / (2 * Math.PI);

      for (var i = 0; i < numVerts; i++) {
        angleSteps[i] = angleSteps[i] / k;
      }

      // Generate the points
      var points = [];
      var angle = (0, _npmRandomNumber["default"])({ min: 0, max: 2 * Math.PI });

      for (var i = 0; i < numVerts; i++) {
        var gauss = (0, _npmGaussian["default"])(aveRadius, spikeyness).ppf(Math.random());
        var rad = this._clip(gauss, 0, 2 * aveRadius);
        var x = ctrX + rad * Math.cos(angle);
        var y = ctrY + rad * Math.sin(angle);

        points.push(+x, +y);

        angle += angleSteps[i];
      }

      return points;
    },

    _clip: function _clip(x, min, max) {
      if (min > max) {
        return x;
      } else if (x < min) {
        return min;
      } else if (x > max) {
        return max;
      } else {
        return x;
      }
    }
  });
});