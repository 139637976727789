define('keygen/models/group', ['exports', 'keygen/models/base', 'ember-data'], function (exports, _keygenModelsBase, _emberData) {
  exports['default'] = _keygenModelsBase['default'].extend({
    type: 'groups',

    account: _emberData['default'].belongsTo('account'),
    users: _emberData['default'].hasMany('user'),
    licenses: _emberData['default'].hasMany('license'),
    machines: _emberData['default'].hasMany('machine'),
    name: _emberData['default'].attr('string'),
    maxUsers: _emberData['default'].attr('number'),
    maxLicenses: _emberData['default'].attr('number'),
    maxMachines: _emberData['default'].attr('number'),
    metadata: _emberData['default'].attr('json')
  });
});