define('keygen/components/kg-second-factor/edit', ['exports', 'keygen/components/base', 'ember'], function (exports, _keygenComponentsBase, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _keygenComponentsBase['default'].extend({
    tagName: '',

    authParams: {
      otp: null
    },

    isSecondFactorPresent: computed.notEmpty('secondFactor'),
    isSecondFactorEnabled: computed.bool('secondFactor.enabled'),
    isSecondFactorDisabled: computed.not('isSecondFactorEnabled')
  });
});