define('keygen/serializers/base', ['exports', 'ember', 'ember-data', 'keygen/mixins/serialize-top-level-meta', 'keygen/mixins/serialize-for-action', 'ember-data-change-tracker/mixins/keep-only-changed'], function (exports, _ember, _emberData, _keygenMixinsSerializeTopLevelMeta, _keygenMixinsSerializeForAction, _emberDataChangeTrackerMixinsKeepOnlyChanged) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var camelize = _ember['default'].String.camelize;
  exports['default'] = _emberData['default'].JSONAPISerializer.extend(_keygenMixinsSerializeForAction['default'], _keygenMixinsSerializeTopLevelMeta['default'], _emberDataChangeTrackerMixinsKeepOnlyChanged['default'], {
    keyForRelationship: function keyForRelationship(key) {
      return camelize(key);
    },
    keyForAttribute: function keyForAttribute(key) {
      return camelize(key);
    },
    keyForLink: function keyForLink(key) {
      return camelize(key);
    },

    normalizeQueryResponse: function normalizeQueryResponse(store, model, payload) {
      var result = this._super.apply(this, arguments);
      result.meta = result.meta || {};

      if (payload.links) {
        result.meta.pagination = this._storePaginationMeta(payload.links);
      }

      return result;
    },

    normalize: function normalize(model, payload) {
      var result = this._super.apply(this, arguments);

      // Append resource meta to the model's attributes (because Ember Data is dumb)
      try {
        var meta = payload.meta || {};

        // Append top-level and resource links to meta object
        meta.links = Object.assign({}, payload.links, payload.data != null ? payload.data.links : {});

        // Append relationship meta as well
        var rels = result.data.relationships || {};

        for (var rel in rels) {
          try {
            var linkage = result.data.relationships[rel];
            if (linkage.meta) {
              meta[rel] = linkage.meta;
            }
          } catch (e) {
            console.error(e);
          }
        }

        result.data.attributes.meta = meta;
      } catch (e) {
        console.error(e);
      }

      return result;
    },

    _storePaginationMeta: function _storePaginationMeta(links) {
      var meta = {};

      Object.keys(links).forEach(function (type) {
        var link = links[type];
        if (typeof link !== 'string') {
          meta[type] = link;

          return;
        }

        var a = document.createElement('a');
        a.href = link;

        meta[type] = {
          link: link
        };

        a.search.slice(1).split('&').forEach(function (pairs) {
          var _pairs$split = pairs.split('=');

          var _pairs$split2 = _slicedToArray(_pairs$split, 2);

          var param = _pairs$split2[0];
          var value = _pairs$split2[1];

          if (param === 'page%5Bnumber%5D') {
            meta[type].number = parseInt(value);
          }
          if (param === 'page%5Bsize%5D') {
            meta[type].size = parseInt(value);
          }
        });

        a = null;
      });

      return meta;
    }
  });
});