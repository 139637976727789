define('keygen/routes/machines/index', ['exports', 'keygen/routes/machines'], function (exports, _keygenRoutesMachines) {
  exports['default'] = _keygenRoutesMachines['default'].extend({
    queryParams: {
      page: { refreshModel: true },
      size: { refreshModel: true },
      product: { refreshModel: true },
      group: { refreshModel: true },
      license: { refreshModel: true },
      key: { refreshModel: true },
      user: { refreshModel: true }
    },

    titleToken: function titleToken() {
      return 'Machines';
    },

    model: function model(params) {
      var number = params.page;
      var size = params.size;
      var product = params.product;
      var group = params.group;
      var license = params.license;
      var key = params.key;
      var user = params.user;

      return this.store.query('machine', {
        page: { number: number, size: size },
        product: product,
        group: group,
        license: license,
        key: key,
        user: user
      });
    }
  });
});