define('keygen/components/kg-public-keys', ['exports', 'keygen/components/base', 'npm:copy-to-clipboard', 'npm:buffer', 'ember'], function (exports, _keygenComponentsBase, _npmCopyToClipboard, _npmBuffer, _ember) {
  var Buffer = _npmBuffer['default'].Buffer;
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;

  // Encode hexadecimal key into DER format (Node doesn't accept hex format)
  var convertHexToDer = function convertHexToDer(hex) {
    var oid = Buffer.from([0x06, 0x03, 0x2B, 0x65, 0x70]);
    var key = Buffer.from(hex, 'hex');
    var elements = Buffer.concat([Buffer.concat([Buffer.from([0x30]), // Sequence tag
    Buffer.from([oid.length]), oid]), Buffer.concat([Buffer.from([0x03]), // Bit tag
    Buffer.from([key.length + 1]), Buffer.from([0x00]), // Zero bit
    key])]);

    var der = Buffer.concat([Buffer.from([0x30]), // Sequence tag
    Buffer.from([elements.length]), elements]);

    return der;
  };

  exports['default'] = _keygenComponentsBase['default'].extend({
    currentAccount: service('current-account'),

    ed25519Base64PublicKey: computed('currentAccount.account.meta.keys.ed25519', function () {
      return this.get('currentAccount.account.meta.keys.ed25519');
    }),

    ed25519HexPublicKey: computed('ed25519Base64PublicKey', function () {
      return atob(this.get('ed25519Base64PublicKey'));
    }),

    ed25519DerPublicKey: computed('ed25519HexPublicKey', function () {
      return convertHexToDer(this.get('ed25519HexPublicKey')).toString('base64');
    }),

    rsaBase64PublicKey: computed('currentAccount.account.meta.keys.rsa2048', function () {
      return this.get('currentAccount.account.meta.keys.rsa2048');
    }),

    rsaPemPublicKey: computed('rsaBase64PublicKey', function () {
      return atob(this.get('rsaBase64PublicKey'));
    }),

    actions: {
      copyToClipboard: function copyToClipboard(text, event) {
        var _this = this;

        setTimeout(function () {
          return _this.set('recentlyCopied', false);
        }, 2500);
        this.set('recentlyCopied', (0, _npmCopyToClipboard['default'])(text));
      }
    }
  });
});