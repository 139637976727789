define('keygen/models/package', ['exports', 'keygen/mixins/changed-relationships', 'keygen/models/base', 'ember-data'], function (exports, _keygenMixinsChangedRelationships, _keygenModelsBase, _emberData) {
  exports['default'] = _keygenModelsBase['default'].extend(_keygenMixinsChangedRelationships['default'], {
    type: 'packages',

    account: _emberData['default'].belongsTo('account'),
    product: _emberData['default'].belongsTo('product'),

    name: _emberData['default'].attr('string'),
    key: _emberData['default'].attr('string'),
    engine: _emberData['default'].attr('string'),

    metadata: _emberData['default'].attr('json'),

    created: _emberData['default'].attr('date'),
    updated: _emberData['default'].attr('date')
  });
});