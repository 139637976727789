define('keygen/components/kg-password-form', ['exports', 'keygen/components/base', 'npm:zxcvbn'], function (exports, _keygenComponentsBase, _npmZxcvbn) {
  exports['default'] = _keygenComponentsBase['default'].extend({
    tagName: '',

    didInsertElement: function didInsertElement() {
      var _this = this;

      var inputs = document.querySelectorAll("input[type='password']");

      if (inputs) {
        Array.prototype.forEach.call(inputs, function (i) {
          return _this._listen(i);
        });
      }
    },

    _listen: function _listen(element) {
      var hint = element.nextSibling;
      if (hint.tagName == null) {
        // Handle text nodes
        hint = hint.nextSibling;
      }

      if (!hint || hint.tagName !== "SPAN" || hint.className !== "hint") {
        return;
      }

      element.onkeyup = function () {
        if (!element.value) {
          hint.dataset.score = null;
          hint.innerHTML = 'Please enter a value';
          return;
        }

        var result = (0, _npmZxcvbn['default'])(element.value);
        hint.dataset.score = result.score;
        hint.innerHTML = result.feedback && result.feedback.warning;
      };

      element.onkeyup();
    }
  });
});