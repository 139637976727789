define('keygen/helpers/current-resource', ['exports', 'ember', 'lodash'], function (exports, _ember, _lodash) {
  exports['default'] = _ember['default'].Helper.extend({
    ignored: ['settings', 'subscription', 'billing', 'login', 'register', 'recover'],

    onPathChanged: _ember['default'].observer('target.url', function () {
      this.recompute();
    }),

    compute: function compute() {
      var parts = window.location.href.split('/');
      var resource = (0, _lodash.last)(parts);

      if (this.get('ignored').includes(resource)) {
        return null;
      }

      return resource;
    }
  });
});