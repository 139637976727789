define('keygen/routes/licenses', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'keygen/routes/base', 'ember', 'lodash'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _keygenRoutesBase, _ember, _lodash) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var RSVP = _ember['default'].RSVP;
  exports['default'] = _keygenRoutesBase['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    actions: {
      create: function create(license) {
        var _this = this;

        license.save()['catch'](function (err) {
          return _this.handleModelErrors(license, err);
        }).then(function (l) {
          var flash = _this.get('flashMessages');
          if (flash != null) {
            flash.clearMessages();
          }

          _this.transitionTo('licenses.show', l.id);
        }).then(function () {
          var _license$changedRelationships = license.changedRelationships();

          var _license$changedRelationships$entitlements = _license$changedRelationships.entitlements;
          var entitlements = _license$changedRelationships$entitlements === undefined ? [] : _license$changedRelationships$entitlements;
          var _license$changedRelationships$users = _license$changedRelationships.users;
          var users = _license$changedRelationships$users === undefined ? [] : _license$changedRelationships$users;

          if (entitlements.length > 0) {
            var _entitlements = _slicedToArray(entitlements, 2);

            var /* prev */entitlementsIds = _entitlements[1];

            license.attachEntitlements({ data: entitlementsIds.map(function (id) {
                return { type: 'entitlements', id: id };
              }) })['catch'](function (e) {
              if (e.errors.length) {
                license.get('errors').add('entitlements', e.errors[0].detail);
              }

              throw e;
            });

            license.get('entitlements').reload(); // so changes always show up
          }

          if (users.length > 0) {
            var _users = _slicedToArray(users, 2);

            var /* prev */userIds = _users[1];

            license.attachUsers({ data: userIds.map(function (id) {
                return { type: 'users', id: id };
              }) })['catch'](function (e) {
              if (e.errors.length) {
                license.get('errors').add('users', e.errors[0].detail);
              }

              throw e;
            });

            license.get('users').reload(); // so changes always show up
          }
        });
      },
      update: function update(license) {
        var _this2 = this;

        var reqs = [];

        if (license.get('hasDirtyRelations')) {
          var changes = license.modelChanges();

          for (var key in changes) {
            if (!changes[key]) {
              continue;
            }

            switch (key) {
              case 'policy':
                {
                  reqs.push(license.changePolicy({
                    data: {
                      type: 'policies',
                      id: license.get('policy.id')
                    }
                  })['catch'](function (e) {
                    if (e.errors.length) {
                      license.get('errors').add('policy', e.errors[0].detail);
                    }

                    throw e;
                  }));

                  break;
                }
              case 'group':
                {
                  var data = license.get('group.id') ? { type: 'groups', id: license.get('group.id') } : null;

                  reqs.push(license.changeGroup({
                    data: data
                  })['catch'](function (e) {
                    if (e.errors.length) {
                      license.get('errors').add('group', e.errors[0].detail);
                    }

                    throw e;
                  }));

                  break;
                }
              case 'owner':
                {
                  var data = license.get('owner.id') ? { type: 'users', id: license.get('owner.id') } : null;

                  reqs.push(license.changeOwner({
                    data: data
                  })['catch'](function (e) {
                    if (e.errors.length) {
                      license.get('errors').add('owner', e.errors[0].detail);
                    }

                    throw e;
                  }));

                  break;
                }
              case 'entitlements':
                {
                  var _license$changedRelationships2 = license.changedRelationships();

                  var entitlements = _license$changedRelationships2.entitlements;

                  if (entitlements == null) {
                    break;
                  }

                  var _entitlements2 = _slicedToArray(entitlements, 2);

                  var prevEntitlementsIds = _entitlements2[0];
                  var nextEntitlementsIds = _entitlements2[1];

                  var removedIds = (0, _lodash.difference)(prevEntitlementsIds, nextEntitlementsIds);
                  var addedIds = (0, _lodash.difference)(nextEntitlementsIds, prevEntitlementsIds);
                  var onError = function onError(e) {
                    if (e.errors.length) {
                      // FIXME(ezekg) Reload so that the failed entitlement changes are rolled back
                      license.get('entitlements').reload().then(function () {
                        var _e$errors = _slicedToArray(e.errors, 1);

                        var error = _e$errors[0];

                        license.get('errors').add('entitlements', error.detail);
                      });
                    }

                    throw e;
                  };

                  var ops = [];

                  if (removedIds.length) {
                    ops.push(license.detachEntitlements({ data: removedIds.map(function (id) {
                        return { type: 'entitlements', id: id };
                      }) })['catch'](onError));
                  }

                  if (addedIds.length) {
                    ops.push(license.attachEntitlements({ data: addedIds.map(function (id) {
                        return { type: 'entitlements', id: id };
                      }) })['catch'](onError));
                  }

                  reqs.push(Promise.all(ops).then(function () {
                    return license.get('entitlements').reload();
                  }));

                  break;
                }
              case 'users':
                {
                  var _license$changedRelationships3 = license.changedRelationships();

                  var users = _license$changedRelationships3.users;

                  if (users == null) {
                    break;
                  }

                  var _users2 = _slicedToArray(users, 2);

                  var prevUserIds = _users2[0];
                  var nextUserIds = _users2[1];

                  var removedIds = (0, _lodash.difference)(prevUserIds, nextUserIds);
                  var addedIds = (0, _lodash.difference)(nextUserIds, prevUserIds);
                  var onError = function onError(e) {
                    if (e.errors.length) {
                      // FIXME(ezekg) Reload so that the failed user changes are rolled back
                      license.get('users').reload().then(function () {
                        var _e$errors2 = _slicedToArray(e.errors, 1);

                        var error = _e$errors2[0];

                        license.get('errors').add('users', error.detail);
                      });
                    }

                    throw e;
                  };

                  var ops = [];

                  if (removedIds.length) {
                    ops.push(license.detachUsers({ data: removedIds.map(function (id) {
                        return { type: 'users', id: id };
                      }) })['catch'](onError));
                  }

                  if (addedIds.length) {
                    ops.push(license.attachUsers({ data: addedIds.map(function (id) {
                        return { type: 'users', id: id };
                      }) })['catch'](onError));
                  }

                  reqs.push(Promise.all(ops).then(function () {
                    return license.get('users').reload();
                  }));

                  break;
                }
            }
          }
        }

        if (license.get('hasDirtyAttributes')) {
          reqs.push(license.save()['catch'](function (err) {
            return _this2.handleModelErrors(license, err);
          }));
        }

        RSVP.all(reqs).then(function () {
          _this2.transitionTo('licenses.show', license.id);

          var flash = _this2.get('flashMessages');
          if (flash != null) {
            flash.clearMessages();
          }

          license.get('errors').clear();
        });
      },
      'delete': function _delete(license) {
        var _this3 = this;

        this.controller.promptForConfirmation().onConfirmed(function (confirm) {
          if (confirm !== 'DELETE') {
            return false;
          }

          license.destroyRecord()['catch'](function (err) {
            return _this3.handleModelErrors(license, err);
          }).then(function () {
            return _this3.transitionTo('licenses.index');
          });
        });
      }
    }
  });
});