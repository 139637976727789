define('keygen/mixins/embedded-jsonapi-records', ['exports', 'ember', 'ember-data', 'lodash'], function (exports, _ember, _emberData, _lodash) {
  exports['default'] = _ember['default'].Mixin.create(_emberData['default'].EmbeddedRecordsMixin, {

    /**
     * @override embedded-records-mixin
     */
    hasEmbeddedAlwaysOption: function hasEmbeddedAlwaysOption(attr) {
      var option = this.attrsOption(attr);

      return option && (option.embedded === 'always' || option.serialize && option.serialize.embedded === 'always');
    },

    hasSerializeRecordsOption: function hasSerializeRecordsOption(attr) {
      var alwaysEmbed = this.hasEmbeddedAlwaysOption(attr);
      var option = this.attrsOption(attr);

      return alwaysEmbed || option && (option.serialize === 'records' || option.serialize && option.serialize.embedded === 'records');
    },

    hasSerializeIdsOption: function hasSerializeIdsOption(attr) {
      var option = this.attrsOption(attr);

      return option && (option.serialize === 'ids' || option.serialize === 'id' || option.serialize && (option.serialize.embedded === 'ids' || option.serialize.embedded === 'id'));
    },

    hasSerializeIdsAndTypesOption: function hasSerializeIdsAndTypesOption(attr) {
      var option = this.attrsOption(attr);

      return option && (option.serialize === 'ids-and-types' || option.serialize === 'id-and-type' || option.serialize && (option.serialize.embedded === 'id-and-types' || option.serialize.embedded === 'id-and-type'));
    },

    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      var attr = relationship.key;

      if (this._canSerialize(attr)) {
        if (this.noSerializeOptionSpecified(attr) || !this.hasSerializeRecordsOption(attr)) {
          this._super(snapshot, json, relationship);
          return;
        }

        this._serializeEmbeddedRecord(attr, snapshot, json, relationship, '_serializeEmbeddedBelongsTo');
      }
    },

    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      var attr = relationship.key;

      if (this._canSerialize(attr)) {
        if (this.noSerializeOptionSpecified(attr) || !this.hasSerializeRecordsOption(attr)) {
          this._super(snapshot, json, relationship);
          return;
        }

        this._serializeEmbeddedRecord(attr, snapshot, json, relationship, '_serializeEmbeddedHasMany');
      }
    },

    _serializeEmbeddedRecord: function _serializeEmbeddedRecord(attr, snapshot, json, relationship, serializer) {
      if (!json.relationships) {
        json.relationships = {};
      }

      var serializedKey = this.keyForRelationship(attr, relationship.kind, 'serialize');
      this[serializer](snapshot, json, relationship);

      switch (serializer) {
        case '_serializeEmbeddedBelongsTo':
          json.relationships[serializedKey] = json[serializedKey];
          break;
        case '_serializeEmbeddedHasMany':
          var data = [];
          (0, _lodash.mapValues)(json[serializedKey], function (rel) {
            return data.push(rel.data);
          });

          json.relationships[serializedKey] = { data: data };
          break;
      }

      delete json[serializedKey];
    }
  });
});