define('keygen/routes/releases', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'keygen/routes/base', 'ember', 'lodash'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _keygenRoutesBase, _ember, _lodash) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var RSVP = _ember['default'].RSVP;
  exports['default'] = _keygenRoutesBase['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    actions: {
      create: function create(release) {
        var _this = this;

        release.save()['catch'](function (err) {
          return _this.handleModelErrors(release, err);
        }).then(function (r) {
          var flash = _this.get('flashMessages');
          if (flash != null) {
            flash.clearMessages();
          }

          _this.transitionTo('releases.show', r.id);
        }).then(function () {
          var _release$changedRelationships = release.changedRelationships();

          var entitlements = _release$changedRelationships.entitlements;

          if (entitlements == null) {
            return;
          }

          var _entitlements = _slicedToArray(entitlements, 2);

          var /* prev */entitlementsIds = _entitlements[1];

          var data = entitlementsIds.map(function (id) {
            return {
              type: 'constraints',
              relationships: {
                entitlement: {
                  data: { type: 'entitlements', id: id }
                }
              }
            };
          });

          release.attachEntitlements({ data: data })['catch'](function (e) {
            if (e.errors.length) {
              release.get('errors').add('entitlements', e.errors[0].detail);
            }

            throw e;
          });
        });
      },
      update: function update(release) {
        var _this2 = this;

        var reqs = [];

        if (release.get('hasDirtyRelations')) {
          var changes = release.modelChanges();

          for (var key in changes) {
            if (!changes[key]) {
              continue;
            }

            switch (key) {
              case 'entitlements':
                {
                  var _ret = (function () {
                    var _release$changedRelationships2 = release.changedRelationships();

                    var entitlements = _release$changedRelationships2.entitlements;

                    if (entitlements == null) {
                      return 'break';
                    }

                    var _entitlements2 = _slicedToArray(entitlements, 2);

                    var prevEntitlementsIds = _entitlements2[0];
                    var nextEntitlementsIds = _entitlements2[1];

                    var removedIds = (0, _lodash.difference)(prevEntitlementsIds, nextEntitlementsIds);
                    var addedIds = (0, _lodash.difference)(nextEntitlementsIds, prevEntitlementsIds);
                    var onError = function onError(e) {
                      if (e.errors.length) {
                        // FIXME(ezekg) Reload so that the failed entitlement changes are rolled back
                        release.get('entitlements').reload().then(function () {
                          var _e$errors = _slicedToArray(e.errors, 1);

                          var error = _e$errors[0];

                          release.get('errors').add('entitlements', error.detail);
                        });
                      }

                      throw e;
                    };

                    var ops = [];

                    if (removedIds.length) {
                      ops.push(release.get('constraints').then(function (constraints) {
                        release.detachEntitlements({
                          data: removedIds.map(function (id) {
                            return {
                              type: 'constraints',
                              id: constraints.find(function (c) {
                                return c.belongsTo('entitlement').id() === id;
                              }).get('id')
                            };
                          })
                        });
                      })['catch'](onError));
                    }

                    if (addedIds.length) {
                      ops.push(release.attachEntitlements({
                        data: addedIds.map(function (id) {
                          return {
                            type: 'constraints',
                            relationships: {
                              entitlement: {
                                data: { type: 'entitlements', id: id }
                              }
                            }
                          };
                        })
                      })['catch'](onError));
                    }

                    reqs.push(Promise.all(ops).then(function () {
                      return release.get('entitlements').reload();
                    }));

                    return 'break';
                  })();

                  if (_ret === 'break') break;
                }
              case 'package':
                {
                  var data = release.get('package.id') ? { type: 'packages', id: release.get('package.id') } : null;

                  reqs.push(release.changePackage({
                    data: data
                  })['catch'](function (e) {
                    if (e.errors.length) {
                      release.get('errors').add('package', e.errors[0].detail);
                    }

                    throw e;
                  }));

                  break;
                }
            }
          }
        }

        if (release.get('hasDirtyAttributes')) {
          reqs.push(release.save()['catch'](function (err) {
            return _this2.handleModelErrors(release, err);
          }));
        }

        RSVP.all(reqs).then(function () {
          var flash = _this2.get('flashMessages');
          if (flash != null) {
            flash.clearMessages();
          }

          _this2.transitionTo('releases.show', release.id);

          release.get('entitlements').reload();
          release.get('constraints').reload();
          release.get('errors').clear();
        });
      },
      'delete': function _delete(release) {
        var _this3 = this;

        this.controller.promptForConfirmation().onConfirmed(function (confirm) {
          if (confirm !== 'DELETE') {
            return false;
          }

          release.destroyRecord()['catch'](function (err) {
            return _this3.handleModelErrors(release, err);
          }).then(function () {
            return _this3.transitionTo('releases.index');
          });
        });
      }
    }
  });
});