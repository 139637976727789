define('keygen/components/kg-search', ['exports', 'npm:relaxed-json', 'keygen/components/base', 'ember-inflector', 'ember', 'lodash'], function (exports, _npmRelaxedJson, _keygenComponentsBase, _emberInflector, _ember, _lodash) {
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  var getOwner = _ember['default'].getOwner;
  var typeOf = _ember['default'].typeOf;

  var NEWLINE_SENTINEL_CHAR = String.fromCharCode(32);
  var INDEX_NOT_SELECTED = -1;

  var clean = function clean(s) {
    return s.replace(/^['"]+|['"]+$/g, '').trim();
  };

  exports['default'] = _keygenComponentsBase['default'].extend({
    session: service('session'),
    router: service('-routing'),

    init: function init() {
      this._super.apply(this, arguments);

      // Controller so that we can abort network requests
      this.controller = new AbortController();

      // Search field text
      this.text = null;

      // Which search result is currently selected
      this.selectedResultIndex = INDEX_NOT_SELECTED;
    },

    focusOnChange: observer('open', 'text', function () {
      var open = this.get('open');
      var node = this.$('input')[0];
      if (open) {
        if (this.blurHandler == null) {
          this.blurHandler = function () {
            return node.focus();
          };
        }

        // We want to make sure that the search field is always focused
        node.addEventListener('blur', this.blurHandler);
        node.focus();
      } else {
        node.removeEventListener('blur', this.blurHandler);
      }
    }),

    clearOnClose: observer('open', function () {
      var open = this.get('open');
      if (open) {
        return;
      }

      this.setProperties({
        selectedResultIndex: INDEX_NOT_SELECTED,
        results: null,
        errors: null
      });
    }),

    tokens: computed('text', function () {
      var text = this.get('text');
      if (typeof text !== 'string') {
        return [];
      }

      var chars = text.split('');
      var tokens = [];
      var state = {
        dquo: false, // Inside double quotes
        squo: false, // Inside single quotes
        cur: false, // Inside curly brackets
        par: false, // Inside of parenthesis
        key: false, // Key is present
        val: false, // Value is present
        tok: '' // Cleaned token
      };

      // This is a rather dumb parser (i.e. it doesn't match nested parenthesis or quotes),
      // but it does its job of tokenizing the search text in a forgiving way.
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = chars[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var char = _step.value;

          switch (char) {
            case NEWLINE_SENTINEL_CHAR:
            case ' ':
              {
                if (state.par || state.dquo || state.squo || state.cur || !state.key || !state.val) {
                  state.tok += char;

                  break;
                }

                tokens.push(state.tok);

                // Reset state
                state.dquo = false;
                state.squo = false;
                state.cur = false;
                state.par = false;
                state.key = false;
                state.val = false;
                state.tok = '';

                break;
              }
            case ':':
              {
                if (!state.key) {
                  state.key = true;
                }

                state.tok += char;

                break;
              }
            case '{':
              {
                state.tok += char;
                state.cur = true;

                break;
              }
            case '}':
              {
                state.tok += char;
                state.cur = false;

                break;
              }
            case '(':
              {
                state.par = true;

                break;
              }
            case ')':
              {
                state.par = false;

                break;
              }
            case "'":
              {
                if (state.cur) {
                  state.tok += char;

                  break;
                }

                if (state.squo) {
                  state.squo = false;
                } else {
                  state.squo = true;
                }

                break;
              }
            case '"':
              {
                if (state.cur) {
                  state.tok += char;

                  break;
                }

                if (state.dquo) {
                  state.dquo = false;
                } else {
                  state.dquo = true;
                }

                break;
              }
            default:
              {
                if (state.key) {
                  state.val = true;
                }

                state.tok += char;
              }
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator['return']) {
            _iterator['return']();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return tokens;
    }),

    searchOnTokenChange: observer('tokens', function () {
      var _this = this;

      this.setProperties({
        selectedResultIndex: INDEX_NOT_SELECTED,
        results: null,
        errors: null
      });

      var baseUrl = getOwner(this).lookup('adapter:application').buildURL();
      var account = this.get('session.data.authenticated.aid');
      var token = this.get('session.data.authenticated.tok');

      var _get = this.get('search');

      var type = _get.type;
      var query = _get.query;

      if ((0, _lodash.isEmpty)(type) || (0, _lodash.isEmpty)(query)) {
        return;
      }

      // Abort any previous requests that still may be pending
      var prevController = this.get('controller');
      prevController.abort();

      var controller = new AbortController();
      var signal = controller.signal;

      this.setProperties({
        isSearchInProgress: true,
        controller: controller
      });

      fetch(baseUrl + '/accounts/' + account + '/search', {
        signal: signal, // This is so that we can cancel this request
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          'Accept': 'application/vnd.api+json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({
          meta: {
            type: type,
            query: query
          }
        })
      }).then(function (res) {
        if (signal.aborted) {
          return;
        }

        return res.json();
      }).then(function (body) {
        if (signal.aborted) {
          return;
        }

        var data = body.data;
        var errors = body.errors;

        if (errors) {
          throw errors;
        }

        _this.setProperties({
          isSearchInProgress: false,
          results: data,
          errors: null
        });
      })['catch'](function (errs) {
        if (signal.aborted) {
          return;
        }

        _this.setProperties({
          isSearchInProgress: false,
          results: null,
          errors: errs
        });
      });
    }),

    search: computed('tokens', function () {
      var _this2 = this;

      var tokens = this.get('tokens');
      var query = tokens.reduce(function (obj, token) {
        var idx = token.indexOf(':');
        var key = token.substring(0, idx);
        var val = token.substring(idx + 1);
        if (!val) {
          return obj;
        }

        if (key === 'metadata') {
          try {
            obj[clean(key)] = _npmRelaxedJson['default'].parse(val, { warnings: true });
          } catch (e) {
            _this2.setProperties({
              errors: [{
                title: 'Invalid metadata query syntax',
                detail: e.message.toLowerCase() + ' (try surrounding the term in quotes)'
              }]
            });

            return obj;
          }
        } else {
          obj[clean(key)] = clean(val);
        }

        return obj;
      }, {});

      // Pop off the type so that we use it separately
      var type = query.type;

      delete query.type;

      return {
        type: type,
        query: query
      };
    }),

    type: computed('search', function () {
      var _get2 = this.get('search');

      var type = _get2.type;

      return (0, _emberInflector.singularize)(type);
    }),

    query: computed('search', function () {
      var _get3 = this.get('search');

      var query = _get3.query;

      return (0, _lodash.mapValues)(query, function (value) {
        switch (typeOf(value)) {
          case 'object':
            {
              return '{ ' + (0, _lodash.reduce)(value, function (a, v, k) {
                return a.push(k + ':' + v), a;
              }, []).join(',') + ' }';
            }
          case 'array':
            {
              return value.join(',');
            }
          default:
            {
              return value;
            }
        }
      });
    }),

    keys: computed('search', function () {
      var _get4 = this.get('search');

      var query = _get4.query;

      return (0, _lodash.keys)(query);
    }),

    actions: {
      handleSearchFieldChange: function handleSearchFieldChange(event, value) {
        var key = event.key;

        if (key == null) {
          key = String.fromCharCode(event.keyCode);
        }

        switch (key) {
          case 'Enter':
            {
              var index = this.get('selectedResultIndex');
              if (index !== INDEX_NOT_SELECTED) {
                // When selected idx isn't -1, we should navigate to selected item
                this.actions.handleTransitionToResultByIndex.bind(this)(index);

                return;
              }

              if (value && value.slice(-1) !== NEWLINE_SENTINEL_CHAR) {
                value += NEWLINE_SENTINEL_CHAR;
              }

              // Force change notification (this way pressing the enter key always submits)
              this.notifyPropertyChange('text');

              break;
            }
          case 'ArrowUp':
            {
              var _getProperties = this.getProperties('selectedResultIndex', 'results');

              var index = _getProperties.selectedResultIndex;
              var results = _getProperties.results;

              if (!results || !results.length) {
                break;
              }

              if (index < 0) {
                index = results.length - 1;
              } else {
                index--;
              }

              this.set('selectedResultIndex', index);

              break;
            }
          case 'ArrowDown':
            {
              var _getProperties2 = this.getProperties('selectedResultIndex', 'results');

              var index = _getProperties2.selectedResultIndex;
              var results = _getProperties2.results;

              if (!results || !results.length) {
                break;
              }

              if (index >= results.length - 1) {
                index = 0;
              } else {
                index++;
              }

              this.set('selectedResultIndex', index);

              break;
            }
          case 'Escape':
            {
              this.sendAction('handleClose');

              break;
            }
        }

        this.set('text', value);
      },

      handleSearchFieldClick: function handleSearchFieldClick() {
        this.set('selectedResultIndex', INDEX_NOT_SELECTED);
      },

      handleSearchFieldClear: function handleSearchFieldClear() {
        this.setProperties({
          selectedResultIndex: INDEX_NOT_SELECTED,
          results: null,
          errors: null,
          text: null
        });
      },

      handleTransitionToResultByIndex: function handleTransitionToResultByIndex(index) {
        var resource = this.get('results.' + index);
        var action = this.get('handleOpenToggle');
        var router = this.get('router');

        // Navigate to the selected resource
        try {
          router.transitionTo(resource.type + '.show', [resource.id]);

          // Close the search component if we've specified a handler
          if (typeof action === 'function') {
            action();
          }
        } catch (e) {
          this.setProperties({
            errors: [{ title: 'Error', detail: 'failed to transition to resource' }]
          });
        }
      }
    }
  });
});