define('keygen/components/kg-request-log-chart', ['exports', 'ember', 'keygen/components/base', 'd3-shape', 'd3-array', 'd3-scale', 'd3-selection', 'd3-axis', 'd3-time-format'], function (exports, _ember, _keygenComponentsBase, _d3Shape, _d3Array, _d3Scale, _d3Selection, _d3Axis, _d3TimeFormat) {
  var run = _ember['default'].run;
  exports['default'] = _keygenComponentsBase['default'].extend({
    tagName: 'svg',
    attributeBindings: ['viewBox', 'width', 'height'],
    viewBox: '0 0 0 0',
    width: 0,
    height: 0,

    didReceiveAttrs: function didReceiveAttrs() {
      run.scheduleOnce('render', this, this._draw);
      this._calculateAttrs();
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      var renderer = function renderer() {
        run.scheduleOnce('render', _this, _this._draw);
        _this._calculateAttrs();
      };

      window.addEventListener('resize', renderer);
    },

    _calculateAttrs: function _calculateAttrs() {
      var container = this.element && this.element.parentNode;
      if (!container) {
        return;
      }

      var rect = container.getBoundingClientRect();
      var width = Math.max(0, Math.floor(rect.width));
      var height = Math.max(0, Math.floor(rect.height));

      this.setProperties({
        viewBox: '0 0 ' + width + ' ' + height,
        width: width,
        height: height
      });
    },

    _draw: function _draw() {
      var plot = (0, _d3Selection.select)(this.element);
      var data = this.get('data');
      var width = this.get('width');
      var height = this.get('height');
      if (!data) {
        return;
      }

      var x = (0, _d3Scale.scaleLinear)().domain((0, _d3Array.extent)(data.map(function (d) {
        return d.x;
      }))).range([-5, width + 5]);

      var y = (0, _d3Scale.scaleLinear)().domain((0, _d3Array.extent)(data.map(function (d) {
        return d.y;
      }))).range([height - 27, 20]);

      var xAxis = (0, _d3Axis.axisTop)(x).tickFormat((0, _d3TimeFormat.timeFormat)("%x")).tickValues(data.map(function (d) {
        return d.x;
      }));

      var yAxis = (0, _d3Axis.axisLeft)(y);

      var valueLine = (0, _d3Shape.line)().x(function (d) {
        return x(d.x);
      }).y(function (d) {
        return y(d.y);
      }).curve(_d3Shape.curveMonotoneX);

      // Clear before redrawing
      plot.selectAll("svg > *").remove();

      plot.append("path").data([data]).attr("vector-effect", "non-scaling-stroke").attr("class", "line").attr("d", valueLine);

      plot.append("g").attr("transform", 'translate(0, ' + height + ')').attr("class", "x-axis").call(xAxis);

      plot.append("g").attr("transform", 'translate(' + width + ', 0)').attr("class", "y-axis").call(yAxis);
    }
  });
});