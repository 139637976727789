define('keygen/routes/settings', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'keygen/routes/base', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _keygenRoutesBase, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _keygenRoutesBase['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    session: service('session'),

    model: function model() /* params */{
      var id = this.get('session.data.authenticated.aid');
      return this.store.findRecord('account', id);
    },

    actions: {
      update: function update(account) {
        var _this = this;

        account.save().then(function () {
          return _this.transitionTo('settings.show');
        });
      }
    }
  });
});