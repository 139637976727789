define('keygen/routes/billing', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'keygen/routes/base', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _keygenRoutesBase, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _keygenRoutesBase['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    currentAccount: service('current-account'),
    currentBearer: service('current-bearer'),
    session: service('session'),

    beforeModel: function beforeModel() {
      if (!this.get('currentBearer.isAdmin')) {
        return this.transitionTo('index');
      }
    },

    model: function model() /* params */{
      var id = this.get('session.data.authenticated.aid');
      return this.store.findRecord('account', id);
    }
  });
});