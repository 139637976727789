define('keygen/routes/arches/index', ['exports', 'keygen/routes/arches'], function (exports, _keygenRoutesArches) {
  exports['default'] = _keygenRoutesArches['default'].extend({
    queryParams: {
      page: { refreshModel: true },
      size: { refreshModel: true }
    },

    titleToken: function titleToken() {
      return 'Architectures';
    },

    model: function model(params) {
      var number = params.page;
      var size = params.size;

      return this.store.query('arch', {
        page: { number: number, size: size }
      });
    }
  });
});