define('keygen/models/policy', ['exports', 'keygen/mixins/changed-relationships', 'keygen/models/base', 'ember-api-actions', 'ember-data'], function (exports, _keygenMixinsChangedRelationships, _keygenModelsBase, _emberApiActions, _emberData) {
  exports['default'] = _keygenModelsBase['default'].extend(_keygenMixinsChangedRelationships['default'], {
    type: 'policies',

    account: _emberData['default'].belongsTo('account'),
    product: _emberData['default'].belongsTo('product'),
    licenses: _emberData['default'].hasMany('license'),
    entitlements: _emberData['default'].hasMany('entitlement'),

    pool: _emberData['default'].hasMany('key'),
    name: _emberData['default'].attr('string'),
    duration: _emberData['default'].attr('number'),
    strict: _emberData['default'].attr('boolean'),
    floating: _emberData['default'].attr('boolean'),
    requireProductScope: _emberData['default'].attr('boolean'),
    requirePolicyScope: _emberData['default'].attr('boolean'),
    requireMachineScope: _emberData['default'].attr('boolean'),
    requireFingerprintScope: _emberData['default'].attr('boolean'),
    requireUserScope: _emberData['default'].attr('boolean'),
    requireChecksumScope: _emberData['default'].attr('boolean'),
    requireVersionScope: _emberData['default'].attr('boolean'),
    requireCheckIn: _emberData['default'].attr('boolean'),
    checkInInterval: _emberData['default'].attr('string'),
    checkInIntervalCount: _emberData['default'].attr('number'),
    usePool: _emberData['default'].attr('boolean'),
    maxMachines: _emberData['default'].attr('number'),
    maxProcesses: _emberData['default'].attr('number'),
    maxUsers: _emberData['default'].attr('number'),
    maxCores: _emberData['default'].attr('number'),
    maxUses: _emberData['default'].attr('number'),
    encrypted: _emberData['default'].attr('boolean'),
    scheme: _emberData['default'].attr('string'),
    machineUniquenessStrategy: _emberData['default'].attr('string'),
    machineMatchingStrategy: _emberData['default'].attr('string'),
    componentUniquenessStrategy: _emberData['default'].attr('string'),
    componentMatchingStrategy: _emberData['default'].attr('string'),
    expirationStrategy: _emberData['default'].attr('string'),
    expirationBasis: _emberData['default'].attr('string'),
    renewalBasis: _emberData['default'].attr('string'),
    transferStrategy: _emberData['default'].attr('string'),
    authenticationStrategy: _emberData['default'].attr('string'),
    machineLeasingStrategy: _emberData['default'].attr('string'),
    processLeasingStrategy: _emberData['default'].attr('string'),
    overageStrategy: _emberData['default'].attr('string'),
    heartbeatCullStrategy: _emberData['default'].attr('string'),
    heartbeatResurrectionStrategy: _emberData['default'].attr('string'),
    heartbeatBasis: _emberData['default'].attr('string'),
    heartbeatDuration: _emberData['default'].attr('number'),
    requireHeartbeat: _emberData['default'].attr('boolean'),
    'protected': _emberData['default'].attr('boolean'),
    metadata: _emberData['default'].attr('json'),

    // Relationships
    attachEntitlements: (0, _emberApiActions.memberAction)({ type: 'POST', path: 'entitlements' }),
    detachEntitlements: (0, _emberApiActions.memberAction)({ type: 'DELETE', path: 'entitlements' })
  });
});