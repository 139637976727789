define('keygen/serializers/process', ['exports', 'keygen/serializers/base'], function (exports, _keygenSerializersBase) {
  exports['default'] = _keygenSerializersBase['default'].extend({
    attrs: {
      created: { serialize: false },
      updated: { serialize: false },
      pid: { serialize: ['create', 'new'] },
      interval: { serialize: false },
      status: { serialize: false },
      lastHeartbeat: { serialize: false },
      nextHeartbeat: { serialize: false },
      metadata: { serialize: ['create', 'new', 'update', 'edit'] },
      account: { serialize: false },
      product: { serialize: false },
      machine: { serialize: ['create', 'new'] },
      license: { serialize: false }
    }
  });
});