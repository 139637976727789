define('keygen/routes/users/index', ['exports', 'keygen/routes/users'], function (exports, _keygenRoutesUsers) {
  exports['default'] = _keygenRoutesUsers['default'].extend({
    queryParams: {
      page: { refreshModel: true },
      size: { refreshModel: true },
      assigned: { refreshModel: true },
      active: { refreshModel: true },
      product: { refreshModel: true },
      license: { refreshModel: true },
      group: { refreshModel: true },
      roles: { refreshModel: true },
      status: { refreshModel: true }
    },

    titleToken: function titleToken() {
      return 'Users';
    },

    model: function model(params) {
      var number = params.page;
      var size = params.size;
      var assigned = params.assigned;
      var active = params.active;
      var product = params.product;
      var license = params.license;
      var group = params.group;
      var roles = params.roles;
      var status = params.status;

      if (roles != null) {
        roles = roles.split(',');
      }

      return this.store.query('user', {
        page: { number: number, size: size },
        assigned: assigned,
        active: active,
        product: product,
        license: license,
        group: group,
        roles: roles,
        status: status
      });
    }
  });
});