define('keygen/routes/second-factors/index', ['exports', 'keygen/routes/second-factors', 'ember'], function (exports, _keygenRoutesSecondFactors, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var service = _ember['default'].inject.service;
  exports['default'] = _keygenRoutesSecondFactors['default'].extend({
    currentAccount: service('current-account'),
    currentBearer: service('current-bearer'),

    titleToken: function titleToken() {
      return 'Loading 2FA…';
    },

    model: function model(params) {
      var _this = this;

      var secondFactors = this.store.findAll('second-factor', { reload: true });

      return secondFactors.then(function (res) {
        var _res$toArray = res.toArray();

        var _res$toArray2 = _slicedToArray(_res$toArray, 1);

        var secondFactor = _res$toArray2[0];

        if (secondFactor != null && secondFactor.id != null) {
          return _this.transitionTo('second-factors.edit', secondFactor.id);
        }

        return _this.transitionTo('second-factors.new');
      });
    }
  });
});