define('keygen/models/event-log', ['exports', 'keygen/models/base', 'ember-data', 'ember'], function (exports, _keygenModelsBase, _emberData, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _keygenModelsBase['default'].extend({
    type: 'event-logs',

    account: _emberData['default'].belongsTo('account'),
    whodunnit: _emberData['default'].belongsTo('bearer', { polymorphic: true }),
    resource: _emberData['default'].belongsTo('base', { polymorphic: true }),
    request: _emberData['default'].belongsTo('request-log'),

    event: _emberData['default'].attr('string'),
    metadata: _emberData['default'].attr('json'),

    whodunnitId: computed('whodunnit', function () {
      return this.belongsTo('whodunnit').id();
    }),

    resourceId: computed('resource', function () {
      return this.belongsTo('resource').id();
    }),

    requestId: computed('request', function () {
      return this.belongsTo('request').id();
    })
  });
});