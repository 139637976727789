define('keygen/routes/artifacts/new', ['exports', 'keygen/routes/artifacts', 'ember'], function (exports, _keygenRoutesArtifacts, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _keygenRoutesArtifacts['default'].extend({
    currentAccount: service('current-account'),

    titleToken: function titleToken() {
      return 'New artifact';
    },

    model: function model() {
      return this.store.createRecord('artifact');
    }
  });
});