define('keygen/components/kg-metric-feed-item', ['exports', 'keygen/components/base', 'lodash'], function (exports, _keygenComponentsBase, _lodash) {
  exports['default'] = _keygenComponentsBase['default'].extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.isInfoLoaded = false;
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var metric = this.get('metric');
      var parts = metric.get('metric').split('.');
      var type = (0, _lodash.first)(parts);
      var event = (0, _lodash.last)(parts);

      this.set('event', event);
      this.set('type', type);
    },

    actions: {
      handleLoadInfo: function handleLoadInfo() {
        var metric = this.get('metric');
        var store = metric.get('store');
        var detail = metric.get('detail');
        var parts = metric.get('metric').split('.');
        var type = (0, _lodash.first)(parts);
        var resource = store.findRecord(type, detail[type] || detail.resource);

        this.set('resource', resource);

        if (detail.user) {
          this.set('user', store.findRecord('user', detail.user));
        }

        this.setProperties({
          isInfoLoaded: true,
          subject: resource
        });
      }
    }
  });
});