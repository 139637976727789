define('keygen/components/kg-analytics-top-by-volume-cards', ['exports', 'keygen/components/base', 'ember'], function (exports, _keygenComponentsBase, _ember) {
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _keygenComponentsBase['default'].extend({
    session: service('session'),
    router: service('-routing'),

    data: {},

    init: function init() {
      this._super.apply(this, arguments);

      this._fetchTopLicensesByVolume();
      this._fetchTopIpsByVolume();
      this._fetchTopUrlsByVolume();
    },

    isLoading: computed.empty('data'),

    topLicenses: computed('data.topLicensesByVolume', function () {
      var data = this.get('data.topLicensesByVolume');
      if (data == null) {
        return [];
      }

      var sorted = data.sort(function (a, b) {
        return b.count - a.count;
      });
      var sum = sorted.reduce(function (sum, r) {
        return sum + r.count;
      }, 0);
      var licenses = sorted.map(function (_ref) {
        var licenseId = _ref.licenseId;
        var count = _ref.count;

        return {
          percentage: count / sum * 100,
          id: licenseId,
          count: count
        };
      });

      return licenses;
    }),

    topIps: computed('data.topIpsByVolume', function () {
      var data = this.get('data.topIpsByVolume');
      if (data == null) {
        return [];
      }

      var sorted = data.sort(function (a, b) {
        return b.count - a.count;
      });
      var sum = sorted.reduce(function (sum, r) {
        return sum + r.count;
      }, 0);
      var ips = sorted.map(function (_ref2) {
        var ip = _ref2.ip;
        var count = _ref2.count;

        return {
          percentage: count / sum * 100,
          ip: ip,
          count: count
        };
      });

      return ips;
    }),

    topUrls: computed('data.topUrlsByVolume', function () {
      var data = this.get('data.topUrlsByVolume');
      if (data == null) {
        return [];
      }

      var sorted = data.sort(function (a, b) {
        return b.count - a.count;
      });
      var sum = sorted.reduce(function (sum, r) {
        return sum + r.count;
      }, 0);
      var urls = sorted.map(function (_ref3) {
        var method = _ref3.method;
        var url = _ref3.url;
        var count = _ref3.count;

        return {
          percentage: count / sum * 100,
          method: method,
          url: url,
          count: count
        };
      });

      return urls;
    }),

    _fetchTopLicensesByVolume: function _fetchTopLicensesByVolume() {
      var _this = this;

      var baseUrl = getOwner(this).lookup('adapter:application').buildURL();
      var accountId = this.get('session.data.authenticated.aid');
      var token = this.get('session.data.authenticated.tok');

      fetch(baseUrl + '/accounts/' + accountId + '/analytics/actions/top-licenses-by-volume', {
        headers: {
          "Content-Type": "application/vnd.api+json",
          "Accept": "application/vnd.api+json",
          "Authorization": 'Bearer ' + token
        }
      }).then(function (res) {
        return res.json();
      }).then(function (body) {
        if (_this.isDestroyed) {
          return;
        }

        var data = body.meta;

        _this.set('data.topLicensesByVolume', data);
      })['catch'](function (e) {
        if (_this.isDestroyed) {
          return;
        }

        _this.set('data.topLicensesByVolume', null);
      });
    },

    _fetchTopIpsByVolume: function _fetchTopIpsByVolume() {
      var _this2 = this;

      var baseUrl = getOwner(this).lookup('adapter:application').buildURL();
      var accountId = this.get('session.data.authenticated.aid');
      var token = this.get('session.data.authenticated.tok');

      fetch(baseUrl + '/accounts/' + accountId + '/analytics/actions/top-ips-by-volume', {
        headers: {
          "Content-Type": "application/vnd.api+json",
          "Accept": "application/vnd.api+json",
          "Authorization": 'Bearer ' + token
        }
      }).then(function (res) {
        return res.json();
      }).then(function (body) {
        if (_this2.isDestroyed) {
          return;
        }

        var data = body.meta;

        _this2.set('data.topIpsByVolume', data);
      })['catch'](function (e) {
        if (_this2.isDestroyed) {
          return;
        }

        _this2.set('data.topIpsByVolume', null);
      });
    },

    _fetchTopUrlsByVolume: function _fetchTopUrlsByVolume() {
      var _this3 = this;

      var baseUrl = getOwner(this).lookup('adapter:application').buildURL();
      var accountId = this.get('session.data.authenticated.aid');
      var token = this.get('session.data.authenticated.tok');

      fetch(baseUrl + '/accounts/' + accountId + '/analytics/actions/top-urls-by-volume', {
        headers: {
          "Content-Type": "application/vnd.api+json",
          "Accept": "application/vnd.api+json",
          "Authorization": 'Bearer ' + token
        }
      }).then(function (res) {
        return res.json();
      }).then(function (body) {
        if (_this3.isDestroyed) {
          return;
        }

        var data = body.meta;

        _this3.set('data.topUrlsByVolume', data);
      })['catch'](function (e) {
        if (_this3.isDestroyed) {
          return;
        }

        _this3.set('data.topUrlsByVolume', null);
      });
    },

    actions: {
      filterRequestLogsByLicenseId: function filterRequestLogsByLicenseId(id) {
        var _getProperties = this.getProperties('router.currentPath', 'router.router');

        var path = _getProperties['router.currentPath'];
        var router = _getProperties['router.router'];

        router.transitionTo(path, { queryParams: { 'resource[type]': 'license', 'resource[id]': id } });
      },

      filterRequestLogsByIp: function filterRequestLogsByIp(ip) {
        var _getProperties2 = this.getProperties('router.currentPath', 'router.router');

        var path = _getProperties2['router.currentPath'];
        var router = _getProperties2['router.router'];

        router.transitionTo(path, { queryParams: { ip: ip } });
      }
    }
  });
});