define('keygen/serializers/token', ['exports', 'keygen/serializers/base'], function (exports, _keygenSerializersBase) {
  exports['default'] = _keygenSerializersBase['default'].extend({
    attrs: {
      account: { serialize: false },
      bearer: { serialize: false },
      created: { serialize: false },
      updated: { serialize: false },
      maxActivations: { serialize: ['create', 'new'] },
      activations: { serialize: false },
      maxDeactivations: { serialize: ['create', 'new'] },
      deactivations: { serialize: false },
      expiry: { serialize: ['create', 'new'] },
      name: { serialize: ['create', 'new'] },
      permissions: { serialize: ['create', 'new'] },
      token: { serialize: false },
      kind: { serialize: false }
    },

    normalize: function normalize(model, payload) {
      var result = this._super.apply(this, arguments);

      // Since the token attribute is transient, we need to null it
      // if it's missing from the payload otherwise Ember Data
      // fails to update it and the cached value is used.
      if (result.data.attributes.token == null) {
        result.data.attributes.token = null;
      }

      return result;
    },

    serialize: function serialize(model, payload) {
      var json = this._super.apply(this, arguments);

      if (json.data != null && json.data.attributes != null) {
        // Permissions should not be serialized by default.
        if (json.data.attributes.permissions != null && json.data.attributes.permissions.length === 0) {
          delete json.data.attributes.permissions;
        }
      }

      return json;
    }
  });
});