define('keygen/routes/tokens/index', ['exports', 'keygen/routes/tokens'], function (exports, _keygenRoutesTokens) {
  exports['default'] = _keygenRoutesTokens['default'].extend({
    queryParams: {
      'bearer[type]': { refreshModel: true },
      'bearer[id]': { refreshModel: true },
      product: { refreshModel: true },
      license: { refreshModel: true },
      user: { refreshModel: true },
      page: { refreshModel: true },
      size: { refreshModel: true }
    },

    titleToken: function titleToken() {
      return 'Tokens';
    },

    model: function model(params) {
      var bearer = undefined;

      switch (true) {
        // FIXME(ezekg) Ember doesn't support object key/value query params
        case params['bearer[type]'] != null && params['bearer[id]'] != null:
          bearer = { type: params['bearer[type]'], id: params['bearer[id]'] };
          break;
        case params.product != null:
          bearer = { type: 'product', id: params.product };
          break;
        case params.license != null:
          bearer = { type: 'license', id: params.license };
          break;
        case params.user != null:
          bearer = { type: 'user', id: params.user };
          break;
      }

      return this.store.query('token', {
        bearer: bearer,
        page: {
          number: params.page,
          size: params.size
        }
      });
    }
  });
});