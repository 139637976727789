define('keygen/routes/artifacts', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'keygen/routes/base'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _keygenRoutesBase) {
  exports['default'] = _keygenRoutesBase['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    actions: {
      create: function create(artifact) {
        var _this = this;

        artifact.save({ adapterOptions: { prefer: 'no-redirect' } })['catch'](function (err) {
          return _this.handleModelErrors(artifact, err);
        }).then(function (a) {
          var flash = _this.get('flashMessages');
          if (flash != null) {
            flash.clearMessages();
          }

          _this.controller.promptForArtifactUpload().then(function () {
            return _this.transitionTo('artifacts.show', a.id);
          });
        });
      },
      update: function update(artifact) {
        var _this2 = this;

        artifact.save()['catch'](function (err) {
          return _this2.handleModelErrors(artifact, err);
        }).then(function (a) {
          var flash = _this2.get('flashMessages');
          if (flash != null) {
            flash.clearMessages();
          }

          _this2.transitionTo('artifacts.show', a.id);
        });
      },
      'delete': function _delete(artifact) {
        var _this3 = this;

        this.controller.promptForConfirmation().onConfirmed(function (confirm) {
          if (confirm !== 'DELETE') {
            return false;
          }

          artifact.destroyRecord()['catch'](function (err) {
            return _this3.handleModelErrors(artifact, err);
          }).then(function () {
            return _this3.transitionTo('artifacts.index');
          });
        });
      }
    }
  });
});