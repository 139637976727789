define('keygen/authenticators/token', ['exports', 'ember-simple-auth/authenticators/base', 'ember-network/fetch', 'ember', 'npm:raygun4js', 'keygen/config/environment'], function (exports, _emberSimpleAuthAuthenticatorsBase, _emberNetworkFetch, _ember, _npmRaygun4js, _keygenConfigEnvironment) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var _get = function get(_x, _x2, _x3) { var _again = true; _function: while (_again) { var object = _x, property = _x2, receiver = _x3; _again = false; if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { _x = parent; _x2 = property; _x3 = receiver; _again = true; desc = parent = undefined; continue _function; } } else if ('value' in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } } };

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

  function _inherits(subClass, superClass) { if (typeof superClass !== 'function' && superClass !== null) { throw new TypeError('Super expression must either be null or a function, not ' + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

  var ADMIN_ROLES = ['admin', 'developer', 'read-only', 'sales-agent', 'support-agent'];

  var AuthenticationError = (function (_Error) {
    _inherits(AuthenticationError, _Error);

    function AuthenticationError(message) {
      _classCallCheck(this, AuthenticationError);

      _get(Object.getPrototypeOf(AuthenticationError.prototype), 'constructor', this).call(this, message);

      if (Error.hasOwnProperty('captureStackTrace')) {
        Error.captureStackTrace(this);
      }

      // FIXME(ezekg) It's kind of weird that these are needed?
      this.message = message;
      this.name = 'AuthenticationError';
    }

    return AuthenticationError;
  })(Error);

  exports.AuthenticationError = AuthenticationError;

  var SecondFactorRequiredError = (function (_AuthenticationError) {
    _inherits(SecondFactorRequiredError, _AuthenticationError);

    function SecondFactorRequiredError(message) {
      _classCallCheck(this, SecondFactorRequiredError);

      _get(Object.getPrototypeOf(SecondFactorRequiredError.prototype), 'constructor', this).call(this, message);

      this.name = 'SecondFactorRequiredError';
    }

    return SecondFactorRequiredError;
  })(AuthenticationError);

  exports.SecondFactorRequiredError = SecondFactorRequiredError;

  var SecondFactorInvalidError = (function (_AuthenticationError2) {
    _inherits(SecondFactorInvalidError, _AuthenticationError2);

    function SecondFactorInvalidError(message) {
      _classCallCheck(this, SecondFactorInvalidError);

      _get(Object.getPrototypeOf(SecondFactorInvalidError.prototype), 'constructor', this).call(this, message);

      this.name = 'SecondFactorInvalidError';
    }

    return SecondFactorInvalidError;
  })(AuthenticationError);

  exports.SecondFactorInvalidError = SecondFactorInvalidError;
  var RSVP = _ember['default'].RSVP;
  var isEmpty = _ember['default'].isEmpty;
  exports['default'] = _emberSimpleAuthAuthenticatorsBase['default'].extend({
    /**
     * Restore a session from an existing payload
     */
    restore: function restore(payload) {
      var _this = this;

      return new RSVP.Promise(function (resolve, reject) {
        if (!_this._tokenPayloadIsValid(payload) || _this._tokenPayloadIsExpired(payload)) {
          return reject();
        }

        var endpoint = _keygenConfigEnvironment['default'].keygen.host + '/v1/accounts/' + payload.aid + '/me';
        var headers = {
          'Authorization': 'Bearer ' + payload.tok,
          'Accept': 'application/vnd.api+json'
        };

        (0, _emberNetworkFetch['default'])(endpoint, { method: 'GET', headers: headers }).then(function (response) {
          return response.json();
        }).then(function (_ref) {
          var bearer = _ref.data;
          var errors = _ref.errors;

          if (errors) {
            var _errors = _slicedToArray(errors, 1);

            var error = _errors[0];

            return reject(new AuthenticationError(error.detail));
          }

          if (bearer == null || bearer.type !== 'users' || !ADMIN_ROLES.includes(bearer.attributes.role)) {
            return reject(new AuthenticationError('must be an admin to access the dashboard'));
          }

          _this._bootstrapSession(payload, bearer);

          return resolve(payload);
        })['catch'](function (_e) {
          return reject(new AuthenticationError('failed to complete the request'));
        });
      });
    },

    /**
     * Create a new session
     */
    authenticate: function authenticate(account, email, password, otp, expiry) {
      var _this2 = this;

      return new RSVP.Promise(function (resolve, reject) {
        var endpoint = _keygenConfigEnvironment['default'].keygen.host + '/v1/accounts/' + account + '/tokens';
        var headers = {
          // See: https://developer.mozilla.org/en-US/docs/Web/API/window.btoa#Unicode_Strings
          'Authorization': 'Basic ' + btoa(unescape(encodeURIComponent(email + ':' + password))),
          'Content-Type': 'application/vnd.api+json',
          'Accept': 'application/vnd.api+json'
        };

        var params = { method: 'POST', headers: headers };
        var data = undefined,
            meta = undefined;

        // FIXME(ezekg) Some accounts want their tokens to have a specific TTL.
        //              Should be moved server-side.
        switch (account) {
          case '11e1a4ce-64a8-4d42-9177-0cbcd8d7adaf':
          case 'dev-synopsys-com':
          case 'e2e92373-38a0-431d-be9a-057e346dd841':
          case 'synopsys-com':
          case '5cc3b5a2-0d08-4291-940b-41c21f0ba6ab':
          case 'keygen':
            data = {
              type: 'token',
              attributes: {
                expiry: moment().utc().add(8, 'hours').format()
              }
            };

            break;
          case '280a22fc-9448-4a79-9d7e-d3ff3a9ff1a6':
          case 'nonica-io':
            data = {
              type: 'token',
              attributes: {
                expiry: moment().utc().add(2, 'hours').format()
              }
            };

            break;
          default:
            data = {
              type: 'token',
              attributes: {
                expiry: expiry
              }
            };
        }

        if (otp != null) {
          meta = { otp: otp };
        }

        if (meta != null || data != null) {
          params.body = JSON.stringify({ meta: meta, data: data });
        }

        (0, _emberNetworkFetch['default'])(endpoint, params).then(function (response) {
          return response.json();
        }).then(function (_ref2) {
          var token = _ref2.data;
          var errors = _ref2.errors;

          if (errors) {
            var _errors2 = _slicedToArray(errors, 1);

            var error = _errors2[0];

            switch (error.code) {
              case 'OTP_REQUIRED':
                return reject(new SecondFactorRequiredError(error.detail));
              case 'OTP_INVALID':
                return reject(new SecondFactorInvalidError(error.detail));
              default:
                return reject(new AuthenticationError(error.detail));
            }
          }

          var payload = _this2._tokenToSessionPayload(token);

          return resolve(payload);
        })['catch'](function (_e) {
          return reject(new AuthenticationError('failed to complete the request'));
        });
      });
    },

    /**
     * Revoke a session using an existing payload
     */
    invalidate: function invalidate(payload) {
      return new RSVP.Promise(function (resolve, reject) {
        var endpoint = _keygenConfigEnvironment['default'].keygen.host + '/v1/accounts/' + payload.aid + '/tokens/' + payload.tid;
        var headers = {
          'Authorization': 'Bearer ' + payload.tok,
          'Accept': 'application/vnd.api+json'
        };

        (0, _emberNetworkFetch['default'])(endpoint, { method: 'DELETE', headers: headers }).then(function () {
          return resolve();
        })['catch'](function () {
          return reject(new AuthenticationError('failed to complete the request'));
        });
      });
    },

    /* @private */

    _tokenToSessionPayload: function _tokenToSessionPayload(token) {
      var tid = token.id;
      var _token$attributes = token.attributes;
      var exp = _token$attributes.expiry;
      var tok = _token$attributes.token;
      var _token$relationships = token.relationships;
      var aid = _token$relationships.account.data.id;
      var uid = _token$relationships.bearer.data.id;

      return { tok: tok, tid: tid, aid: aid, uid: uid, exp: exp };
    },

    _tokenPayloadIsValid: function _tokenPayloadIsValid(payload) {
      if (isEmpty(payload)) {
        return false;
      }

      var tok = payload.tok;
      var tid = payload.tid;
      var aid = payload.aid;
      var uid = payload.uid;

      return tok != null && tid != null && aid != null && uid != null;
    },

    _tokenPayloadIsExpired: function _tokenPayloadIsExpired(payload) {
      try {
        var exp = payload.exp;

        if (exp == null) {
          return false;
        }

        var expiry = Date.parse(exp);
        var now = Date.now();

        return expiry < now;
      } catch (_e) {
        return true;
      }
    },

    _tokenPayloadIsLegacy: function _tokenPayloadIsLegacy(token) {
      var meta = token.meta;

      if (meta != null && meta.profile != null) {
        return true;
      }

      return false;
    },

    _revokeLegacySession: function _revokeLegacySession(_ref3) {
      var id = _ref3.id;
      var type = _ref3.type;
      var token = _ref3.attributes.token;
      var account = _ref3.relationships.account.data;

      return new RSVP.Promise(function (resolve, reject) {
        if (type !== 'tokens') {
          return reject();
        }

        var endpoint = _keygenConfigEnvironment['default'].keygen.host + '/v1/accounts/' + account.id + '/tokens/' + id;
        var headers = {
          'Authorization': 'Bearer ' + token,
          'Accept': 'application/vnd.api+json'
        };

        (0, _emberNetworkFetch['default'])(endpoint, { method: 'DELETE', headers: headers }).then(function () {
          return resolve();
        })['catch'](function () {
          return reject(new AuthenticationError('failed to complete the request'));
        });
      });
    },

    _bootstrapSession: function _bootstrapSession(payload, bearer) {
      if (isEmpty(payload)) {
        return false;
      }

      try {
        // Revoke legacy sessions (these were too large so we slimmed the payload down)
        if (this._tokenPayloadIsLegacy(payload)) {
          this._revokeLegacySession(payload);

          return false;
        }

        // Raygun current user
        try {
          (0, _npmRaygun4js['default'])('setUser', {
            identifier: bearer.id,
            isAnonymous: false,
            email: bearer.attributes.email,
            firstName: bearer.attributes.firstName,
            fullName: bearer.attributes.fullName
          });
        } catch (e) {
          console.error('[raygun]', e);
        }

        return true;
      } catch (e) {
        console.error('[bootstrap]', e);

        (0, _npmRaygun4js['default'])('send', e);

        return false;
      }
    }
  });
});