define('keygen/routes/licenses/new', ['exports', 'keygen/routes/licenses', 'ember'], function (exports, _keygenRoutesLicenses, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _keygenRoutesLicenses['default'].extend({
    currentAccount: service('current-account'),

    titleToken: function titleToken() {
      return 'New license';
    },

    model: function model() {
      var _get = this.get('currentAccount');

      var account = _get.account;

      return this.store.createRecord('license', {
        'protected': account.get('protected')
      });
    }
  });
});