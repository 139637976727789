define('keygen/routes/second-factors/new', ['exports', 'keygen/routes/second-factors', 'ember'], function (exports, _keygenRoutesSecondFactors, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _keygenRoutesSecondFactors['default'].extend({
    currentAccount: service('current-account'),
    currentBearer: service('current-bearer'),

    titleToken: function titleToken() {
      return 'Add 2FA';
    },

    model: function model(params) {
      return this.store.createRecord('second-factor');
    },

    actions: {
      addSecondFactor: function addSecondFactor(secondFactor, authParams) {
        var _this = this;

        secondFactor.set('request.meta', authParams);

        return secondFactor.save()['catch'](function (err) {
          return _this.handleModelErrors(secondFactor, err);
        }).then(function (_ref) {
          var id = _ref.id;
          return _this.transitionTo('second-factors.edit', id);
        });
      }
    }
  });
});