define('keygen/components/kg-notification', ['exports', 'keygen/components/base', 'ember'], function (exports, _keygenComponentsBase, _ember) {
  var getWithDefault = _ember['default'].getWithDefault;
  exports['default'] = _keygenComponentsBase['default'].extend({
    type: 'info',

    click: function click() {
      var flash = getWithDefault(this, 'flash', false);
      if (flash) {
        flash.destroyMessage();
      }
    }
  });
});