define('keygen/controllers/artifacts/new', ['exports', 'keygen/controllers/application', 'ember'], function (exports, _keygenControllersApplication, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _keygenControllersApplication['default'].extend({
    flashMessages: service('flashMessages'),
    session: service(),
    uploader: null,
    promise: null,

    promptForArtifactUpload: function promptForArtifactUpload() {
      var _this = this;

      this.actions.showArtifactUploadModal.bind(this)();

      return new Promise(function (resolve, reject) {
        return _this.set('promise', { resolve: resolve, reject: reject });
      });
    },

    actions: {
      showArtifactUploadModal: function showArtifactUploadModal() {
        this.set('isArtifactUploadModalOpen', true);
      },

      exitArtifactUploadModal: function exitArtifactUploadModal() {
        this.set('isArtifactUploadModalOpen', false);
        this.set('uploader', null);
      },

      onArtifactUploadProgress: function onArtifactUploadProgress(event) {
        var percentage = event.percent;

        this.set('uploader', { percentage: percentage });
      },

      onArtifactUploadComplete: function onArtifactUploadComplete(_data) {
        var _this2 = this;

        this.set('uploader', { percentage: 100 });

        setTimeout(function () {
          var flash = _this2.get('flashMessages');

          flash.clearMessages();
          flash.add({
            message: 'Successfully uploaded artifact! It is now available for download.',
            type: 'success',
            timeout: 5000,
            priority: 500
          });

          _this2.send('exitArtifactUploadModal');
        }, 2000);

        var promise = this.get('promise');
        if (promise) {
          promise.resolve();
        }
      },

      onArtifactUploadError: function onArtifactUploadError(error) {
        var message = error.message != null ? error.message : error.responseText;
        var flash = this.get('flashMessages');

        this.set('uploader', { error: message });

        flash.clearMessages();
        flash.add({
          message: 'Failed to upload release artifact: ' + error.statusText.toLowerCase(),
          type: 'error',
          timeout: Infinity
        });

        var promise = this.get('promise');
        if (promise) {
          promise.reject();
        }
      }
    }
  });
});