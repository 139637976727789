define('keygen/routes/releases/show', ['exports', 'keygen/routes/releases'], function (exports, _keygenRoutesReleases) {
  exports['default'] = _keygenRoutesReleases['default'].extend({
    titleToken: function titleToken(model) {
      return 'Release ' + model.get('id');
    },

    model: function model(params) {
      return this.store.findRecord('release', params.id, { reload: true });
    }
  });
});