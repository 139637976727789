define('keygen/mixins/changed-relationships', ['exports', 'ember', 'lodash'], function (exports, _ember, _lodash) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var isEqual = _lodash['default'].isEqual;
  var Mixin = _ember['default'].Mixin;

  var mapById = function mapById(member) {
    return member.id;
  };

  /**
   * @see https://github.com/isleofcode/ember-changed-relationships
   */
  exports['default'] = Mixin.create({
    changedRelationships: function changedRelationships() {
      var _this = this;

      // IDs are always undefined until initialized
      if (this.get('_internalModel.dataHasInitialized') === false) {
        return;
      }

      var relationships = {};

      this.eachRelationship(function (name, meta) {
        var basePath = '_internalModel._relationships.initializedRelationships.' + name;
        var hasCanonical = _this.get(basePath + '.canonicalMembers.list.length') > 0;

        switch (meta.kind) {
          case 'belongsTo':
            {
              var nextId = _this.get(name + '.id');
              var prevId = null;
              if (hasCanonical) {
                var _get = _this.get(basePath + '.canonicalMembers.list');

                var _get2 = _slicedToArray(_get, 1);

                var firstObject = _get2[0];

                if (firstObject) {
                  prevId = firstObject.id;
                }
              }

              if (prevId !== nextId) {
                relationships[name] = [prevId, nextId];
              }

              break;
            }
          case 'hasMany':
            {
              var nextIds = _this.get(basePath + '.members.list').map(mapById);
              var prevIds = [];
              if (hasCanonical) {
                prevIds = _this.get(basePath + '.canonicalMembers.list').map(mapById);
              }

              if (isEqual(prevIds, nextIds) === false) {
                relationships[name] = [prevIds, nextIds];
              }

              break;
            }
        }
      });

      return relationships;
    }
  });
});