define('keygen/components/kg-product-releases', ['exports', 'keygen/components/base', 'ember', 'npm:copy-to-clipboard', 'npm:download-in-browser'], function (exports, _keygenComponentsBase, _ember, _npmCopyToClipboard, _npmDownloadInBrowser) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var Obj = _ember['default'].Object;
  var Arr = _ember['default'].A;
  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;
  exports['default'] = _keygenComponentsBase['default'].extend({
    flashMessages: service('flashMessages'),
    session: service('session'),

    init: function init() {
      this._super.apply(this, arguments);

      this.recentlyCopied = Obj.create({ url: {}, checksum: {} });
      this.pendingReleases = Arr([]);
      this.currentPlatform = null;
      this.platforms = Arr([]);
      this.releases = Arr([]);
    },

    account: computed.alias('product.account'),

    headers: computed('session.data.authenticated.tok', function () {
      var token = this.get('session.data.authenticated.tok');
      return {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/vnd.api+json'
      };
    }),

    observeForPlatforms: observer('account', 'product', function () {
      this.send('fetchPlatforms');
    }).on('init'),

    observeForReleases: observer('account', 'product', 'platforms', 'currentPlatform', function () {
      this.send('fetchReleases');
    }).on('init'),

    actions: {
      fetchPlatforms: function fetchPlatforms() {
        var _this = this;

        var _getProperties = this.getProperties('account', 'product', 'headers');

        var account = _getProperties.account;
        var product = _getProperties.product;
        var headers = _getProperties.headers;

        return fetch('https://dist.keygen.sh/v1/' + account.get('id') + '/' + product.get('id') + '/platforms', { headers: headers }).then(function (res) {
          return res.json();
        }).then(function (_ref) {
          var data = _ref.data;
          var errors = _ref.errors;

          if (errors) {
            return;
          }

          // Deselect current platform if it no longer exists.
          var currentPlatform = _this.get('currentPlatform');
          if (!data.some(function (p) {
            return p.attributes.name === currentPlatform;
          })) {
            _this.set('currentPlatform', null);
          }

          _this.set('platforms', data);
          return data;
        });
      },
      fetchReleases: function fetchReleases() {
        var _this2 = this;

        var _getProperties2 = this.getProperties('account', 'product', 'currentPlatform', 'headers');

        var account = _getProperties2.account;
        var product = _getProperties2.product;
        var currentPlatform = _getProperties2.currentPlatform;
        var headers = _getProperties2.headers;

        if (!currentPlatform) {
          this.set('releases', Arr([]));
          return;
        }

        return fetch('https://dist.keygen.sh/v1/' + account.get('id') + '/' + product.get('id') + '/releases/' + currentPlatform, { headers: headers }).then(function (res) {
          return res.json();
        }).then(function (_ref2) {
          var data = _ref2.data;
          var errors = _ref2.errors;

          if (errors) {
            return;
          }

          _this2.set('releases', data);
          return data;
        });
      },
      handlePlatformSelect: function handlePlatformSelect(platform) {
        this.set('currentPlatform', platform);
      },
      handleReleaseDownload: function handleReleaseDownload(release) {
        var _release$attributes = release.attributes;
        var platform = _release$attributes.platform;
        var key = _release$attributes.key;
        var url = _release$attributes.url;

        var token = this.get('session.data.authenticated.tok');
        var flash = this.get('flashMessages');
        flash.clearMessages();

        (0, _npmDownloadInBrowser['default'])('' + url, key).then(function (res) {
          flash.add({
            message: 'Download starting for ' + platform + '/' + key + '…',
            type: 'success',
            timeout: Infinity,
            priority: 500
          });
        })['catch'](function (err) {
          flash.add({
            message: err.statusText + ': download failed to start for ' + platform + '/' + key,
            type: 'error',
            timeout: Infinity
          });
        });
      },
      handleReleaseCopyURLToClipboard: function handleReleaseCopyURLToClipboard(release) {
        var _this3 = this;

        var id = release.id;
        var url = release.attributes.url;

        var cleanUrl = url.split(/[#?]/)[0];

        setTimeout(function () {
          return _this3.set('recentlyCopied.url.' + id, false);
        }, 2500);
        this.set('recentlyCopied.url.' + id, (0, _npmCopyToClipboard['default'])(cleanUrl));
      },
      handleReleaseCopyChecksumToClipboard: function handleReleaseCopyChecksumToClipboard(release) {
        var _this4 = this;

        var id = release.id;
        var checksum = release.attributes.checksum;

        setTimeout(function () {
          return _this4.set('recentlyCopied.checksum.' + id, false);
        }, 2500);
        this.set('recentlyCopied.checksum.' + id, (0, _npmCopyToClipboard['default'])(checksum));
      },
      handleReleaseUploaded: function handleReleaseUploaded(release, res) {
        var _release$getProperties = release.getProperties('platform', 'key');

        var platform = _release$getProperties.platform;
        var key = _release$getProperties.key;

        var flash = this.get('flashMessages');

        flash.clearMessages();
        flash.add({
          message: 'Successfully uploaded release: ' + platform + '/' + key,
          type: 'success',
          timeout: Infinity,
          priority: 500
        });

        this.send('fetchPlatforms');
      },
      handleReleaseUploadError: function handleReleaseUploadError(release, error) {
        var _release$getProperties2 = release.getProperties('platform', 'key');

        var platform = _release$getProperties2.platform;
        var key = _release$getProperties2.key;

        var flash = this.get('flashMessages');

        flash.clearMessages();
        flash.add({
          message: 'Failed to upload release: ' + platform + '/' + key,
          type: 'error',
          timeout: Infinity
        });

        this.send('fetchPlatforms');
      },
      handleReleaseDelete: function handleReleaseDelete(release) {
        var _this5 = this;

        if (!confirm("Are you sure you want to delete this release? Any existing download links for the release will immediately stop working.")) {
          return;
        }

        var _getProperties3 = this.getProperties('account', 'product', 'currentPlatform', 'headers');

        var account = _getProperties3.account;
        var product = _getProperties3.product;
        var currentPlatform = _getProperties3.currentPlatform;
        var headers = _getProperties3.headers;

        if (currentPlatform == null) {
          return;
        }

        var key = release.attributes.key;

        var flash = this.get('flashMessages');

        fetch('https://dist.keygen.sh/v1/' + account.get('id') + '/' + product.get('id') + '/releases/' + currentPlatform + '/' + key, { method: "DELETE", headers: headers }).then(function (res) {
          return res.json();
        })['catch'](function () {
          return undefined;
        } /* catch JSON parse error */).then(function () {
          var _ref3 = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

          var data = _ref3.data;
          var errors = _ref3.errors;

          flash.clearMessages();

          if (errors) {
            var _errors = _slicedToArray(errors, 1);

            var error = _errors[0];

            var message = (error.detail || error.title).toLowerCase();

            flash.add({
              message: 'Failed to delete release: ' + message,
              type: 'error',
              timeout: Infinity
            });

            return;
          }

          flash.add({
            message: 'Successfully deleted release: ' + currentPlatform + '/' + key,
            type: 'success',
            timeout: Infinity,
            priority: 500
          });

          _this5.send('fetchPlatforms');
        });
      },
      handleReleaseCreate: function handleReleaseCreate() {
        var _getProperties4 = this.getProperties('pendingReleases', 'currentPlatform');

        var pendingReleases = _getProperties4.pendingReleases;
        var currentPlatform = _getProperties4.currentPlatform;

        pendingReleases.pushObject(Obj.create({
          platform: currentPlatform,
          progress: null,
          success: null,
          error: null,
          key: null
        }));
      },
      handleReleaseCancel: function handleReleaseCancel(release) {
        var _getProperties5 = this.getProperties('pendingReleases');

        var pendingReleases = _getProperties5.pendingReleases;
        var uploader = release.uploader;
        var key = release.key;
        var platform = release.platform;

        if (uploader) {
          try {
            uploader.abort();
          } catch (e) {}
        }

        pendingReleases.removeObject(release);
      }
    }
  });
});