define('keygen/routes/engines/index', ['exports', 'keygen/routes/engines'], function (exports, _keygenRoutesEngines) {
  exports['default'] = _keygenRoutesEngines['default'].extend({
    queryParams: {
      page: { refreshModel: true },
      size: { refreshModel: true }
    },

    titleToken: function titleToken() {
      return 'Engines';
    },

    model: function model(params) {
      var number = params.page;
      var size = params.size;

      return this.store.query('engine', {
        page: { number: number, size: size }
      });
    }
  });
});