define('keygen/serializers/key', ['exports', 'keygen/serializers/base'], function (exports, _keygenSerializersBase) {
  exports['default'] = _keygenSerializersBase['default'].extend({
    attrs: {
      created: { serialize: false },
      updated: { serialize: false },
      account: { serialize: false },
      product: { serialize: false },
      policy: { serialize: ['create', 'new'] }
    }
  });
});