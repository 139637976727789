define('keygen/components/kg-artifact-uploader', ['exports', 'ember-uploader', 'ember'], function (exports, _emberUploader, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var service = _ember['default'].inject.service;
  var getOwner = _ember['default'].getOwner;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var FileField = _emberUploader['default'].FileField;
  var Uploader = _emberUploader['default'].Uploader;

  var S3Uploader = Uploader.extend({
    upload: function upload(file) {
      var url = get(this, 'url');
      var method = get(this, 'method');

      set(this, 'isUploading', true);

      return this.ajax(url, file, method);
    }
  });

  exports['default'] = FileField.extend({
    session: service('session'),

    filesDidChange: function filesDidChange(_ref) {
      var _this = this;

      var _ref2 = _slicedToArray(_ref, 1);

      var file = _ref2[0];

      if (file == null) {
        return;
      }

      var url = this.get('artifact.meta.links.redirect');
      var uploader = S3Uploader.create({
        method: 'PUT',
        url: url
      });

      uploader.on('progress', function (event) {
        var onProgress = _this.get('onProgress');
        if (onProgress == null) {
          return;
        }

        onProgress(event);
      });

      uploader.on('didUpload', function (event) {
        var onUpload = _this.get('onUpload');
        if (onUpload == null) {
          return;
        }

        onUpload(event);
      });

      uploader.on('didError', function (err) {
        var onError = _this.get('onError');
        if (onError == null) {
          return;
        }

        onError(err);
      });

      uploader.upload(file);
    }
  });
});