define('keygen/mixins/user-namespace', ['exports', 'ember'], function (exports, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Mixin.create({
    session: service('session'),

    // FIXME(ezekg) Like the account namespace… this is very hacky.
    namespace: (function () {
      var accountId = this.get('session.data.authenticated.aid');
      var userId = this.get('session.data.authenticated.uid');

      return 'v1/accounts/' + accountId + '/users/' + userId;
    }).property().volatile()
  });
});