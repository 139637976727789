define('keygen/adapters/application', ['exports', 'ember-simple-auth/mixins/data-adapter-mixin', 'ember-data-has-many-query', 'ember', 'ember-data', 'keygen/config/environment'], function (exports, _emberSimpleAuthMixinsDataAdapterMixin, _emberDataHasManyQuery, _ember, _emberData, _keygenConfigEnvironment) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;

  var ADDITIONAL_AUTH_ERROR_CODES = ['PASSWORD_REQUIRED', 'PASSWORD_INVALID', 'OTP_REQUIRED', 'OTP_INVALID'];

  exports['default'] = _emberData['default'].JSONAPIAdapter.extend(_emberSimpleAuthMixinsDataAdapterMixin['default'], _emberDataHasManyQuery['default'].RESTAdapterMixin, {
    flashMessages: service('flashMessages'),

    authorizer: 'authorizer:token',
    host: _keygenConfigEnvironment['default'].keygen.host,
    namespace: 'v1',
    version: '1.7',

    headers: computed('version', function () {
      var version = this.get('version');

      return {
        'Prefer': 'no-redirect, no-download',
        'Keygen-Version': version
      };
    }),

    // FIXME(ezekg) Monkey patch response handling to add 400 errors to models
    // FIXME(ezekg) Monkey patch session invalidation on 401 status to not invalidate
    //              session when prompted for second factor
    handleResponse: function handleResponse(status, headers, payload /*, request */) {
      if (status === 401 && payload != null) {
        var _ref = payload.errors != null ? payload.errors : [];

        var _ref2 = _slicedToArray(_ref, 1);

        var code = _ref2[0].code;

        // Skip auto-logout for second factor error codes or codes that prompt
        // for additional auth
        if (ADDITIONAL_AUTH_ERROR_CODES.includes(code)) {
          return new _emberData['default'].InvalidError(payload.errors);
        }
      }

      if (status === 402) {
        var flash = get(this, 'flashMessages');

        flash.clearMessages();
        flash.add({
          message: 'Daily API request limit has been reached. Please add a payment method to your account.',
          type: 'error',
          timeout: Infinity
        });
      }

      if (status === 403) {
        var flash = get(this, 'flashMessages');

        flash.clearMessages();
        flash.add({
          message: 'You do not have permission to perform this action.',
          type: 'error',
          timeout: Infinity
        });
      }

      if (status === 400 && payload != null && payload.errors) {
        return new _emberData['default'].InvalidError(payload.errors.map(function (e) {
          if (e.source && /^\/data\/relationships\//.test(e.source.pointer)) {
            e.source.pointer = e.source.pointer.replace(/\/data$/, '');
          }

          return e;
        }));
      }

      // Append top level meta into the data's meta so that it's actually
      // serialized into the model (i.e. so we can use it).
      if (payload != null && (payload.meta || payload.data != null && payload.data.meta)) {
        try {
          if (payload.data == null) {
            payload.data = {};
          }

          // Add to data's meta
          payload.data.meta = Object.assign({}, payload.data.meta, payload.meta);

          // Add to attrs
          if (payload.data.attributes) {
            payload.data.attributes.meta = payload.data.meta;
          }
        } catch (e) {
          console.error(e);
        }
      }

      // Append links to meta as well
      if (payload != null && (payload.links || payload.data != null && payload.data.links)) {
        try {
          if (payload.data == null) {
            payload.data = {};
          }

          // Combine top level and resource links
          var links = Object.assign({}, payload.data.links, payload.links);

          // Add to meta
          payload.data.meta = Object.assign({}, payload.data.meta, { links: links });

          // Add to attrs
          if (payload.data.attributes) {
            payload.data.attributes.meta = payload.data.meta;
          }
        } catch (e) {
          console.error(e);
        }
      }

      return this._super.apply(this, arguments);
    }
  });
});