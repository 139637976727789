define('keygen/transforms/array', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].Transform.extend({

    deserialize: function deserialize(value) {
      if (_ember['default'].isArray(value)) {
        return _ember['default'].A(value);
      } else {
        return _ember['default'].A();
      }
    },

    serialize: function serialize(value) {
      if (_ember['default'].isArray(value)) {
        return _ember['default'].A(value);
      } else {
        if (value) {
          return _ember['default'].A(value.toString().split(/,\s*/));
        } else {
          return _ember['default'].A();
        }
      }
    }
  });
});