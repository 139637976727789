define('keygen/routes/webhook-endpoints/index', ['exports', 'keygen/routes/webhook-endpoints'], function (exports, _keygenRoutesWebhookEndpoints) {
  exports['default'] = _keygenRoutesWebhookEndpoints['default'].extend({
    queryParams: {
      page: { refreshModel: true },
      size: { refreshModel: true }
    },

    titleToken: function titleToken() {
      return 'Endpoints';
    },

    model: function model(params) {
      return this.store.query('webhook-endpoint', {
        page: {
          number: params.page,
          size: params.size
        }
      });
    }
  });
});