define('keygen/routes/tokens', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'keygen/routes/base', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _keygenRoutesBase, _ember) {
  var service = _ember['default'].inject.service;
  exports['default'] = _keygenRoutesBase['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    session: service('session'),

    actions: {
      create: function create(token) {
        var _this = this;

        token.save()['catch'](function (err) {
          return _this.handleModelErrors(token, err);
        }).then(function (t) {
          var flash = _this.get('flashMessages');
          if (flash != null) {
            flash.clearMessages();
          }

          _this.transitionTo('tokens.show', t);
        });
      },
      'delete': function _delete(token) {
        var _this2 = this;

        this.controller.promptForConfirmation().onConfirmed(function (confirm) {
          if (confirm !== 'DELETE') {
            return false;
          }

          token.destroyRecord()['catch'](function (err) {
            return _this2.handleModelErrors(token, err);
          }).then(function () {
            var session = _this2.get('session');
            var isCurrentSessionToken = session.get('data.authenticated.tid') === token.get('id');
            if (isCurrentSessionToken) {
              return session.invalidate().then(function () {
                return _this2.transitionTo('login');
              });
            }

            _this2.transitionTo('tokens.index');
          });
        });
      }
    }
  });
});