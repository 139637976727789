define('keygen/components/kg-resource-relationships', ['exports', 'keygen/components/base', 'ember', 'lodash'], function (exports, _keygenComponentsBase, _ember, _lodash) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  var PromiseProxyMixin = _ember['default'].PromiseProxyMixin;
  var ArrayProxy = _ember['default'].ArrayProxy;
  exports['default'] = _keygenComponentsBase['default'].extend({
    filtered: [
      // 'account'
    ],

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var resource = this.get('resource');
      var attrs = this.get('attrs');
      var rels = this.get('rels'); // FIXME(ezekg) For conflicts with resource=

      delete attrs.relationships;
      delete attrs.resource;
      delete attrs.nested;
      delete attrs.rels;

      if ((0, _lodash.isEmpty)(attrs) && (0, _lodash.isEmpty)(rels)) {
        return;
      }

      this.set('relationships', (0, _lodash.reduce)(_extends({}, attrs, rels), function (relationships, rel, key) {
        if (!(0, _lodash.includes)(_this.get('filtered'), key)) {
          var value = undefined,
              ref = undefined;
          if (rel.fetch) {
            if (rel.collection) {
              value = ArrayProxy.extend(PromiseProxyMixin).create({
                promise: resource.query(key, { limit: 100 })
              });
            } else {
              value = resource.get(key);
            }
          } else {
            if (rel.collection) {
              ref = resource.hasMany(key);

              // TODO: Handle collections
            } else {
                ref = resource.belongsTo(key);

                value = { id: ref.id(), type: ref.type };
              }
          }

          relationships.push(Object.assign({}, rel, { value: value }));
        }
        return relationships;
      }, []));
    }
  });
});