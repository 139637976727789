define('keygen/serializers/release', ['exports', 'keygen/serializers/base'], function (exports, _keygenSerializersBase) {
  exports['default'] = _keygenSerializersBase['default'].extend({
    attrs: {
      version: { serialize: ['create', 'new'] },
      channel: { serialize: ['create', 'new'] },
      status: { serialize: false },
      semver: { serialize: false },
      created: { serialize: false },
      updated: { serialize: false },
      account: { serialize: false },
      product: { serialize: ['create', 'new'] },
      entitlements: { serialize: false },
      constraints: { serialize: false },
      artifacts: { serialize: false }
    },

    serialize: function serialize(snapshot, options) {
      var json = this._super.apply(this, arguments);

      // These attributes get serialized for new records, and we don't want
      // that, since the API doesn't allow these params for non-admins.
      if (json != null && json.relationships != null && json.relationships.constraints != null) {
        // TODO(ezekg) Add support for specifying constraints
      }

      return json;
    }
  });
});