define('keygen/mixins/serialize-top-level-meta', ['exports', 'lodash', 'ember'], function (exports, _lodash, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    serialize: function serialize(snapshot, options) {
      var data = this._super(snapshot, options);

      try {
        var record = snapshot.record;

        if ((0, _lodash.isEmpty)(record.request)) {
          return data;
        }

        var meta = record.request.meta;

        if ((0, _lodash.isEmpty)(meta)) {
          return data;
        }

        data.meta = meta;
      } catch (e) {
        console.error('[serialize-top-level-meta]', e);
      }

      return data;
    }
  });
});