define('keygen/transforms/string', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var isEmpty = _ember['default'].isEmpty;
  exports['default'] = _emberData['default'].Transform.extend({

    deserialize: function deserialize(str) {
      return isEmpty(str) ? null : str;
    },

    serialize: function serialize(str) {
      return isEmpty(str) ? null : str;
    }
  });
});