define('keygen/helpers/route-action-exists', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var emberArray = _ember['default'].A;
  var assert = _ember['default'].assert;
  var get = _ember['default'].get;
  var typeOf = _ember['default'].typeOf;
  exports['default'] = _ember['default'].Helper.extend({
    router: _ember['default'].inject.service('-routing'),

    compute: function compute(params) {
      var _get = get(this, 'router.router');

      var router = _get.router;

      assert('[route-action-exists-helper] Unable to lookup router', router);

      var _params = _slicedToArray(params, 2);

      var resource = _params[0];
      var action = _params[1];

      var routes = emberArray(router.currentHandlerInfos).mapBy('handler').reverse();

      return emberArray(routes).any(function (route) {
        return typeOf(route.actions[action]) === "function";
      });
    }
  });
});