define('keygen/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'keygen/routes/base', 'ember', 'npm:detect-browser'], function (exports, _emberSimpleAuthMixinsApplicationRouteMixin, _keygenRoutesBase, _ember, _npmDetectBrowser) {
  var service = _ember['default'].inject.service;
  var RSVP = _ember['default'].RSVP;
  var isEmpty = _ember['default'].isEmpty;
  exports['default'] = _keygenRoutesBase['default'].extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    currentAccount: service('current-account'),
    currentBearer: service('current-bearer'),
    classNames: [_npmDetectBrowser['default'].name],

    title: function title(tokens) {
      return !isEmpty(tokens) ? tokens.join(' | ') : 'Dashboard';
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      return RSVP.all([this._loadCurrentAccount(), this._loadCurrentBearer()]);
    },

    sessionAuthenticated: function sessionAuthenticated() {
      this.refresh();
    },

    sessionInvalidated: function sessionInvalidated() {
      // noop to stop ESA from reloading the window
    },

    _loadCurrentAccount: function _loadCurrentAccount() {
      var _this = this;

      return this.get('currentAccount').load()['catch'](function (e) {
        console.error(e);

        var session = _this.get('session');

        if (session.get('isAuthenticated')) {
          session.invalidate();
        }
      });
    },

    _loadCurrentBearer: function _loadCurrentBearer() {
      var _this2 = this;

      return this.get('currentBearer').load()['catch'](function (e) {
        console.error(e);

        var session = _this2.get('session');

        if (session.get('isAuthenticated')) {
          session.invalidate();
        }
      });
    }
  });
});