define('keygen/adapters/token', ['exports', 'keygen/adapters/application', 'keygen/mixins/account-namespace', 'ember'], function (exports, _keygenAdaptersApplication, _keygenMixinsAccountNamespace, _ember) {
  var get = _ember['default'].get;
  exports['default'] = _keygenAdaptersApplication['default'].extend(_keygenMixinsAccountNamespace['default'], {
    createRecord: function createRecord(store, type, snapshot) {
      var record = snapshot.record;

      if (!record) {
        return null;
      }

      var bearer = get(record, 'bearer');
      if (!bearer) {
        return null;
      }

      var baseUrl = this.buildURL();
      var kind = get(record, 'kind');
      var id = get(bearer, 'id');

      switch (kind) {
        case 'product-token':
          {
            var serializer = store.serializerFor(type.modelName);
            var data = {};

            serializer.serializeIntoHash(data, type, snapshot);

            // FIXME(ezekg) Only license tokens should serialize these attributes
            delete data.data.attributes.maxDeactivations;
            delete data.data.attributes.maxActivations;

            return this.ajax(baseUrl + '/products/' + id + '/tokens', 'POST', { data: data });
          }
        case 'activation-token':
          {
            var serializer = store.serializerFor(type.modelName);
            var data = {};

            serializer.serializeIntoHash(data, type, snapshot);

            return this.ajax(baseUrl + '/licenses/' + id + '/tokens', 'POST', { data: data });
          }
        default:
          {
            throw new Error('Invalid token type: ' + kind);
          }
      }
    }
  });
});