define('keygen/app', ['exports', 'ember', 'keygen/resolver', 'ember-load-initializers', 'keygen/config/environment', 'moment', 'npm:raygun4js', 'npm:whatwg-fetch', 'npm:abortcontroller-polyfill/dist/polyfill-patch-fetch'], function (exports, _ember, _keygenResolver, _emberLoadInitializers, _keygenConfigEnvironment, _moment, _npmRaygun4js, _npmWhatwgFetch, _npmAbortcontrollerPolyfillDistPolyfillPatchFetch) {

  // Error reporting
  if (_keygenConfigEnvironment['default'].environment === 'production') {
    (0, _npmRaygun4js['default'])('apiKey', 'UPrFosMYDjVGBxY/cFL39Q==');
    (0, _npmRaygun4js['default'])('enableCrashReporting', true);

    _ember['default'].onerror = function (error) {
      (0, _npmRaygun4js['default'])('send', error);
    };

    _ember['default'].RSVP.on('error', function (error) {
      (0, _npmRaygun4js['default'])('send', error);
    });

    _ember['default'].Logger.error = function (message, cause, stack) {
      (0, _npmRaygun4js['default'])('send', new Error(message), null, { cause: cause, stack: stack });
    };
  }

  // Moment defaults
  _moment['default'].updateLocale('en', {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: "a second",
      ss: "%d seconds",
      m: "%d minute",
      mm: "%d minutes",
      h: "%d hour",
      hh: "%d hours",
      d: "%d day",
      dd: "%d days",
      M: "%d month",
      MM: "%d months",
      y: "%d year",
      yy: "%d years"
    }
  });

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _keygenConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _keygenConfigEnvironment['default'].podModulePrefix,
    Resolver: _keygenResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _keygenConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});