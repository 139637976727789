define('keygen/routes/users', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'keygen/routes/base', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _keygenRoutesBase, _ember) {
  var RSVP = _ember['default'].RSVP;
  exports['default'] = _keygenRoutesBase['default'].extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    // FIXME(ezekg) We're manually triggering 'store.admins' because our computed
    //              property in the notifications component does not always fire,
    //              for some stupid reason I'm unaware of.
    actions: {
      create: function create(user) {
        var _this = this;

        var isAdmin = user.get('role') === 'admin';

        user.save()['catch'](function (err) {
          return _this.handleModelErrors(user, err);
        }).then(function (u) {
          var flash = _this.get('flashMessages');
          if (flash != null) {
            flash.clearMessages();
          }

          return _this.transitionTo('users.show', u.id);
        }).then(function () {
          return isAdmin && _this.store.notifyPropertyChange('admins');
        });
      },
      update: function update(user) {
        var _this2 = this;

        var isAdmin = user.get('role') === 'admin';
        var reqs = [];

        if (user.get('hasDirtyRelations')) {
          var changes = user.modelChanges();

          for (var key in changes) {
            if (!changes[key]) {
              continue;
            }

            switch (key) {
              case 'group':
                {
                  var data = user.get('group.id') ? { type: 'groups', id: user.get('group.id') } : null;

                  reqs.push(user.changeGroup({
                    data: data
                  })['catch'](function (e) {
                    if (e.errors.length) {
                      user.get('errors').add('group', e.errors[0].detail);
                    }

                    throw e;
                  }));

                  break;
                }
            }
          }
        }

        if (user.get('hasDirtyAttributes')) {
          reqs.push(user.save()['catch'](function (err) {
            return _this2.handleModelErrors(user, err);
          }));
        }

        RSVP.all(reqs).then(function () {
          var flash = _this2.get('flashMessages');
          if (flash != null) {
            flash.clearMessages();
          }

          _this2.transitionTo('users.show', user.id);

          user.get('errors').clear();
        }).then(function () {
          return isAdmin && _this2.store.notifyPropertyChange('admins');
        });
      },
      'delete': function _delete(user) {
        var _this3 = this;

        var isAdmin = user.get('role') === 'admin';

        this.controller.promptForConfirmation({ property: 'email' }).onConfirmed(function (email) {
          if (email !== user.get('email')) {
            return false;
          }

          user.destroyRecord()['catch'](function (err) {
            return _this3.handleModelErrors(user, err);
          }).then(function () {
            return _this3.transitionTo('users.index');
          }).then(function () {
            return isAdmin && _this3.store.notifyPropertyChange('admins');
          });
        });
      }
    }
  });
});