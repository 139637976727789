define('keygen/models/webhook-event', ['exports', 'keygen/models/base', 'ember-data', 'ember-api-actions'], function (exports, _keygenModelsBase, _emberData, _emberApiActions) {
  exports['default'] = _keygenModelsBase['default'].extend({
    type: 'webhook-events',

    account: _emberData['default'].belongsTo('account'),
    endpoint: _emberData['default'].attr('string'),
    apiVersion: _emberData['default'].attr('string'),
    event: _emberData['default'].attr('string'),
    status: _emberData['default'].attr('string'),
    payload: _emberData['default'].attr('string'),
    lastResponseCode: _emberData['default'].attr('string'),
    lastResponseBody: _emberData['default'].attr('string'),

    // Actions
    retry: (0, _emberApiActions.memberAction)({ type: 'POST', path: 'actions/retry' })
  });
});