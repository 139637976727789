define('keygen/controllers/components/index', ['exports', 'keygen/controllers/application'], function (exports, _keygenControllersApplication) {
  exports['default'] = _keygenControllersApplication['default'].extend({
    queryParams: ['page', 'size', 'product', 'license', 'machine', 'user', 'fingerprint'],
    product: null,
    license: null,
    machine: null,
    user: null,
    name: null,
    page: 1,
    size: 20
  });
});