define('keygen/mixins/reset-scroll', ['exports', 'ember'], function (exports, _ember) {
  var run = _ember['default'].run;
  exports['default'] = _ember['default'].Mixin.create({
    actions: {
      willTransition: function willTransition() {
        this._super.apply(this, arguments);

        run.next('afterRender', function () {
          var main = document.querySelector('.main');
          if (main) {
            main.scrollTop = 0;
          }
        });

        window.scrollTo(0, 0);
      }
    }
  });
});