define('keygen/components/kg-product-release-uploader', ['exports', 'ember-uploader', 'ember'], function (exports, _emberUploader, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var service = _ember['default'].inject.service;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var FileField = _emberUploader['default'].FileField;
  var Uploader = _emberUploader['default'].Uploader;

  var S3Uploader = Uploader.extend({
    upload: function upload(file) {
      var url = get(this, 'url');
      var method = get(this, 'method');

      set(this, 'isUploading', true);

      return this.ajax(url, file, method);
    }
  });

  exports['default'] = FileField.extend({
    session: service('session'),

    headers: computed('session.data.authenticated.tok', function () {
      var token = this.get('session.data.authenticated.tok');
      return {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json'
      };
    }),

    filesDidChange: function filesDidChange(_ref) {
      var _this = this;

      var _ref2 = _slicedToArray(_ref, 1);

      var file = _ref2[0];

      if (file == null) {
        return;
      }

      var _getProperties = this.getProperties('headers', 'release', 'url');

      var headers = _getProperties.headers;
      var release = _getProperties.release;
      var url = _getProperties.url;

      fetch(url, { method: 'POST', headers: headers }).then(function (res) {
        if (res.status !== 200 && res.status !== 307) {
          throw new Error('failed to generate signed release upload');
        }

        return res.json();
      }).then(function (body) {
        var url = body.url;

        var uploader = S3Uploader.create({
          method: 'PUT',
          url: url
        });

        // Keep a reference to our uploader so we can abort if needed
        release.set('uploader', uploader);

        uploader.on('progress', function (progress) {
          release.set('progress', progress.percent);
        });

        uploader.on('didUpload', function (res) {
          var action = _this.get('on-upload');

          release.setProperties({
            progress: 100,
            success: true,
            error: null
          });

          action(release, res);
        });

        uploader.on('didError', function (err) {
          var action = _this.get('on-error');
          var error = err.statusText;

          try {
            var _JSON$parse = JSON.parse(err.responseText);

            var _JSON$parse$errors = _slicedToArray(_JSON$parse.errors, 1);

            var e = _JSON$parse$errors[0];

            error = (e.detail || e.title).toLowerCase();
          } catch (e) {}

          release.setProperties({
            success: false,
            error: error
          });

          action(release, err);
        });

        uploader.upload(file);
      })['catch'](function (err) {
        release.setProperties({
          success: false,
          error: err.message
        });
      });
    }
  });
});