define('keygen/transforms/object', ['exports', 'ember-data', 'lodash'], function (exports, _emberData, _lodash) {
  exports['default'] = _emberData['default'].Transform.extend({

    deserialize: function deserialize(value) {
      if (!(0, _lodash.isObject)(value)) {
        return {};
      } else {
        return Object.assign({}, value);
      }
    },

    serialize: function serialize(value) {
      if (!(0, _lodash.isObject)(value)) {
        return {};
      } else {
        return Object.assign({}, value);
      }
    }
  });
});