define('keygen/models/account', ['exports', 'keygen/mixins/has-top-level-meta', 'keygen/models/base', 'ember-data'], function (exports, _keygenMixinsHasTopLevelMeta, _keygenModelsBase, _emberData) {
  exports['default'] = _keygenModelsBase['default'].extend(_keygenMixinsHasTopLevelMeta['default'], {
    type: 'accounts',

    name: _emberData['default'].attr('string'),
    slug: _emberData['default'].attr('string'),
    status: _emberData['default'].attr('string'),
    'protected': _emberData['default'].attr('boolean'),
    apiVersion: _emberData['default'].attr('string'),

    admins: _emberData['default'].hasMany('user', { inverse: 'account' }), // For account creation
    plan: _emberData['default'].belongsTo('plan'),
    billing: _emberData['default'].belongsTo('billing'),
    webhookEndpoints: _emberData['default'].hasMany('webhook-endpoint'),
    webhookEvents: _emberData['default'].hasMany('webhook-event'),
    products: _emberData['default'].hasMany('product'),
    policies: _emberData['default'].hasMany('policy'),
    users: _emberData['default'].hasMany('user'),
    keys: _emberData['default'].hasMany('key'),
    licenses: _emberData['default'].hasMany('license'),
    machines: _emberData['default'].hasMany('machine'),
    tokens: _emberData['default'].hasMany('token'),

    meta: _emberData['default'].attr('object')
  });
});