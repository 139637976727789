define('keygen/models/license', ['exports', 'keygen/mixins/changed-relationships', 'keygen/models/bearer', 'ember-data', 'ember-api-actions', 'ember'], function (exports, _keygenMixinsChangedRelationships, _keygenModelsBearer, _emberData, _emberApiActions, _ember) {
  var computed = _ember['default'].computed;
  var LICENSE_PERMISSIONS = ['*', 'account.read', 'arch.read', 'artifact.read', 'channel.read', 'constraint.read', 'entitlement.read', 'group.owners.read', 'group.read', 'license.check-in', 'license.check-out', 'license.read', 'license.usage.increment', 'license.validate', 'machine.check-out', 'machine.create', 'machine.delete', 'machine.heartbeat.ping', 'machine.proofs.generate', 'machine.read', 'machine.update', 'platform.read', 'policy.read', 'process.create', 'process.delete', 'process.heartbeat.ping', 'process.read', 'process.update', 'product.read', 'release.download', 'release.read', 'release.upgrade', 'token.read', 'token.regenerate', 'token.revoke', 'user.read'];

  exports.LICENSE_PERMISSIONS = LICENSE_PERMISSIONS;
  exports['default'] = _keygenModelsBearer['default'].extend(_keygenMixinsChangedRelationships['default'], {
    type: 'licenses',

    account: _emberData['default'].belongsTo('account'),
    product: _emberData['default'].belongsTo('product'),
    policy: _emberData['default'].belongsTo('policy'),
    group: _emberData['default'].belongsTo('group'),
    owner: _emberData['default'].belongsTo('user', { inverse: false }),
    users: _emberData['default'].hasMany('user', { inverse: 'licenses' }),
    machines: _emberData['default'].hasMany('machine'),
    entitlements: _emberData['default'].hasMany('entitlement'),

    name: _emberData['default'].attr('string'),
    key: _emberData['default'].attr('string'),
    status: _emberData['default'].attr('string'),
    scheme: _emberData['default'].attr('string'),
    maxMachines: _emberData['default'].attr('number'),
    maxProcesses: _emberData['default'].attr('number'),
    maxUsers: _emberData['default'].attr('number'),
    maxCores: _emberData['default'].attr('number'),
    cores: _emberData['default'].attr('number'),
    maxUses: _emberData['default'].attr('number'),
    uses: _emberData['default'].attr('number'),
    expiry: _emberData['default'].attr('date'),
    'protected': _emberData['default'].attr('boolean'),
    suspended: _emberData['default'].attr('boolean'),
    encrypted: _emberData['default'].attr('boolean'),
    lastValidated: _emberData['default'].attr('date'),
    lastCheckOut: _emberData['default'].attr('date'),
    lastCheckIn: _emberData['default'].attr('date'),
    nextCheckIn: _emberData['default'].attr('date'),
    permissions: _emberData['default'].attr('array'),
    metadata: _emberData['default'].attr('json'),
    role: 'license',

    // Helper method to simplify display of tokens w/ polymorphic bearer
    universalName: computed('name', 'policy', 'policy.name', function () {
      return this.get('name') || this.get('policy.name');
    }),

    availablePermissions: computed('permissions', function () {
      var perms = this.get('permissions');
      if (perms != null && perms.includes('*')) {
        return ['*'];
      }

      return LICENSE_PERMISSIONS;
    }),

    // Relationships
    changePolicy: (0, _emberApiActions.memberAction)({ type: 'PUT', path: 'policy' }),
    changeOwner: (0, _emberApiActions.memberAction)({ type: 'PUT', path: 'owner' }),
    changeGroup: (0, _emberApiActions.memberAction)({ type: 'PUT', path: 'group' }),
    attachEntitlements: (0, _emberApiActions.memberAction)({ type: 'POST', path: 'entitlements' }),
    detachEntitlements: (0, _emberApiActions.memberAction)({ type: 'DELETE', path: 'entitlements' }),
    attachUsers: (0, _emberApiActions.memberAction)({ type: 'POST', path: 'users' }),
    detachUsers: (0, _emberApiActions.memberAction)({ type: 'DELETE', path: 'users' }),

    // Actions
    validate: (0, _emberApiActions.memberAction)({ type: 'GET', path: 'actions/validate' }),
    revoke: (0, _emberApiActions.memberAction)({ type: 'DELETE', path: 'actions/revoke' }),
    suspend: (0, _emberApiActions.memberAction)({ type: 'POST', path: 'actions/suspend' }),
    reinstate: (0, _emberApiActions.memberAction)({ type: 'POST', path: 'actions/reinstate' }),
    renew: (0, _emberApiActions.memberAction)({ type: 'POST', path: 'actions/renew' }),
    checkIn: (0, _emberApiActions.memberAction)({ type: 'POST', path: 'actions/check-in' }),
    incrementUsage: (0, _emberApiActions.memberAction)({ type: 'POST', path: 'actions/increment-usage' }),
    decrementUsage: (0, _emberApiActions.memberAction)({ type: 'POST', path: 'actions/decrement-usage' }),
    resetUsage: (0, _emberApiActions.memberAction)({ type: 'POST', path: 'actions/reset-usage' }),
    checkout: (0, _emberApiActions.memberAction)({
      path: 'actions/check-out',
      type: 'POST'
    }),

    // Resource meta
    meta: _emberData['default'].attr('string')
  });
});