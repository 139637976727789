define('keygen/models/metric', ['exports', 'ember-api-actions', 'keygen/models/base', 'ember-data'], function (exports, _emberApiActions, _keygenModelsBase, _emberData) {
  exports['default'] = _keygenModelsBase['default'].extend({
    type: 'metrics',

    account: _emberData['default'].belongsTo('account'),
    metric: _emberData['default'].attr('string'),
    detail: _emberData['default'].attr('json'),

    count: (0, _emberApiActions.collectionAction)({
      path: 'actions/count',
      type: 'GET'
    })
  });
});